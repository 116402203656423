<form #form="ngForm" autocomplete="off">
  <h2 mat-dialog-title>Korygowanie karty</h2>
  <div mat-dialog-content class="container" fxLayout="wrap row" fxLayoutAlign="left" fxLayoutGap="1%" fxLayoutGap.lt-md="1%" *ngIf="!loading">
    <mat-form-field appearance="outline" fxFlex="100%" fxFlex.lt-md="100%">
      <mat-label>Uwagi</mat-label>
      <textarea matInput [value]="remarks" readonly name="remarks"></textarea>
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex="100%">
      <mat-label>Kod odpadów</mat-label>
      <input autocomplete="off" type="text" placeholder="Wpisz lub wybierz" aria-label="Kod odpadów" matInput (focusout)="wasteCodeFocusout($event)" [formControl]="wasteCode" [matAutocomplete]="auto" name="wasteCode" required />
      <mat-error *ngIf="wasteCode.invalid">Niewłaściwy kod odpadu</mat-error>
      <mat-autocomplete autoActiveFirstOption (optionSelected)="wasteCodeActivated($event)" #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredWasteCodes | async" [value]="option.code"> {{ option.code }} - {{ option.description }} </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex="76%" fxFlex.lt-md="100%">
      <mat-label>Masa odpadów [Tony]</mat-label>
      <input matInput autocomplete="off" [(ngModel)]="wasteMassValue" (focusout)="parseWasteMass($event)" placeholder="1,0000" name="wasteMass" required />
    </mat-form-field>

    <div class="checkbox-field" fxFlex="24%" fxFlex.lt-md="100%">
      <mat-checkbox [(ngModel)]="details.wasteCodeExtended" name="wasteCodeExtended" (change)="clearWasteCodeExtendedDescription($event)">Kod EX</mat-checkbox>
    </div>

    <mat-form-field appearance="outline" fxFlex="74%" fxFlex.lt-md="100%">
      <mat-label *ngIf="details.wasteCodeExtended"> Rodzaj odpadu ex </mat-label>
      <input matInput autocomplete="off" [readOnly]="!details.wasteCodeExtended" (focusin)="details.wasteCodeExtended = true" [(ngModel)]="details.wasteCodeExtendedDescription" name="wasteCodeExtendedDescription" />
    </mat-form-field>

    <div class="checkbox-field" fxFlex="24%" fxFlex.lt-md="100%">
      <mat-checkbox [(ngModel)]="details.hazardousWasteReclassification" name="hazardousWasteReclassification" (change)="clearHazardousWasteReclassificationDescription($event)">Inne niż niebezp. </mat-checkbox>
    </div>

    <mat-form-field appearance="outline" fxFlex="74%" fxFlex.lt-md="100%">
      <mat-label *ngIf="details.hazardousWasteReclassification"> Rodzaj odpadu</mat-label>
      <input matInput autocomplete="off" [readonly]="!details.hazardousWasteReclassification" [(ngModel)]="details.hazardousWasteReclassificationDescription" name="hazardousWasteReclassificationDescription" (focusin)="details.hazardousWasteReclassification = true" />
    </mat-form-field>
  </div>
  <mat-spinner *ngIf="loading" style="margin: 90px auto"></mat-spinner>
  <div mat-dialog-actions>
    <button mat-button color="warn" (click)="dialogRef.close(false)">Anuluj</button>
    <button mat-button [disabled]="working" type="submit" (click)="confirm(form)">Potwierdź</button>
  </div>
</form>
