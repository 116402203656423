import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AlertService, MessageType } from '../../../../services/alert.service';
import { UserService, ExtendedKpoService, SubjectAuthenticationService, SubjectWhitelistCreationDto, WasteRegisterPublicApiApiModelsElasticsearchCompanyEs } from '../../../../../../api';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddSubjectDialogComponent } from '../../login/subject-list/add-subject-dialog/add-subject-dialog.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { fromEvent } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { ExtendedTableComponent } from '../../../../helpers/extended-management';

@Component({
  selector: 'app-add-whitelist-subject-dialog',
  templateUrl: './add-whitelist-subject-dialog.component.html',
  styleUrls: ['./add-whitelist-subject-dialog.component.css']
})
export class AddWhitelistSubjectDialogComponent extends ExtendedTableComponent implements AfterViewInit {
  constructor(
    private alertService: AlertService,
    private userService: UserService,
    private extendedKpoService: ExtendedKpoService,
    private subjectAuthenticationService: SubjectAuthenticationService,
    public dialogRef: MatDialogRef<AddSubjectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    super();
    this.loading = false;
  }

  displayedColumns = ["registrationNumber", "name", "address", "action"];
  model: SubjectWhitelistCreationDto = {};
  query = '';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild("search", { static: true }) searchInput: ElementRef;

  parseAddress(address: string) {
    if (address == undefined) return "";
    const elements = address.split(", ");
    const town = elements
      .find((el) => el.split(": ")[0] === "Miejscowość")
      ?.split(": ")[1];
    const street = elements
      .find((el) => el.split(": ")[0] === "Ulica")
      ?.split(": ")[1];
    return town + (street == undefined ? "" : " ul. " + street);
  }

  setCompany(company: WasteRegisterPublicApiApiModelsElasticsearchCompanyEs) {
    this.model.name = company.name;
    this.model.address = company.address;
    this.model.nip = company.nip;
    this.model.registryNumber = company.registrationNumber;
    this.model.companyId = company.companyId;

    this.userService.getCurrentUser().subscribe(result => {
      this.subjectAuthenticationService.addWhitelistSubject(this.model, this.data?.user?.id).subscribe(result => {
        this.alertService.showMessage('Pomyślnie dodano podmiot!', MessageType.Info);
        this.dialogRef.close();
      }, error => this.alertService.showMessage(error.error, MessageType.Error));
    }, error => this.alertService.showMessage(error.error, MessageType.Error));
  }

  ngAfterViewInit(): void {
    this.loading = false;
    fromEvent<any>(this.searchInput.nativeElement, "keyup")
      .debounceTime(1000)
      .subscribe((res) => {
        this.extendedKpoService.searchCompany(this.query).subscribe((result) => {
          this.dataSource = new MatTableDataSource(result);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.loading = false;
        });
      });
  }
}
