<h2 mat-dialog-title>Potwierdź przejęcie odpadu</h2>
<div mat-dialog-content>
  <mat-form-field readonly>
    <mat-label>Masa [Tony]</mat-label>
    <input matInput autocomplete="off" [(ngModel)]="wasteMass" disabled />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Uwagi</mat-label>
    <input matInput autocomplete="off" type="text" [(ngModel)]="remarks" />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button color="warn" (click)="dialogRef.close(false)">Anuluj</button>
  <button mat-button (click)="confirm()" [disabled]="working">Potwierdź</button>
</div>