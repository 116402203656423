import { Overlay } from "@angular/cdk/overlay";
import { AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { Subject, Subscription, Observable } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { WasteTransferCardRepositoryEntityDto, WasteTransferCardService } from "../../../../../api";
import { AlertService, MessageType } from "../../../services/alert.service";
import { CardService } from "../../../services/card.service";
import { MessagingService } from "../../../services/messaging.service";
import { BdoFilterComponent } from "../../bdo-filter/bdo-filter.component";
import { FiledCardDetailsComponent } from "../../dialogs/filed-card-details/filed-card-details.component";
import { FilingConfirmationDialogComponent } from "../../dialogs/filing/filing-confirmation-dialog/filing-confirmation-dialog.component";
import { KpoDetailsDialogComponent } from "../archive/archive-details-dialog/kpo-details-dialog.component";
import { ViewDraftCardDialogComponent } from "../home/kpo/view-draft-card-dialog/view-draft-card-dialog.component";

@UntilDestroy()
@Component({
  selector: "app-filed-cards",
  templateUrl: "./filed-cards.component.html",
  styleUrls: ["./filed-cards.component.scss"],
})
export class FiledCardsComponent implements OnInit, OnDestroy, AfterViewInit {
  loading = true;
  error = false;

  reloadRequest: Subject<void> = new Subject();
  refreshSubscription = new Subscription();
  requestSubscription = new Subscription();
  scrollStrategy = this.overlay.scrollStrategies.block();
  currentPageSize = 0;

  filteredResults: WasteTransferCardRepositoryEntityDto[] = [];
  filteredResults$: Observable<any[]> = new Observable((observer) => {
    this.paginator.page.subscribe((p) => {
      this.load(observer);
    });
    this.load(observer);
    this.handleQueryChanged(observer);
    this.handleRefresh(observer);
    this.reloadRequest.subscribe(() => this.load(observer));
  });

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(BdoFilterComponent, { static: true })
  bdoFilter: BdoFilterComponent;

  constructor(public cardService: CardService, private alertService: AlertService, private messagingService: MessagingService, public dialog: MatDialog, private overlay: Overlay, private wasteTransferCardService: WasteTransferCardService, @Inject(LOCAL_STORAGE) private storage: WebStorageService) {}
  ngOnInit(): void {
    let currentDate = new Date();
    this.bdoFilter.advancedQuery.transportDateRange.from = new Date(currentDate.getFullYear(), 0, 0, 0, 0, 0, 0);
    this.bdoFilter.advancedQuery.transportDateRange.to = new Date(currentDate.getFullYear() + 1, 0, 0, 0, 0, 0, 0);
  }

  ngAfterViewInit(): void {
    this.currentPageSize = this.paginator.pageSize;
    this.filteredResults$.subscribe((filteredResults) => (this.filteredResults = filteredResults));
  }

  load(observer) {
    this.error = false;
    this.loading = true;

    observer.next([]);

    let skip = this.paginator.pageIndex * this.paginator.pageSize;
    let take = this.paginator.pageSize;

    this.wasteTransferCardService
      .getFilteredWasteTransferCards(
        {
          cardNumber: this.bdoFilter.advancedQuery.cardNumber,
          cardStatusCodeName: this.bdoFilter.advancedQuery.cardStatusCodeName,
          startTransportDate: this.bdoFilter.advancedQuery.transportDateRange.from,
          endTransportDate: this.bdoFilter.advancedQuery.transportDateRange.to,
          registrationNumber: this.bdoFilter.advancedQuery.vehicleRegistrationNumber,
          senderName: this.bdoFilter.advancedQuery.senderName,
          wasteCode: this.bdoFilter.advancedQuery.wasteCode,
          skip: skip,
          take: take,
        },
        true,
        true
      )
      .subscribe(
        (result) => {
          observer.next(result.items);
          this.paginator.length = result.totalItemCount;
          this.loading = false;
          this.error = false;
        },
        () => {
          this.error = true;
          this.loading = false;
          this.alertService.showMessage("Wystąpił błąd podczas ładowania listy", MessageType.Error);
        }
      );
  }

  handleRefresh(observer) {
    this.refreshSubscription.add(
      this.messagingService.messageReceived$.pipe(untilDestroyed(this)).subscribe((payload) => {
        this.alertService.showMessage(payload.data.body.replace("\r\n", " | "), MessageType.Notification);
        this.load(observer);
      })
    );
  }

  handleQueryChanged(observer) {
    this.requestSubscription.add(
      this.bdoFilter.advancedQueryTextChanged.pipe(debounceTime(300)).subscribe(() => {
        this.load(observer);
      })
    );
    this.requestSubscription.add(
      this.bdoFilter.advancedQueryChanged.subscribe(() => {
        this.load(observer);
      })
    );
  }

  ngOnDestroy(): void {
    this.refreshSubscription.unsubscribe();
    this.requestSubscription.unsubscribe();
  }

  @HostListener("window:focus", ["$event"])
  onFocus(event: FocusEvent): void {
    this.reloadRequest.next();
  }

  openDetailsDialog(card): void {
    this.dialog.open(FiledCardDetailsComponent, {
      width: "950px",
      data: { cardId: card.id },
      panelClass: "dialog",
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });
  }
}
