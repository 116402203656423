<h1 mat-dialog-title>Szczegóły karty ewidencji odpadów</h1>
<mat-dialog-content class="mat-typography">
    <mat-spinner *ngIf="details == undefined; else detailsTemplate" style="margin: 40px auto;"></mat-spinner>
    <ng-template #detailsTemplate>
        <div class="container" fxLayout="wrap row" fxLayoutAlign="left" fxLayoutGap="1%" fxLayoutGap.lt-md="0">
            <app-value-field fxFlex="100%" [value]="details.wasteCode"> Kod i rodzaj odpadu </app-value-field>
            <app-value-field fxFlex="49%" [value]="details.cardNumber"> Numer karty </app-value-field>
            <app-value-field fxFlex="49%" [value]="details.year"> Rok kalendarzowy </app-value-field>

            <div class="checkbox-field" fxFlex="39%">
                <mat-checkbox [checked]="details.wasteCodeExtended" disabled>Kod ex</mat-checkbox>
            </div>
            <app-value-field fxFlex="59%" [value]="details.wasteCodeExtendedDescription == undefined ? '-' : details.wasteCodeExtendedDescription"> Rodzaj odpadu ex </app-value-field>


            <div class="checkbox-field" fxFlex="39%">
                <mat-checkbox [checked]="details.hazardousWasteReclassification" disabled> Zmiana statusu odpadów niebezpiecznych </mat-checkbox>
            </div>
            <app-value-field fxFlex="59%" [value]="details.hazardousWasteReclassificationDescription == undefined ? '-' : details.hazardousWasteReclassificationDescription"> Rodzaj odpadu </app-value-field>


            <div class="checkbox-field" fxFlex="100%">
                <mat-checkbox [checked]="details.isNeutralization" disabled> Zakres działalności dla kodu odpadu: Un – unieszkodliwione</mat-checkbox>
            </div>

            <div class="checkbox-field" fxFlex="100%">
                <mat-checkbox [checked]="details.isSalvage" disabled> Zakres działalności dla kodu odpadu: Od – odzysk </mat-checkbox>
            </div>
            <div class="checkbox-field" fxFlex="100%">
                <mat-checkbox [checked]="details.isWasteCollecting" disabled> Zakres działalności dla kodu odpadu: Zb – zbieranie odpadów </mat-checkbox>
            </div>
            <div class="checkbox-field" fxFlex="100%">
                <mat-checkbox [checked]="details.isWasteGenerating" disabled> Zakres działalności dla kodu odpadu: W – wytwarzanie odpadów </mat-checkbox>
            </div>


            <!-- 
            <div style="width: 100%;" class="container" fxLayout="wrap row" fxLayoutAlign="left" fxLayoutGap="1%"
            fxLayoutGap.lt-md="0"></div> -->
        </div>
    </ng-template>
    <mat-dialog-actions>
        <button mat-button (click)="dialogRef.close()">Zamknij</button>
    </mat-dialog-actions>
</mat-dialog-content>