import { Component, OnInit, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { AlertService, MessageType } from "../../../services/alert.service";
import { ExtendedKpoService } from "../../../../../api";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";

@Component({
  selector: "app-headquarter-selection",
  templateUrl: "./headquarter-selection.component.html",
  styleUrls: ["./headquarter-selection.component.scss"],
})
export class HeadquarterSelectionComponent implements OnInit {
  constructor(private router: Router, private alertService: AlertService, private extendedKpoService: ExtendedKpoService, @Inject(LOCAL_STORAGE) private storage: WebStorageService) {}

  headquarters = [];
  loading = true;
  subjectName: string;

  ngOnInit(): void {
    this.subjectName = this.storage.get("SubjectName");
    this.extendedKpoService.getCompanyHeadquarters().subscribe(
      (result) => {
        this.headquarters = result.reverse();
        this.loading = false;
      },
      (error) => {
        this.alertService.showMessage("Klucz API jest niepoprawny.", MessageType.Error);
        this.router.navigateByUrl("/login");
      }
    );
  }

  selected() {
    this.router.navigateByUrl("/role-selection");
  }
}
