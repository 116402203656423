import { Component, OnInit } from '@angular/core';
import { AlertService, MessageType } from '../../../../services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { ExtendedTableComponent } from '../../../../helpers/extended-management';
import * as momentjs from 'moment';
import { ConsoleService } from '../../../../../../api/api/console.service';

@Component({
  selector: 'app-logger-console',
  templateUrl: './logger-console.component.html',
  styleUrls: ['./logger-console.component.scss']
})
export class LoggerConsoleComponent extends ExtendedTableComponent implements OnInit {

  displayedColumns = ['date', 'type', 'message'];
  loading = true;

  public moment = momentjs;

  constructor(
    private alertService: AlertService,
    private consoleService: ConsoleService,
    private dialog: MatDialog
  ) {
    super();
  }

  reloadData() {
    this.consoleService.getAll().subscribe(result => {
      this.moment.locale('pl');
      this.updateTableData(result.reverse());
      this.loading = false;
    }, error => {});
  }

  clear() {
    this.consoleService.deleteAll().subscribe(res => {
      this.alertService.showMessage('Pomyślnie wyczyszczono dziennik zdarzeń', MessageType.Info);
    }, error => {
      this.alertService.showMessage('Błąd podczas wykonywania operacji', MessageType.Info);
    });
  }

  ngOnInit() {
    this.reloadData();
  }
}
