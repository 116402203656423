<h1 mat-dialog-title>Odpady wytworzone</h1>
<mat-dialog-content>
  <mat-tab-group mat-align-tabs="start">
    <mat-tab label="Informacje zbiorcze">
      <mat-spinner *ngIf="details == undefined; else infoTemplate" style="margin: 40px auto"></mat-spinner>
      <ng-template #infoTemplate>
        <p>
          Łączna masa wytworzonych odpadów w tonach [Mg]:
          {{ details?.wasteMassSum }}
        </p>
        <p>W tym w wyniku świadczenia usług i/lub działalności w zakresie obiektów liniowych: {{ details?.wasteMassServicesSum }}</p>
      </ng-template>
    </mat-tab>
    <mat-tab label="Dane szczegółowe">
      <div style="padding: 5px">
        <mat-spinner *ngIf="details == undefined || loading; else detailsTemplate" style="margin: 40px auto"> </mat-spinner>
        <ng-template #detailsTemplate>
          <ng-container *ngFor="let item of details?.generatedKeos?.items">
            <mat-accordion>
              <app-card-block [details]="item" [header]="">
                <div fxLayout="row wrap">
                  <app-card-block-field header="Masa wytworzonych odpadów w tonach [Mg] w związku z eksploatacją instalacji" *ngIf="item.wasteMassInstallation" fxFlex="100%">
                    {{ item.wasteMassInstallation }}
                  </app-card-block-field>

                  <app-card-block-field header="Masa wytworzonych odpadów w tonach [Mg] poza instalacją" *ngIf="item.wasteMassExcludingInstallation" fxFlex="100%">
                    {{ item.wasteMassExcludingInstallation }}
                  </app-card-block-field>

                  <app-card-block-field header="Nazwa instalacji" *ngIf="item.installationName" fxFlex="100%">
                    {{ item.installationName }}
                  </app-card-block-field>

                  <app-card-block-field header="Data wytworzenia" *ngIf="item.manufactureDate" fxFlex="100%">
                    {{ item.manufactureDate | date : "dd.MM.yyyy HH:mm" }}
                  </app-card-block-field>

                  <app-card-block-field header="Wytwarzanie odpadów w wyniku świadczenia usług i/lub działalności w zakresie obiektów liniowych" fxFlex="100%">
                    {{ item.wasteFromServices == true ? "Tak" : "Nie" }}
                  </app-card-block-field>

                  <app-card-block-field header="Imię i nazwisko osoby sporządzającej" *ngIf="item.createdByUser" fxFlex="100%">
                    {{ item.createdByUser }}
                  </app-card-block-field>
                </div>
                <!-- <button mat-button color="primary" [matMenuTriggerFor]="menu"
                                    class="actions-menu">Akcje</button>
                                <mat-menu #menu="matMenu">
                                    <button (click)="openDetailsDialog(item)" mat-menu-item>Podgląd</button>
                                </mat-menu> -->
              </app-card-block>
            </mat-accordion>
          </ng-container>
        </ng-template>
        <mat-paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 30, 50]"></mat-paginator>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
