<div>
    <h2 mat-dialog-title> Profil </h2>
</div>

<form *ngIf="user != null" #form="ngForm" (ngSubmit)="form.valid && onSubmit(form)" autocomplete="off">
    <div mat-dialog-content>
        <div fxLayout="row wrap" fxLayoutGap.gt-sm="2%">
            <mat-form-field appearance="outline" fxFlex="100%" fxFlex.gt-sm="49%">
                <mat-label>Imię</mat-label>
                <input matInput autocomplete="off" name="firstName" placeholder="Jan" [(ngModel)]="user.firstName"
                    [readonly]="true">
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="100%" fxFlex.gt-sm="49%">
                <mat-label>Nazwisko</mat-label>
                <input matInput autocomplete="off" name="lastName" placeholder="Kowalski" [(ngModel)]="user.lastName"
                    [readonly]="true">
            </mat-form-field>
        </div>
        <mat-form-field class="full-width" appearance="outline">
            <mat-label>E-Mail</mat-label>
            <input matInput autocomplete="off" placeholder="example@mae.pl" name="username" [(ngModel)]="user.username"
                [readonly]="true">
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline" *ngIf="user.phoneNumber != null">
            <mat-label>Numer telefonu</mat-label>
            <span matPrefix>+48 &nbsp;</span>
            <input matInput autocomplete="off" placeholder="Numer telefonu" name="phoneNumber"
                value="{{user.phoneNumber}}" [readonly]="true">
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
            <mat-label>Rola</mat-label>
            <input matInput autocomplete="off" name="role" value="{{getUserGroup()?.name}}" [readonly]="true">
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
            <mat-label>Nowe hasło</mat-label>
            <input matInput autocomplete="off" name="password" [type]="hidePassword ? 'password' : 'text'"
                [(ngModel)]="password">
            <mat-icon matSuffix (click)="hidePassword = !hidePassword">
                {{hidePassword ? 'visibility' : 'visibility_off'}}
            </mat-icon>
        </mat-form-field>

    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="dialog.close(false);" type="button">Zamknij</button>
        <button mat-button color="primary" type="submit">Zapisz</button>



    </div>
</form>