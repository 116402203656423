<mat-spinner *ngIf="subjects == null" style="margin: 0 auto;"></mat-spinner>

  <p class="no-subjects" *ngIf="subjects?.length === 0"> Brak przypisanych podmiotów </p>

  <table mat-table [dataSource]="dataSource" *ngIf="subjects?.length > 0">
    <ng-container matColumnDef="radio">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" style="text-align: right;">
        <button (click)="removeSubject(element)" mat-button><mat-icon>delete</mat-icon></button>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Nazwa podmiotu</th>
      <td mat-cell *matCellDef="let element">

        <div
          *ngIf="element.addresHtml !== undefined"
          [innerHTML]="element.addressHtml"
        ></div>
        <span *ngIf="element.addresHtml === undefined"> 
            {{element.name}} 
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-card-actions>
    <button mat-button (click)="dialog.close(false)">Zamknij</button>
    <button mat-button color="primary" (click)="openDialog()">Dodaj podmiot</button>
</mat-card-actions>

