import { Component, OnInit } from '@angular/core';
import { ExtendedKpoService, WasteRegisterPublicApiApiModelsElasticsearchCompanyEs } from '../../../../../api';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})
export class CompanyDetailsComponent implements OnInit {
  loading = true;
  company: WasteRegisterPublicApiApiModelsElasticsearchCompanyEs;
  eups: any;
  constructor(private extendedKpoService: ExtendedKpoService) { }

  ngOnInit(): void {
    this.extendedKpoService.getSelfCompany().subscribe(result => this.company = result, error => this.loading = false);
    this.extendedKpoService.getCompanyHeadquarters().subscribe(result => this.eups = result, error => this.loading = false);
    this.loading = false;

  }

}
