<mat-card cdk-scrollable>
  <mat-card-header>
    <button fxHide.lt-md mat-fab color="primary" style="position: absolute; top: 20px; right: 32px" (click)="openCardCreationDialog()">
      <mat-icon>add</mat-icon>
    </button>
    <button fxHide.gt-sm mat-mini-fab color="primary" style="position: absolute; top: 10px; right: 10px" (click)="openCardCreationDialog()">
      <mat-icon>add</mat-icon>
    </button>
    <mat-card-title>Tablica zadań </mat-card-title>
    <mat-card-subtitle>Zarządzanie zadaniami wymagającymi interakcji </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <app-bdo-filter [allowedCardStatuses]="filterCardStatuses" [initialStatus]="'APPROVED'"> </app-bdo-filter>

    <mat-expansion-panel disabled style="margin-top: 5px" *ngIf="hasRejected">
      <mat-expansion-panel-header>
        <button mat-button color="primary" style="margin-left: -16px" (click)="redirectToRejected()"><mat-icon>report</mat-icon> W systemie istnieją karty wymagające skorygowania. Kliknij by przejść do zakładki z kpo odrzuconymi przez przejmującego.</button>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
    <button mat-raised-button style="margin: 90px auto" (click)="ngOnInit()" *ngIf="error && !loading">Ponów próbę</button>
    <ng-container *ngIf="filteredResult$ | async as filteredResult">
      <mat-spinner *ngIf="loading; else view" style="margin: 90px auto"></mat-spinner>
      <ng-template #view>
        <ng-container *ngFor="let item of filteredResult">
          <mat-accordion>
            <app-card-block [details]="item">
              <div fxLayout="row wrap">
                <app-card-block-field header="Numer karty" *ngIf="item.cardNumber" fxFlex="100%" fxFlex.gt-lg="50%">
                  {{ item.cardNumber }}
                </app-card-block-field>
                <app-card-block-field header="Kod odpadów" fxFlex="100%" fxFlex.gt-lg="50%">
                  {{ item.wasteCode }}
                </app-card-block-field>
                <app-card-block-field header="Przypisany kierowca" *ngIf="item.user" fxFlex="100%" fxFlex.gt-lg="50%"> {{ item.user.firstName }} {{ item.user.lastName }} </app-card-block-field>
                <app-card-block-field header="Numer rejestracyjny" fxFlex="100%" fxFlex.gt-lg="50%" *ngIf="item.vehicleRegNumber != undefined && item.vehicleRegNumber != ''">
                  {{ item.vehicleRegNumber }}
                </app-card-block-field>
                <!-- <app-card-block-field header="Data utworzenia/modyfikacji">
                            {{getLastModificationDate(item) | date: 'dd.MM.yyyy HH:mm'}}
                        </app-card-block-field> -->
                <app-card-block-field header="Status karty" fxFlex="100%" fxFlex.gt-lg="50%">
                  {{ item.cardStatus }}
                  {{ item.user ? " - oczekiwanie na podjęcie odpadu" : "" }}
                </app-card-block-field>
                <app-card-block-field header="Planowany transport" fxFlex="100%" fxFlex.gt-lg="50%">
                  {{ item.plannedTransportTime | date : "dd.MM.yyyy HH:mm" }}
                </app-card-block-field>
                <app-card-block-field header="MPD przekazującego" fxFlex="100%" fxFlex.gt-lg="50%">
                  <button mat-button color="primary" *ngIf="item.eup == undefined; else eup" (click)="loadEupInfo(item)">Załaduj</button>
                  <ng-template #eup> {{ item.eup }}</ng-template>
                </app-card-block-field>
              </div>
              <button mat-button color="primary" [matMenuTriggerFor]="menu" class="actions-menu">Akcje</button>
              <mat-menu #menu="matMenu">
                <button (click)="openDetailsDialog(item)" mat-menu-item *ngIf="item.cardStatusCodeName === 'APPROVED' || item.cardStatusCodeName === 'PLANNED'">Podgląd</button>
                <button (click)="openPlannedCardEditDialog(item)" *ngIf="item.cardStatusCodeName === 'PLANNED' && role == 'SENDER'" mat-menu-item>Edytuj</button>
                <button (click)="openPlannedCardApprovalDialog(item)" *ngIf="item.cardStatusCodeName === 'PLANNED' && role == 'SENDER'" mat-menu-item>Zatwierdź</button>
                <button (click)="openPlannedCardApprovalDialog(item, 'generate')" *ngIf="item.cardStatusCodeName === 'PLANNED' && role == 'SENDER'" mat-menu-item>Zatwierdź i wygeneruj potwierdzenie</button>
                <button (click)="openPlannedRemovalDialog(item)" *ngIf="item.cardStatusCodeName === 'PLANNED' && role == 'SENDER'" mat-menu-item>Usuń</button>
                <!-- <button (click)="openCardRejectionDialog(item)" *ngIf="item.cardStatus == cardService.plannedStatus || (item.cardStatus == cardService.editedStatus && role == 'SENDER')" mat-menu-item>Odrzuć</button> -->
                <button (click)="openDriverSelectionDialog(item)" *ngIf="item.cardStatusCodeName === 'APPROVED' && role == 'CARRIER'" mat-menu-item>Przydziel kierowcę</button>
                <button (click)="openConfirmationGenerationDialog(item)" *ngIf="item.cardStatusCodeName === 'APPROVED' && role == 'SENDER'" mat-menu-item>Wygeneruj potwierdzenie</button>
                <button (click)="openCardWithdrawalDialog(item)" *ngIf="(item.cardStatusCodeName === 'APPROVED' || item.cardStatusCodeName === 'CONFIRMATION_GENERATED') && role == 'SENDER'" mat-menu-item>Wycofaj</button>
              </mat-menu>
            </app-card-block>
          </mat-accordion>
        </ng-container>
      </ng-template>
    </ng-container>
    <mat-paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 30, 50]" cha></mat-paginator>
  </mat-card-content>
</mat-card>
