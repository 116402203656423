<mat-expansion-panel [expanded]="expanded">
  <mat-expansion-panel-header>
    <!-- Temporary -->
    <mat-panel-title *ngIf="draftCard?.senderCompanyName">
      <div>
        <mat-checkbox style="margin-right: 16px" (change)="checkedChanged($event)" *ngIf="check" [checked]="details.checked"> </mat-checkbox>
        <span class="label">Przekazujący:&nbsp;</span> <span class="content"> {{ draftCard?.senderCompanyName }}</span>
      </div>
    </mat-panel-title>
    <mat-panel-title *ngIf="kpoCard?.senderName">
      <mat-checkbox style="margin-right: 16px" (change)="checkedChanged($event)" *ngIf="check" [checked]="details.checked"> </mat-checkbox>
      <span class="label">Przekazujący:&nbsp;</span> <span class="content"> {{ kpoCard?.senderName }}</span>
    </mat-panel-title>
    <!-- ^ -->

    <mat-panel-title *ngIf="header !== undefined">
      <ng-container *ngIf="radio; else default">
        <mat-radio-button (click)="$event.stopPropagation()" [value]="value" (change)="radioChanged($event)" [checked]="checkboxChecked">
          <div class="radio-header">
            <span class="label">{{ header }}&nbsp;</span> <span class="content"> {{ text }}</span>
          </div>
        </mat-radio-button>
      </ng-container>

      <ng-template #default>
        <mat-checkbox (change)="checkedChanged($event)" *ngIf="check; else labelTemplate" [checked]="checkboxChecked"> {{ header }} </mat-checkbox>
        <ng-template #labelTemplate>
          <div>
            <span class="label">{{ header }}&nbsp;</span> <span class="content"> {{ text }}</span>
          </div>
        </ng-template>
      </ng-template>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <ng-content></ng-content>
</mat-expansion-panel>
