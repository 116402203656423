<h1 mat-dialog-title>Odpady przyjęte</h1>
<mat-dialog-content>
  <mat-tab-group mat-align-tabs="start">
    <mat-tab label="Informacje zbiorcze">
      <mat-spinner *ngIf="details == undefined; else infoTemplate" style="margin: 40px auto"></mat-spinner>
      <ng-template #infoTemplate>
        <p>
          Łączna masa odpadów przejętych przez posiadacza odpadów w tonach [Mg]:
          {{ details?.collectedSum }}
        </p></ng-template
      >
    </mat-tab>
    <mat-tab label="Dane szczegółowe">
      <div style="padding: 5px">
        <mat-spinner *ngIf="details == undefined || loading; else detailsTemplate" style="margin: 40px auto"> </mat-spinner>
        <ng-template #detailsTemplate>
          <ng-container *ngFor="let item of details?.collectedKeos?.items">
            <mat-accordion>
              <app-card-block [details]="item" [header]="">
                <div fxLayout="row wrap">
                  <app-card-block-field header="Data przyjęcia" *ngIf="item.collectedDate" fxFlex="100%">
                    {{ item.collectedDate | date : "dd.MM.yyyy HH:mm" }}
                  </app-card-block-field>
                  <app-card-block-field header="Masa wytworzonych odpadów w tonach [Mg]" *ngIf="item.collectedMass" fxFlex="100%">
                    {{ item.collectedMass }}
                  </app-card-block-field>
                  <app-card-block-field header="Numer karty przekazania odpadów" *ngIf="item.kpoCardNumber" fxFlex="100%">
                    {{ item.kpoCardNumber }}
                  </app-card-block-field>
                  <app-card-block-field header="Numer karty przekazania odpadów komunalnych" *ngIf="item.kpokCardNumber" fxFlex="100%">
                    {{ item.kpokCardNumber }}
                  </app-card-block-field>
                  <app-card-block-field header="Nazwa instalacji" *ngIf="item.installationName" fxFlex="100%">
                    {{ item.installationName }}
                  </app-card-block-field>
                  <app-card-block-field header="Informacja o sposobie przyjęcia odpadów" *ngIf="item.collectionWay" fxFlex="100%">
                    {{ item.collectionWay == "BASED_ON_KPO_CARD" ? "na podstawie kpo" : item.collectionWay }}
                  </app-card-block-field>
                  <app-card-block-field header="Imię i nazwisko osoby sporządzającej" *ngIf="item.createdByUser" fxFlex="100%">
                    {{ item.createdByUser }}
                  </app-card-block-field>
                </div>
                <!-- <button mat-button color="primary" [matMenuTriggerFor]="menu"
                                    class="actions-menu">Akcje</button>
                                <mat-menu #menu="matMenu">
                                    <button (click)="openDetailsDialog(item)" mat-menu-item>Podgląd</button>
                                </mat-menu> -->
              </app-card-block>
            </mat-accordion>
          </ng-container>
        </ng-template>
        <mat-paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 30, 50]"></mat-paginator>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
