import { Component, OnInit, Inject } from "@angular/core";
import {
  GroupDto,
  UserDto,
  UsersService,
  GroupsService,
} from "../../../../../../api";
import { AlertService, MessageType } from "../../../../services/alert.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { group } from "@angular/animations";

@Component({
  selector: "app-driver-wizard",
  templateUrl: "./driver-wizard.component.html",
  styleUrls: ["./driver-wizard.component.css"],
})
export class DriverWizardComponent implements OnInit {
  password: string;
  hidePassword = true;
  sendMail = false;
  randomPrefix = 0;

  userGroups: GroupDto[];
  user: UserDto = { firstName: "", lastName: "", username: "" };

  constructor(
    private userService: UsersService,
    private groupService: GroupsService,
    private alertService: AlertService,
    public dialog: MatDialogRef<DriverWizardComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  ngOnInit() {
    this.groupService.getAll().subscribe((value) => {
      this.userGroups = value;
    });
    this.user = this.data.user === undefined ? null : this.data.user;
    switch (this.data.mode) {
      case "create":
        this.randomPrefix = this.getRandomInt(100, 999);
        break;
      case "edit":
        this.randomPrefix = Number.parseInt(
          this.user.username.split("_")[0],
          10
        );
        this.user.username = this.user.username.substring(
          this.user.username.indexOf("_") + 1
        );
        break;
    }
  }

  valueChange($event) {
    this.sendMail = $event.checked;
  }

  passwordModelChanged($event: any) {
    const i = Number.parseInt(this.password, 10);
    this.password = i !== i ? "" : i.toString();
  }

  passwordChanged($event: any) {
    if (!($event.key[0] >= "0" && $event.key[0] <= "9")) {
      $event.preventDefault();
    }
    const i = Number.parseInt(this.password, 10);
    this.password = i !== i ? "" : i.toString();
  }

  nameChange($event: KeyboardEvent) {
    if (this.data.mode === "edit") {
      return;
    }
    if (this.user.firstName != null && this.user.lastName != null) {
      this.user.username =
        this.user.firstName.toLowerCase() +
        "." +
        this.user.lastName.toLowerCase();
      this.user.username = this.user.username
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/\u0142/g, "l");
    }
  }

  onSubmit(form): void {
    this.user.group = this.userGroups.find((g) => g.name === "Kierowca");
    const i = Number.parseInt(this.password, 10);
    this.password = i != i ? "" : i.toString();
    switch (this.data.mode) {
      case "create":
        let userCopyC = { ...this.user };
        userCopyC.username = this.randomPrefix + "_" + this.user.username;
        this.userService
          .add(
            userCopyC,
            this.password === "" ? null : this.password,
            this.sendMail
          )
          .subscribe(
            (result: any) => {
              this.alertService.showMessage(
                "Pomyślnie dodano użytkownika",
                MessageType.Info
              );
              this.dialog.close(true);
            },
            (error) =>
              this.alertService.showMessage(error.error, MessageType.Error)
          );
        break;
      case "edit":
        let userCopyE = { ...this.user };
        userCopyE.username = this.randomPrefix + "_" + this.user.username;
        this.userService
          .update(
            this.data.user.id,
            userCopyE,
            this.password === "" ? null : this.password,
            this.sendMail
          )
          .subscribe(
            (result: any) => {
              this.alertService.showMessage(
                "Pomyślnie zaktualizowano dane użytkownika",
                MessageType.Info
              );
              this.dialog.close(true);
            },
            (error) =>
              this.alertService.showMessage(error.error, MessageType.Error)
          );
        break;
    }
  }
}
