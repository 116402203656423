import { Component, OnInit, ViewChild } from "@angular/core";
import { ExtendedTableComponent } from "../../../helpers/extended-management";
import { AlertService, MessageType } from "../../../services/alert.service";
import { CarsService, CarDto } from "../../../../../api";
import { MatDialog } from "@angular/material/dialog";
import { CarWizardComponent } from "../cars/car-wizard/car-wizard.component";
import { ConfirmDialogComponent } from "../../dialogs/confirm-dialog/confirm-dialog.component";
import { Observable } from "rxjs";
import { MatPaginator } from "@angular/material/paginator";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-cars",
  templateUrl: "./cars.component.html",
  styleUrls: ["./cars.component.scss"],
})
export class CarsComponent implements OnInit {
  isLoading = false;
  results: CarDto[];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(private alertService: AlertService, private carService: CarsService, private dialog: MatDialog) {}

  filteredResults$ = new Observable<CarDto[]>((observer) => {
    this.load(observer);
    this.paginator.page.pipe(untilDestroyed(this)).subscribe((p) => this.load(observer));
  });

  load(observer) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    const filteredResults = this.results.slice(startIndex, Math.min(startIndex + this.paginator.pageSize, this.paginator.length));
    observer.next(filteredResults);
  }

  openDialog(mode: string, car: CarDto = {}) {
    const dialogRef = this.dialog.open(CarWizardComponent, {
      data: { mode: mode, car: { ...car } },
      width: "500px",
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.reloadData();
      }
    });
  }

  reloadData() {
    this.isLoading = true;
    this.carService.getAll().subscribe((result: CarDto[]) => {
      this.results = result;
      this.paginator.length = this.results.length;
      this.paginator.pageIndex = 0;
      this.isLoading = false;
    });
  }

  ngOnInit() {
    this.reloadData();
  }
}
