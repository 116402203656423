<mat-spinner *ngIf="loading" style="margin: 90px auto"></mat-spinner>

<div [ngStyle]="{ display: loading ? 'none' : 'inherit' }">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Informacje o firmie</mat-card-title>
      <mat-card-subtitle>Podgląd informacji o firmie</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-spinner *ngIf="company == undefined" style="margin: 0 auto"></mat-spinner>
      <div *ngIf="company != undefined" class="container" fxLayout="wrap row" fxLayoutAlign="left" fxLayoutGap="1%">
        <mat-form-field appearance="standard" fxFlex="24%" *ngIf="company.registrationNumber != undefined">
          <mat-label>Numer rejestrowy</mat-label>
          <input matInput autocomplete="off" readonly [value]="company.registrationNumber" />
        </mat-form-field>
        <mat-form-field appearance="standard" fxFlex="74%" *ngIf="company.name != undefined">
          <mat-label>Nazwa</mat-label>
          <input matInput autocomplete="off" readonly [value]="company.name" />
        </mat-form-field>
        <mat-form-field appearance="standard" fxFlex="24%" *ngIf="company.nip != undefined">
          <mat-label>NIP</mat-label>
          <input matInput autocomplete="off" readonly [value]="company.nip" />
        </mat-form-field>
        <mat-form-field appearance="standard" fxFlex="24%" *ngFor="let item of company.address?.split(', ')">
          <mat-label>{{ item.split(": ")[0] }}</mat-label>
          <input matInput autocomplete="off" readonly [value]="item.split(': ')[1]" />
        </mat-form-field>
        <mat-form-field appearance="standard" fxFlex="24%" *ngIf="company.companyId != undefined">
          <mat-label>Identyfikator (Guid)</mat-label>
          <input matInput autocomplete="off" readonly [value]="company.companyId" />
        </mat-form-field>
        <h3 fxFlex="100%">Miejsca prowadzenia działalności</h3>
      </div>
      <div *ngFor="let senderCompanyEup of eups" class="container" fxLayout="wrap row" fxLayoutAlign="left" fxLayoutGap="1%">
        <mat-form-field appearance="standard" fxFlex="24%" *ngIf="senderCompanyEup.registrationNumber != undefined">
          <mat-label>Numer miejsca</mat-label>
          <input matInput autocomplete="off" readonly [value]="senderCompanyEup.registrationNumber" />
        </mat-form-field>
        <mat-form-field appearance="standard" fxFlex="24%" *ngIf="senderCompanyEup.name != undefined">
          <mat-label>Nazwa</mat-label>
          <input matInput autocomplete="off" readonly [value]="senderCompanyEup.name" />
        </mat-form-field>
        <mat-form-field appearance="standard" fxFlex="24%" *ngFor="let item of senderCompanyEup.address?.split(', ')">
          <mat-label>{{ item.split(": ")[0] }}</mat-label>
          <input matInput autocomplete="off" readonly [value]="item.split(': ')[1]" />
        </mat-form-field>
        <mat-form-field appearance="standard" fxFlex="24%" *ngIf="senderCompanyEup.eupId != undefined">
          <mat-label>Identyfikator (Guid)</mat-label>
          <input matInput autocomplete="off" readonly [value]="senderCompanyEup.eupId" />
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>
</div>
