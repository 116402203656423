import { DatePipe } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { CookieService } from "ngx-cookie-service";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { fromEvent, Observable, Subscription } from "rxjs";
import { switchMap, tap, debounceTime, distinctUntilChanged, finalize, startWith } from "rxjs/operators";
import {
  ExtendedKpoService,
  DraftCardDto,
  BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto,
  WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto,
  WasteRegisterPublicApiApiModelsElasticsearchCompanyEs,
  WasteRegisterPublicApiApiModelsElasticsearchEupEs,
  GroupsService,
  UsersService,
  UserDto,
  WasteRegisterPublicApiApiModelsElasticsearchTerytEs,
} from "../../../../../../../api";
import { CanDeactivateGuard } from "../../../../../guards/auth.guard";
import { AlertService, MessageType } from "../../../../../services/alert.service";
import { RoleService } from "../../../../../services/role.service";
@UntilDestroy()
@Component({
  selector: "app-new-kpo-dialog",
  templateUrl: "./new-kpo-dialog.component.html",
  styleUrls: ["./new-kpo-dialog.component.scss"],
})
export class NewKpoDialogComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<NewKpoDialogComponent>,
    private extendedKpoService: ExtendedKpoService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private alertService: AlertService,
    public datepipe: DatePipe,
    private driversService: UsersService,
    private groupsService: GroupsService,
    private cookieService: CookieService,
    private roleService: RoleService,
    private router: Router,
    private guard: CanDeactivateGuard,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.roleService.role$.pipe(untilDestroyed(this)).subscribe((r) => (this.role = r));
  }

  role: string;

  details: DraftCardDto = {
    additionalInfo: "",
    cardStatus: "",
    carrierCompanyId: "",
    carrierCompanyName: "",
    certificateNumberAndBoxNumbers: "",
    creationDateTime: null,
    hazardousWasteReclassification: false,
    hazardousWasteReclassificationDescription: "",
    isWasteGenerating: false,
    plannedTransportTime: new Date(new Date().getTime() + 1000 * 60 * 60 * 24),
    rejectionCause: "",
    wasteCode: "",
    wasteCodeDescription: "",
    wasteCodeExtended: false,
    wasteCodeExtendedDescription: "",
    wasteGeneratedTeryt: "",
    wasteMass: null,
    wasteGeneratedTerytPk: "",
    wasteGeneratingAdditionalInfo: "",
    vehicleRegNumber: undefined,
  };

  senderCompany: WasteRegisterPublicApiApiModelsElasticsearchCompanyEs = undefined;
  senderCompanyEup: WasteRegisterPublicApiApiModelsElasticsearchEupEs = undefined;
  carrierCompany: WasteRegisterPublicApiApiModelsElasticsearchCompanyEs = undefined;
  carrierAdditionalCompanies: WasteRegisterPublicApiApiModelsElasticsearchCompanyEs[] = [];
  carrierCompanyEx: WasteRegisterPublicApiApiModelsElasticsearchCompanyEs = undefined;

  carrierCompanyEup: WasteRegisterPublicApiApiModelsElasticsearchEupEs = undefined;
  receiverCompany: WasteRegisterPublicApiApiModelsElasticsearchCompanyEs;
  receiverCompanyEup: WasteRegisterPublicApiApiModelsElasticsearchEupEs;
  wasteCodeDescription = "";
  wasteProcessDescription = "";
  wasteCodeModel: WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto;
  wasteGeneratedTerytPkModel: WasteRegisterPublicApiApiModelsElasticsearchTerytEs;
  wasteProcessModel: BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto;
  query = "";
  kpoQueryText = "";
  regNumber = "";
  loading = false;
  addingCard = false;
  plannedTime = new Date();
  wasteMassValue = "";
  plannedDate = new Date();
  subscription = new Subscription();
  drivers: UserDto[];
  driver: UserDto;
  wasteCodeExtendedDescriptions: { [key: string]: string };

  wasteGeneratedTerytPk = new FormControl();
  copyOfWasteGeneratedTerytPks: WasteRegisterPublicApiApiModelsElasticsearchTerytEs[];
  filteredWasteGeneratedTerytPks: Observable<WasteRegisterPublicApiApiModelsElasticsearchTerytEs[]>;

  wasteCode = new FormControl();
  copyOfWasteCodes: WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto[];
  filteredWasteCodes: Observable<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto[]>;

  wasteProcess = new FormControl();
  copyOfWasteProcesses: BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto[];
  filteredWasteProcesses: Observable<BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto[]>;

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  carrierCompanyExChanged() {
    this.carrierCompany = this.carrierCompanyEx;
    console.log("changing");
  }

  ngOnInit() {
    this.extendedKpoService.getWasteCodeExtendedDescriptions().subscribe((result) => {
      console.log(result);
      this.wasteCodeExtendedDescriptions = result;
    });

    this.groupsService.getAll().subscribe((result) => {
      const groupId = result.find((g) => g.name === "Kierowca").id;

      this.driversService.getAllAssigned(groupId).subscribe((drivers) => (this.drivers = drivers));
    });

    this.filteredWasteGeneratedTerytPks = this.wasteGeneratedTerytPk.valueChanges.pipe(
      debounceTime(400),
      startWith(""),
      distinctUntilChanged(),
      switchMap((value) => this.extendedKpoService.getTeryts(value)),
      tap((w) => console.log((this.copyOfWasteGeneratedTerytPks = w)))
    );

    this.filteredWasteCodes = this.wasteCode.valueChanges.pipe(
      debounceTime(400),
      startWith(""),
      distinctUntilChanged(),
      switchMap((value) => this.extendedKpoService.getWasteCodes(value)),
      tap((w) => (this.copyOfWasteCodes = w))
    );

    this.filteredWasteProcesses = this.wasteProcess.valueChanges.pipe(
      debounceTime(400),
      startWith(""),
      distinctUntilChanged(),
      switchMap((value) => this.extendedKpoService.getWasteProcess(value)),
      tap((w) => (this.copyOfWasteProcesses = w))
    );

    this.extendedKpoService.getSelfCompany().subscribe(
      (result) => {
        this.senderCompany = result;
        this.carrierCompany = result;
        if (result.companyId == "e78c3fb2-8889-419e-8c5a-912c192fdd3c") {
          this.carrierAdditionalCompanies.push(result);
          this.carrierCompanyEx = result;
          this.extendedKpoService.getCompany("9157806c-f314-4858-bea8-2bfbd66c2392").subscribe((c1) => {
            this.carrierAdditionalCompanies.push(c1);
          });
          this.extendedKpoService.getCompany("55a6db2d-c2bd-4476-864c-92b9279ed3d7").subscribe((c1) => {
            this.carrierAdditionalCompanies.push(c1);
          });
        }

        if (result.companyId == "6a682474-0595-4e9c-8177-823c08e8c5bf") {
          this.carrierAdditionalCompanies.push(result);
          this.carrierCompanyEx = result;
          this.extendedKpoService.getCompany("b0cccd3a-6b41-4139-bc18-db977701ba42").subscribe((c1) => {
            this.carrierAdditionalCompanies.push(c1);
          });
        }
      },
      (error) => this.alertService.showMessage("Błąd podczas pobierania danych podmiotu", MessageType.Error)
    );
    this.extendedKpoService.getCompanyEup(this.storage.get("eupId")).subscribe(
      (result) => {
        this.senderCompanyEup = result;
        this.carrierCompanyEup = result;
      },
      (error) => this.alertService.showMessage("Błąd podczas pobierania danych podmiotu", MessageType.Error)
    );
  }

  parseAddress(address: string) {
    if (address === undefined) {
      return "";
    }
    const elements = address.split(", ");
    const town = elements.find((el) => el.split(": ")[0] === "Miejscowość")?.split(": ")[1];
    const street = elements.find((el) => el.split(": ")[0] === "Ulica")?.split(": ")[1];
    return town + (street === undefined ? "" : " ul. " + street);
  }

  setCompany(company: WasteRegisterPublicApiApiModelsElasticsearchCompanyEs) {
    this.receiverCompany = company;
    console.log(this.receiverCompany);
  }

  setCarrierCompany(company: WasteRegisterPublicApiApiModelsElasticsearchCompanyEs) {
    this.carrierCompany = company;
    console.log(this.receiverCompany);
  }

  setEup(eup: WasteRegisterPublicApiApiModelsElasticsearchEupEs) {
    this.receiverCompanyEup = eup;
  }

  formatTime($event) {}

  wasteGeneratedTerytPkFocusout($event) {
    if ($event?.option?.value == undefined) return;
    this.wasteGeneratedTerytPkModel = this.copyOfWasteGeneratedTerytPks.find((x) => x.fulltext === $event.option.value);
  }
  wasteGeneratedTerytPkActivated($event) {
    if ($event?.option?.value == undefined) return;
    this.wasteGeneratedTerytPkModel = this.copyOfWasteGeneratedTerytPks.find((x) => x.fulltext === $event.option.value);
  }
  wasteCodeActivated($event) {
    this.wasteCodeModel = this.copyOfWasteCodes.find((x) => x.code === $event.option.value);
    this.fillWasteCodeExtended(this.wasteCodeModel?.code);
  }

  wasteCodeFocusout($event) {
    if (this.copyOfWasteCodes === undefined) {
      return;
    }

    this.wasteCodeModel = this.copyOfWasteCodes.find((x) => x.code === $event.option.value);

    if (this.wasteCodeModel === undefined) {
      this.wasteCode.setErrors({ notUnique: true });
    }

    this.fillWasteCodeExtended(this.wasteCodeModel.code);
  }

  fillWasteCodeExtended(wasteCode) {
    const desc = this.wasteCodeExtendedDescriptions[wasteCode];
    if (desc) {
      this.details.wasteCodeExtendedDescription = desc;
      this.details.wasteCodeExtended = true;
    } else {
      this.details.wasteCodeExtendedDescription = "";
      this.details.wasteCodeExtended = false;
    }
  }

  wasteProcessActivated($event) {
    this.wasteProcessModel = this.copyOfWasteProcesses.find((x) => x.codeName === $event.option.value);
    this.wasteProcessDescription = this.wasteProcessModel?.name;
  }

  wasteProcessFocusout($event) {
    this.wasteProcessModel = this.copyOfWasteProcesses.find((x) => x.codeName === $event.option.value);
    this.wasteProcessDescription = this.wasteProcessModel?.name;
  }

  parseWasteMass($event) {
    if (this.wasteMassValue.indexOf(",") !== -1) {
      this.wasteMassValue = this.wasteMassValue.replace(",", ".");
    }
    const parsedMass = parseFloat(this.wasteMassValue);
    if (Number.isNaN(parsedMass)) {
      this.wasteMassValue = "";
      this.details.wasteMass = undefined;
      return;
    }
    this.details.wasteMass = parseFloat(this.wasteMassValue);
    this.wasteMassValue = parsedMass.toFixed(4).replace(".", ",");
  }

  timeChangeHandler($event) {
    console.log($event);
  }

  showHeadquarterSelection() {
    this.guard.navigationAllowed = true;
    this.router.navigateByUrl("/headquarter-selection");
    this.dialogRef.close(false);
  }

  addCard(form) {
    if (!form.form.valid) {
      return;
    }

    this.addingCard = true;
    this.details.cardStatus = "Planowana";
    this.details.wasteCode = this.wasteCodeModel.code;
    this.details.wasteCodeDescription = this.wasteCodeModel.description;
    this.details.wasteCodeId = this.wasteCodeModel.wasteCodeId;
    this.details.wasteProcessId = this.wasteProcessModel?.wasteProcessId;
    this.details.senderCompanyId = this.senderCompany.companyId;
    this.details.senderCompanyName = this.senderCompany.name;
    this.details.senderEupId = this.senderCompanyEup.eupId;
    this.details.carrierCompanyId = this.carrierCompany.companyId;
    this.details.carrierCompanyName = this.carrierCompany.name;
    this.details.receiverCompanyId = this.receiverCompany.companyId;
    this.details.receiverEupId = this.receiverCompanyEup.eupId;
    this.details.receiverCompanyAddress = this.receiverCompany.address;
    this.details.receiverCompanyName = this.receiverCompany.name;
    this.details.creationDateTime = new Date();

    this.details.wasteGeneratedTerytPk = this.details.isWasteGenerating ? this.wasteGeneratedTerytPkModel.pk : "";
    if (!this.details.isWasteGenerating) {
      this.details.wasteGeneratingAdditionalInfo = "";
    }
    if (this.details.hazardousWasteReclassification == false) this.details.hazardousWasteReclassificationDescription = undefined;
    if (this.details.wasteCodeExtended == false) this.details.wasteCodeExtendedDescription = undefined;

    this.details.plannedTransportTime = this.plannedDate;

    this.details.plannedTransportTime.setHours(this.plannedTime.getHours(), this.plannedTime.getMinutes());
    const driverKey = this.driver ? `[nr. kier. ${this.driver.key}]` : this.details.vehicleRegNumber == "" ? undefined : this.details.vehicleRegNumber;

    console.log(this.details);
    this.extendedKpoService.addAsApproved(this.details, driverKey).subscribe(
      (result) => {
        if (this.driver && this.role == "CARRIER") {
          const eup = this.cookieService.get("eupId");
          this.extendedKpoService.assignDriver(result.kpoId, this.driver.id, result.cardStatusCodeName, result.carrierCompanyId, eup, false).subscribe(
            (r) => {
              this.alertService.showMessage("Pomyślnie dodano kartę jako zatwierdzoną oraz przypisano kierowcę!", MessageType.Info);
              this.addingCard = false;
              this.dialogRef.close("APPROVED");
            },
            (error) => {
              this.alertService.showMessage("Wystąpił błąd podczas przypisywania kierowcy", MessageType.Error);
              this.addingCard = false;
              console.error(error);
            }
          );
        } else {
          this.alertService.showMessage("Pomyślnie dodano kartę jako zatwierdzoną!", MessageType.Info);
          this.addingCard = false;
          this.dialogRef.close("APPROVED");
        }
      },
      (e) => {
        // Error calling PlanKpo: {\"WasteMass\":[\"'Waste Mass' must not be empty.\"]}
        const error: string = e.error;
        console.log(error);
        const matches = /\{.*?\}/g.exec(error);
        let errorMessage = "";
        if (matches.length === 1) {
          const json = matches[0].replace("\\", "");
          const parsed = JSON.parse(json);
          for (const element in parsed) {
            if (parsed[element]) {
              parsed[element].forEach((line) => {
                errorMessage += `"${line}", `;
              });
            }
          }
          errorMessage = errorMessage.substr(0, errorMessage.length - 2);
          this.alertService.showMessage("Wystąpił błąd podczas dodawania karty: \n" + errorMessage, MessageType.Error);
        } else {
          this.alertService.showMessage("Wystąpił niespodziewany podczas dodawania karty", MessageType.Error);
        }
        this.addingCard = false;
        console.error(error);
      }
    );
  }

  addPlannedCard(form) {
    if (!form.form.valid) {
      return;
    }

    this.addingCard = true;
    this.details.cardStatus = "Planowana";
    this.details.wasteCode = this.wasteCodeModel.code;
    this.details.wasteCodeDescription = this.wasteCodeModel.description;
    this.details.wasteCodeId = this.wasteCodeModel.wasteCodeId;
    this.details.wasteProcessId = this.wasteProcessModel?.wasteProcessId;
    this.details.senderCompanyId = this.senderCompany.companyId;
    this.details.senderCompanyName = this.senderCompany.name;
    this.details.senderEupId = this.senderCompanyEup.eupId;
    this.details.carrierCompanyId = this.carrierCompany.companyId;
    this.details.carrierCompanyName = this.carrierCompany.name;
    this.details.receiverCompanyId = this.receiverCompany.companyId;
    this.details.receiverEupId = this.receiverCompanyEup.eupId;
    this.details.receiverCompanyAddress = this.receiverCompany.address;
    this.details.receiverCompanyName = this.receiverCompany.name;
    this.details.creationDateTime = new Date();

    this.details.wasteGeneratedTerytPk = this.details.isWasteGenerating ? this.wasteGeneratedTerytPkModel.pk : "";
    if (!this.details.isWasteGenerating) {
      this.details.wasteGeneratingAdditionalInfo = "";
    }

    if (this.details.hazardousWasteReclassification == false) this.details.hazardousWasteReclassificationDescription = undefined;
    if (this.details.wasteCodeExtended == false) this.details.wasteCodeExtendedDescription = undefined;

    this.details.plannedTransportTime = this.plannedDate;

    this.details.plannedTransportTime.setHours(this.plannedTime.getHours(), this.plannedTime.getMinutes());

    const driverKey = this.driver ? `[nr. kier. ${this.driver.key}]` : this.details.vehicleRegNumber == "" ? undefined : this.details.vehicleRegNumber;

    this.extendedKpoService.addAsPlanned(this.details, driverKey).subscribe(
      (result) => {
        this.alertService.showMessage("Pomyślnie dodano kartę jako planowaną!", MessageType.Info);
        this.addingCard = false;
        this.dialogRef.close("PLANNED");
      },
      (e) => {
        const error: string = e.error;
        console.log(error);
        const matches = /\{.*?\}/g.exec(error);
        let errorMessage = "";
        if (matches.length === 1) {
          const json = matches[0].replace("\\", "");
          const parsed = JSON.parse(json);
          for (const element in parsed) {
            if (parsed[element]) {
              parsed[element].forEach((line) => {
                errorMessage += `"${line}", `;
              });
            }
          }
          errorMessage = errorMessage.substr(0, errorMessage.length - 2);
          this.alertService.showMessage("Wystąpił błąd podczas dodawania karty: \n" + errorMessage, MessageType.Error);
        } else {
          this.alertService.showMessage("Wystąpił niespodziewany podczas dodawania karty", MessageType.Error);
        }
        this.addingCard = false;
        console.error(error);
      }
    );
  }
}
