import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  Inject,
} from "@angular/core";
import {
  ExtendedKpoService,
  DraftCardsService,
  WasteRegisterPublicApiApiModelsElasticsearchCompanyEs,
  WasteRegisterPublicApiApiModelsElasticsearchEupEs,
} from "../../../../../../../api";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { fromEvent, Subscription } from "rxjs";
import "rxjs/add/operator/debounceTime";
import { CardService } from "../../../../../services/card.service";
import {
  AlertService,
  MessageType,
} from "../../../../../services/alert.service";

@Component({
  selector: "app-select-receiver-dialog",
  templateUrl: "./select-receiver-dialog.component.html",
  styleUrls: ["./select-receiver-dialog.component.scss"],
})
export class SelectReceiverDialogComponent implements AfterViewInit, OnDestroy {
  working = false;
  receiverCompany: WasteRegisterPublicApiApiModelsElasticsearchCompanyEs;
  receiverCompanyEup: WasteRegisterPublicApiApiModelsElasticsearchEupEs;
  constructor(
    public dialogRef: MatDialogRef<SelectReceiverDialogComponent>,
    private cardService: CardService,
    private draftCardsService: DraftCardsService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngAfterViewInit(): void {}

  setCompany(company: WasteRegisterPublicApiApiModelsElasticsearchCompanyEs) {
    this.receiverCompany = company;
  }

  setEup(eup: WasteRegisterPublicApiApiModelsElasticsearchEupEs) {
    this.receiverCompanyEup = eup;
  }

  changeReceiver() {
    const model = {
      ...this.data.model,
      receiverCompanyId: this.receiverCompany.companyId,
      receiverEupId: this.receiverCompanyEup.eupId,
      receiverCompanyName: this.receiverCompany.name,
      receiverCompanyAddress: this.receiverCompany.address,
      cardStatus: this.cardService.awaitingUserApproval,
    };
    this.draftCardsService
      .update(model.id, model, "akceptacja karty")
      .subscribe(
        () => {
          this.alertService.showMessage(
            "Karta została przyjęta i oczekuje na zatwierdzenie przez przekazującego.",
            MessageType.Info
          );
          this.dialogRef.close(true);
        },
        (error) => {
          this.alertService.showMessage(
            "Wystąpił błąd podczas przetwarzania zapytania",
            MessageType.Error
          );
          console.error(error);
        }
      );
  }

  ngOnDestroy(): void {}
}
