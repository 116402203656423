import { Component, OnInit, Inject } from "@angular/core";
import { UsersService, ExtendedKpoService, CarsService, WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1UpdateApprovedKpoAndGenerateConfirmationAsCarrierRequest, BdoServiceModelsEnumerationsCompanyType } from "../../../../../../api";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SelectDriverDialogComponent } from "../select-driver-dialog/select-driver-dialog.component";
import { DatePipe } from "@angular/common";
import { MAT_DATE_LOCALE, DateAdapter } from "@angular/material/core";
import { AlertService, MessageType } from "../../../../services/alert.service";
import { CookieService } from "ngx-cookie-service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { RoleService } from "../../../../services/role.service";

// tslint:disable-next-line:max-line-length
type ConfirmationRequest = WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1UpdateApprovedKpoAndGenerateConfirmationAsCarrierRequest;

@UntilDestroy()
@Component({
  selector: "app-generate-confirmation-dialog",
  templateUrl: "./generate-confirmation-dialog.component.html",
  styleUrls: ["./generate-confirmation-dialog.component.css"],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: "pl-PL" }],
})
export class GenerateConfirmationDialogComponent implements OnInit {
  constructor(public datepipe: DatePipe, private carsService: CarsService, private extendedKpoService: ExtendedKpoService, private alertService: AlertService, public dialogRef: MatDialogRef<SelectDriverDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private _adapter: DateAdapter<any>, private cookieService: CookieService, private roleService: RoleService) {
    this.roleService.role$.pipe(untilDestroyed(this)).subscribe((r) => (this.role = r));
  }
  working = false;
  car: any;
  cars: any;
  role: string;
  model: ConfirmationRequest = {};

  ngOnInit(): void {
    this._adapter.setLocale("pl");
    this.carsService.getAll().subscribe((result) => (this.cars = result));
    this.model.realTransportDate = new Date();
    this.model.realTransportTime = new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    this.car = this.data.model.vehicleRegNumber;
  }

  save() {
    this.model.vehicleRegNumber = this.role == "CARRIER" ? this.car.registrationNumber : this.car;
    this.working = true;
    this.extendedKpoService.generateConfirmation(this.data.model.kpoId, this.model, this.role == "CARRIER" ? BdoServiceModelsEnumerationsCompanyType.NUMBER_2 : BdoServiceModelsEnumerationsCompanyType.NUMBER_1).subscribe(
      () => {
        this.alertService.showMessage("Pomyślnie wygenerowano potwierdzenie.", MessageType.Info);
        this.dialogRef.close(true);
      },
      (error) => {
        this.alertService.showMessage(error.error, MessageType.Error);
        this.working = false;
      }
    );
  }
}
