import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { WasteTransferCardService } from "../../../../../../api";
import { AlertService, MessageType } from "../../../../services/alert.service";

@Component({
  selector: "app-filing-confirmation-dialog",
  templateUrl: "./filing-confirmation-dialog.component.html",
  styleUrls: ["./filing-confirmation-dialog.component.scss"],
})
export class FilingConfirmationDialogComponent implements OnInit {
  public isInInstallation: boolean;
  public isSender: boolean;

  constructor(
    public dialogRef: MatDialogRef<FilingConfirmationDialogComponent>,
    private wasteTransferCardService: WasteTransferCardService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isSender = data.isSender;
  }

  ngOnInit(): void {}

  markForFiling(addToGenerated: boolean) {
    this.wasteTransferCardService
      .markForFiling({
        addToGenerated: addToGenerated,
        ids: this.data.selectedCards,
        isInInstalation: this.isInInstallation,
      })
      .subscribe(
        () => {
          this.alertService.showMessage(
            "Pomyślnie oznaczono karty do ewidencji",
            MessageType.Info
          );
          this.dialogRef.close(true);
        },
        (error) =>
          this.alertService.showMessage(
            error.error ? error.error : error,
            MessageType.Error
          )
      );
  }
}
