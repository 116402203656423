<button mat-raised-button style="margin: 200px calc(50% - 115px)" (click)="ngOnInit()" *ngIf="error && !loading">Ponów próbę</button>
<div>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Raporty</mat-card-title>
      <mat-card-subtitle>Generowanie zestawień z obsłużonych KPO</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-spinner *ngIf="loading" style="margin: 90px auto"></mat-spinner>
      <button mat-raised-button style="margin: 90px auto" (click)="ngOnInit()" *ngIf="error && !loading">Ponów próbę</button>

      <form>
        <div fxLayout="row wrap">
          <mat-form-field appearance="outline" fxFlex="62.5%" fxFlex.lt-md="100%">
            <mat-label>Podmiot przekazującego</mat-label>
            <input type="text" placeholder="" matInput [formControl]="senderCompanyControl" [matAutocomplete]="auto" />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option.label">
                {{ option.label }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="32.5%" fxFlex.lt-md="100%">
            <mat-label>MPD Przekazującego</mat-label>
            <input type="text" placeholder="" [(ngModel)]="headquarterAddress" matInput name="headquarterAddress" />
          </mat-form-field>
          <mat-form-field fxFlex="30%" fxFlex.lt-md="100%" appearance="outline">
            <mat-label>Zakres dat</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate placeholder="Początek" [(ngModel)]="dateRangeFrom" name="dateRangeFrom" />
              <input matEndDate placeholder="Koniec" [(ngModel)]="dateRangeTo" name="dateRangeTo" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="30%" fxFlex.lt-md="100%">
            <mat-label>Miejsce wytworzenia odpadu</mat-label>
            <input type="text" placeholder="" [(ngModel)]="teryt" matInput name="teryt" />
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="32.5%" fxFlex.lt-md="100%">
            <mat-label>Kod odpadu</mat-label>
            <input type="text" placeholder="" [(ngModel)]="wasteCode" matInput name="wasteCode" />
          </mat-form-field>
        </div>
      </form>
    </mat-card-content>
    <mat-card-actions style="text-align: right">
      <button mat-button color="primary" (click)="generateReport()" [disabled]="isWorking"><span *ngIf="!isWorking">Generuj raport</span><span *ngIf="isWorking">Generowanie...</span></button>
    </mat-card-actions>
  </mat-card>
</div>
