import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CookieService } from "ngx-cookie-service";
import { ExtendedKpoService } from "../../../../../../api";
import { AlertService, MessageType } from "../../../../services/alert.service";
import { RoleService } from "../../../../services/role.service";
import { ConfirmReciveDialogComponent } from "../confirm-recive-dialog/confirm-recive-dialog.component";

@Component({
  selector: "app-confirm-withdrawal-dialog",
  templateUrl: "./confirm-withdrawal-dialog.component.html",
  styleUrls: ["./confirm-withdrawal-dialog.component.scss"],
})
export class ConfirmWithdrawalDialogComponent implements OnInit {
  remarks = "";
  working = false;
  constructor(private extendedKpoService: ExtendedKpoService, public dialogRef: MatDialogRef<ConfirmReciveDialogComponent>, private alertService: AlertService, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {}

  confirm() {
    this.working = true;
    this.extendedKpoService.withdraw(this.data.model.kpoId, { remarks: this.remarks }).subscribe(
      () => {
        this.alertService.showMessage("Pomyślnie wycofano kartę.", MessageType.Info);
        this.dialogRef.close(true);
      },
      (error) => {
        this.alertService.showMessage("Wystąpił błąd podczas wycofania karty", MessageType.Info);
        this.working = false;
      }
    );
  }
}
