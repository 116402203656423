/**
 * BDOEX API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * Contact: mateusz.surma@mae.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1ReviseKpoRequest
 */
export interface WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1ReviseKpoRequest { 
    /**
     * Id karty przekazania odpadów
     */
    kpoId?: string;
    /**
     * Id kodu odpadu
     */
    wasteCodeId?: number;
    /**
     * Masa odpadów
     */
    wasteMass?: number;
    /**
     * Kod ex
     */
    wasteCodeExtended?: boolean;
    /**
     * Rodzaj odpadu ex
     */
    wasteCodeExtendedDescription?: string;
    /**
     * Zmiana statusu odpadów niebezpiecznych na odpady inne niż niebezpieczne
     */
    hazardousWasteReclassification?: boolean;
    /**
     * Rodzaj odpadu
     */
    hazardousWasteReclassificationDescription?: string;
}