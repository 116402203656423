<mat-spinner *ngIf="details == undefined" style="margin: 40px auto"></mat-spinner>
<mat-tab-group *ngIf="details != undefined">
  <mat-tab label="Ogólne">
    <div class="container" fxLayout="wrap row" fxLayoutAlign="left" fxLayoutGap="1%" fxLayoutGap.lt-md="0">
      <mat-form-field appearance="outline" fxFlex="100%">
        <mat-label>Numer karty</mat-label>
        <input matInput autocomplete="off" readonly [value]="data.model.cardNumber != null ? data.model.cardNumber : 'Brak numeru karty'" />
      </mat-form-field>

      <div style="width: 100%" *ngFor="let change of data.changes" class="container" fxLayout="wrap row" fxLayoutAlign="left" fxLayoutGap="1%" fxLayoutGap.lt-md="0">
        <mat-form-field appearance="outline" fxFlex="22%" fxFlex.lt-md="100%">
          <mat-label>Data</mat-label>
          <input matInput autocomplete="off" readonly [value]="this.datepipe.transform(change.dateTime, 'dd.MM.yyyy HH:mm')" />
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="42%" fxFlex.lt-md="100%">
          <mat-label>Akcja</mat-label>
          <input matInput autocomplete="off" readonly [value]="change.change" />
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="32%" fxFlex.lt-md="100%" *ngIf="change.user != null">
          <mat-label>Imię i nazwisko wysyłającego</mat-label>
          <input matInput autocomplete="off" readonly [value]="change.user?.firstName + ' ' + change.user?.lastName" />
          <!-- <input matInput autocomplete="off" readonly value]="data.model.senderFirstNameAndLastName" /> -->
        </mat-form-field>
      </div>

      <div style="width: 100%" class="container" fxLayout="wrap row" fxLayoutAlign="left" fxLayoutGap="1%" fxLayoutGap.lt-md="0"></div>
    </div>
  </mat-tab>

  <mat-tab label="Przekazujący">
    <mat-spinner *ngIf="senderCompany == undefined || senderCompanyEup == undefined" style="margin: 0 auto"> </mat-spinner>
    <div *ngIf="senderCompany != undefined && senderCompanyEup != undefined" class="container" fxLayout="wrap row" fxLayoutAlign="left" fxLayoutGap="1%" fxLayoutGap.lt-md="0">
      <h3 fxFlex="100%" class="margin-top-zero">Informacje o podmiocie</h3>
      <mat-form-field appearance="outline" fxFlex="24%" fxFlex.lt-md="100%" *ngIf="senderCompany.registrationNumber != undefined">
        <mat-label>Numer rejestrowy</mat-label>
        <input matInput autocomplete="off" readonly [value]="senderCompany.registrationNumber" />
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex="74%" fxFlex.lt-md="100%" *ngIf="data.model.senderName != undefined">
        <mat-label>Nazwa</mat-label>
        <input matInput autocomplete="off" readonly [value]="data.model.senderName" />
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex="24%" fxFlex.lt-md="100%" *ngIf="senderCompany.nip != undefined">
        <mat-label>NIP</mat-label>
        <input matInput autocomplete="off" readonly [value]="senderCompany.nip" />
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex="24%" fxFlex.lt-md="100%" *ngFor="let item of senderCompany.address?.split(', ')">
        <mat-label>{{ item.split(": ")[0] }}</mat-label>
        <input matInput autocomplete="off" readonly [value]="item.split(': ')[1] === '' || item.split(': ')[1] === undefined ? '(brak danych)' : item.split(': ')[1]" />
      </mat-form-field>

      <h3 fxFlex="100%">Miejsce prowadzenia działalności</h3>

      <mat-form-field appearance="outline" fxFlex="24%" fxFlex.lt-md="100%" *ngIf="senderCompanyEup.registrationNumber != undefined">
        <mat-label>Numer miejsca</mat-label>
        <input matInput autocomplete="off" readonly [value]="senderCompanyEup.registrationNumber" />
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex="74%" fxFlex.lt-md="100%" *ngIf="senderCompanyEup.name != undefined">
        <mat-label>Nazwa</mat-label>
        <input matInput autocomplete="off" readonly [value]="senderCompanyEup.name" />
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex="24%" fxFlex.lt-md="100%" *ngFor="let item of senderCompanyEup.address?.split(', ')">
        <mat-label>{{ item.split(": ")[0] }}</mat-label>
        <input matInput autocomplete="off" readonly [value]="item.split(': ')[1] === '' || item.split(': ')[1] === undefined ? '(brak danych)' : item.split(': ')[1]" />
      </mat-form-field>

      <mat-spinner *ngIf="details == undefined" style="margin: 0 auto"></mat-spinner>
      <div *ngIf="details != undefined" fxFlex="100%" fxFlex.lt-md="100%" fxLayout="wrap row" fxLayoutAlign="left" fxLayoutGap="1%" fxLayoutGap.lt-md="0">
        <h3 fxFlex="100%">Miejsce wytwarzania odpadów</h3>
        <mat-form-field appearance="outline" style="width: 100%" *ngIf="details.wasteGeneratedTeryt !== undefined">
          <mat-label>Miejsce wytwarzania odpadów</mat-label>

          <input matInput autocomplete="off" *ngIf="!details.isWasteGenerating" readonly [value]="'zgodnie ze wskazanym powyżej miejscem prowadzenia działalności'" />
          <input matInput autocomplete="off" *ngIf="details.isWasteGenerating" readonly [value]="stripHtml(details.wasteGeneratedTeryt)" />
        </mat-form-field>
        <mat-form-field appearance="outline" style="width: 100%" *ngIf="details.isWasteGenerating && details.wasteGeneratingAdditionalInfo != null">
          <mat-label>Dodatkowe informacje</mat-label>
          <input matInput autocomplete="off" readonly [value]="details.wasteGeneratingAdditionalInfo" />
        </mat-form-field>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Transportujący" *ngIf="role == 'SENDER'">
    <mat-spinner *ngIf="carrierCompany == undefined" style="margin: 0 auto"> </mat-spinner>
    <div *ngIf="carrierCompany != undefined" class="container" fxLayout="wrap row" fxLayoutAlign="left" fxLayoutGap="1%" fxLayoutGap.lt-md="0">
      <h3 fxFlex="100%" class="margin-top-zero">Informacje o podmiocie</h3>
      <mat-form-field appearance="outline" fxFlex="24%" fxFlex.lt-md="100%" *ngIf="carrierCompany.registrationNumber != undefined">
        <mat-label>Numer rejestrowy</mat-label>
        <input matInput autocomplete="off" readonly [value]="carrierCompany.registrationNumber" />
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex="74%" fxFlex.lt-md="100%" *ngIf="carrierCompany.name != undefined">
        <mat-label>Nazwa</mat-label>
        <input matInput autocomplete="off" readonly [value]="carrierCompany.name" />
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex="24" fxFlex.lt-md="100%" *ngIf="carrierCompany.nip != undefined">
        <mat-label>NIP</mat-label>
        <input matInput autocomplete="off" readonly [value]="carrierCompany.nip" />
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex="24%" fxFlex.lt-md="100%" *ngFor="let item of carrierCompany.address?.split(', ')">
        <mat-label>{{ item.split(": ")[0] }}</mat-label>
        <input matInput autocomplete="off" readonly [value]="item.split(': ')[1] === '' || item.split(': ')[1] === undefined ? '(brak danych)' : item.split(': ')[1]" />
      </mat-form-field>
    </div>
  </mat-tab>

  <mat-tab label="Przejmujący">
    <mat-spinner *ngIf="receiverCompany == undefined || receiverCompanyEup == undefined" style="margin: 0 auto"> </mat-spinner>
    <div *ngIf="receiverCompany != undefined && receiverCompanyEup != undefined" class="container" fxLayout="wrap row" fxLayoutAlign="left" fxLayoutGap="1%" fxLayoutGap.lt-md="0">
      <h3 fxFlex="100%" class="margin-top-zero">Informacje o podmiocie</h3>
      <mat-form-field appearance="outline" fxFlex="24%" fxFlex.lt-md="100%" *ngIf="receiverCompany.registrationNumber != undefined">
        <mat-label>Numer rejestrowy</mat-label>
        <input matInput autocomplete="off" readonly [value]="receiverCompany.registrationNumber" />
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex="74%" fxFlex.lt-md="100%" *ngIf="data.model.receiverName != undefined">
        <mat-label>Nazwa</mat-label>
        <input matInput autocomplete="off" readonly [value]="data.model.receiverName" />
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex="24" fxFlex.lt-md="100%" *ngIf="receiverCompany.nip != undefined">
        <mat-label>NIP</mat-label>
        <input matInput autocomplete="off" readonly [value]="receiverCompany.nip" />
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex="24%" fxFlex.lt-md="100%" *ngFor="let item of receiverCompany.address?.split(', ')">
        <mat-label>{{ item.split(": ")[0] }}</mat-label>
        <input matInput autocomplete="off" readonly [value]="item.split(': ')[1] === '' || item.split(': ')[1] === undefined ? '(brak danych)' : item.split(': ')[1]" />
      </mat-form-field>

      <h3 fxFlex="100%">Miejsce prowadzenia działalności</h3>

      <mat-form-field appearance="outline" fxFlex="24%" fxFlex.lt-md="100%" *ngIf="receiverCompanyEup.registrationNumber != undefined">
        <mat-label>Numer miejsca</mat-label>
        <input matInput autocomplete="off" readonly [value]="receiverCompanyEup.registrationNumber" />
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex="74%" fxFlex.lt-md="100%" *ngIf="receiverCompanyEup.name != undefined">
        <mat-label>Nazwa</mat-label>
        <input matInput autocomplete="off" readonly [value]="receiverCompanyEup.name" />
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex="24%" fxFlex.lt-md="100%" *ngFor="let item of receiverCompanyEup.address?.split(', ')">
        <mat-label>{{ item.split(": ")[0] }}</mat-label>
        <input matInput autocomplete="off" readonly [value]="item.split(': ')[1] === '' || item.split(': ')[1] === undefined ? '(brak danych)' : item.split(': ')[1]" />
      </mat-form-field>
    </div>
  </mat-tab>

  <mat-tab label="Odpady">
    <mat-spinner *ngIf="details == undefined" style="margin: 0 auto"></mat-spinner>
    <div *ngIf="details != undefined" class="container" fxLayout="wrap row" fxLayoutAlign="left" fxLayoutGap="1%" fxLayoutGap.lt-md="0">
      <mat-form-field appearance="outline" fxFlex="24%" fxFlex.lt-md="100%" *ngIf="data.model.wasteCode !== undefined">
        <mat-label>Kod odpadów</mat-label>
        <input matInput autocomplete="off" readonly [value]="data.model.wasteCode" />
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex="74%" fxFlex.lt-md="100%" *ngIf="data.model.wasteCodeDescription !== undefined">
        <mat-label>Opis odpadów</mat-label>
        <input matInput autocomplete="off" readonly [value]="data.model.wasteCodeDescription" />
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex="24%" fxFlex.lt-md="100%" *ngIf="details.wasteMass !== undefined">
        <mat-label>Masa odpadów [Tony]</mat-label>
        <input matInput autocomplete="off" readonly [value]="details.wasteMass" />
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex="24%" fxFlex.lt-md="100%" *ngIf="data.model.plannedTransportTime !== undefined">
        <mat-label>Planowany czas transportu</mat-label>
        <input matInput autocomplete="off" readonly [value]="this.datepipe.transform(data.model.plannedTransportTime, 'dd.MM.yyyy HH:mm')" />
      </mat-form-field>

      <div fxFlex="49%"></div>
      <mat-form-field appearance="outline" fxFlex="99%" fxFlex.lt-md="100%">
        <mat-label>Rodzaj procesu przetwarzania</mat-label>
        <input matInput autocomplete="off" readonly [value]="wasteProcess == null ? '-' : wasteProcess.codeName + ' ' + wasteProcess.name" />
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex="99%" fxFlex.lt-md="100%">
        <mat-label>Numer certyfikatu oraz numer pojemników</mat-label>
        <input matInput autocomplete="off" readonly [value]="details.certificateNumberAndBoxNumbers == null ? '-' : details.certificateNumberAndBoxNumbers" />
      </mat-form-field>

      <div class="checkbox-field" fxFlex="24%" fxFlex.lt-md="100%">
        <mat-checkbox [checked]="data.model.wasteCodeExtended" disabled>Kod EX</mat-checkbox>
      </div>

      <mat-form-field appearance="outline" fxFlex="74%" fxFlex.lt-md="100%">
        <mat-label>Rodzaj odpadu ex </mat-label>
        <input matInput autocomplete="off" readonly [value]="details.wasteCodeExtendedDescription == undefined ? '-' : details.wasteCodeExtendedDescription" />
      </mat-form-field>

      <div class="checkbox-field" fxFlex="24%" fxFlex.lt-md="100%">
        <mat-checkbox [checked]="details.hazardousWasteReclassification" disabled>Inne niż niebezpieczne </mat-checkbox>
      </div>

      <mat-form-field appearance="outline" fxFlex="74%" fxFlex.lt-md="100%">
        <mat-label>Rodzaj odpadu</mat-label>
        <input matInput autocomplete="off" readonly [value]="details.hazardousWasteReclassificationDescription == undefined ? '-' : details.hazardousWasteReclassificationDescription" />
      </mat-form-field>

      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>Dodatkowe informacje</mat-label>
        <input matInput autocomplete="off" readonly [value]="details.additionalInfo == null ? '-' : details.additionalInfo" />
      </mat-form-field>
    </div>
  </mat-tab>
</mat-tab-group>
<mat-dialog-actions>
  <button mat-button (click)="dialogRef.close()">Zamknij</button>
</mat-dialog-actions>
