


            <mat-form-field appearance="standard" style="width: 50%">
                <mat-label>Wyszukaj</mat-label>
                <input #search matInput [(ngModel)]="query">
            </mat-form-field>
            
                <mat-spinner *ngIf="loading" style="margin: 20px auto;"></mat-spinner>
              <div class="table-container">
                  <table *ngIf="!loading" mat-table [dataSource]="dataSource" matSort>
                      <ng-container matColumnDef="registrationNumber">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Numer rejestrowy </th>
                          <td mat-cell *matCellDef="let element"> <span class="name-cell"> {{element.registrationNumber}} </span> </td>
                        </ng-container>
                        <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nazwa </th>
                          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                        </ng-container>
                        <ng-container matColumnDef="address">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Adres</th>
                            <td mat-cell *matCellDef="let element"> {{parseAddress(element.address)}} </td>
                          </ng-container>
                        
             
              
                    <ng-container matColumnDef="action">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let element"> 
                          <button mat-button (click)="setCompany(element)">Wybierz</button>
                         
              
                      </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                    </tr>
                  </table>
                </div>
                  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  
    