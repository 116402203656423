import { Component, OnInit, Output, Input, ViewChild, EventEmitter, AfterViewInit } from '@angular/core';
import { MatSelectionListChange, MatSelectionList } from '@angular/material/list';

@Component({
  selector: 'app-days-selector',
  templateUrl: './days-selector.component.html',
  styleUrls: ['./days-selector.component.scss']
})
export class DaysSelectorComponent implements AfterViewInit {


  _selectedDays = [];

  @Output() selectedDaysChange = new EventEmitter();
  @Input() get selectedDays() {
    return this._selectedDays;
  }
  set selectedDays(val) {
    this._selectedDays = val;
    this.selectedDaysChange.emit(val);
  }

  @ViewChild(MatSelectionList, { static: true}) matList: MatSelectionList;

  daysOfWeek =
    [
      { name: 'Poniedziałek', value: 'Monday', checked: false },
      { name: 'Wtorek', value: 'Tuesday', checked: false },
      { name: 'Środa', value: 'Wednesday', checked: false },
      { name: 'Czwartek', value: 'Thursday', checked: false },
      { name: 'Piątek', value: 'Friday', checked: false },
      { name: 'Sobota', value: 'Saturday', checked: false },
      { name: 'Niedziela', value: 'Sunday', checked: false },
    ];

    daysSelectionChange($event: MatSelectionListChange) {
      if ($event.option.value === 'All') {
        if ($event.source.selectedOptions.selected.length === $event.source.options.length - 1 &&
          $event.source.selectedOptions.selected.find(p => p.value === 'All') == null) {
          $event.source.deselectAll();
        } else {
          $event.source.selectAll();
        }
      } else {
        if ($event.source.selectedOptions.selected.length === $event.source.options.length - 1 &&
          $event.source.selectedOptions.selected.find(p => p.value === 'All') == null) {
          $event.source.selectAll();
        } else {
          $event.source.options.find(p => p.value === 'All').selected = false;
        }
      }
      this.selectedDays =
        $event.source.selectedOptions.selected
          .filter(d => d.value !== 'All')
          .map(day => day.value);
    }

    ngAfterViewInit() {
      if (this.selectedDays === undefined) {
        return;
      }
      this.selectedDays.forEach(element => {
        this.matList.options.find(p => p.value === element).selected = true;
      });

      if(this.selectedDays.length==7)
      this.matList.options.find(p => p.value === 'All').selected = true;
    }
    
}
