import { Component, OnInit, Inject } from "@angular/core";

import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import {
  UserDto,
  UsersService,
  GroupsService,
  ExtendedKpoService,
} from "../../../../../../api";
import { AlertService, MessageType } from "../../../../services/alert.service";

@Component({
  selector: "app-select-driver-dialog",
  templateUrl: "./select-driver-dialog.component.html",
  styleUrls: ["./select-driver-dialog.component.css"],
})
export class SelectDriverDialogComponent implements OnInit {
  groupId: number;
  drivers: UserDto[];
  driverId = 0;
  working = false;
  constructor(
    private driversService: UsersService,
    private groupsService: GroupsService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private extendedKpoService: ExtendedKpoService,
    public dialogRef: MatDialogRef<SelectDriverDialogComponent>,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.groupsService.getAll().subscribe((result) => {
      this.groupId = result.find((g) => g.name === "Kierowca").id;

      this.driversService
        .getAllAssigned(this.groupId)
        .subscribe((drivers) => (this.drivers = drivers));
    });
    this.extendedKpoService.getAssignedDriver(this.data.model.kpoId).subscribe(
      (result) => {
        this.driverId = result.id;
      },
      (error) => {}
    );
  }

  selectDriver(driverId) {
    this.working = true;
    this.extendedKpoService.getSelfCompany().subscribe((currentCompany) => {
      this.extendedKpoService
        .assignDriver(
          this.data.model.kpoId,
          driverId,
          this.data.model.cardStatusCodeName,
          currentCompany.companyId,
          this.storage.get("eupId"),
          true
        )
        .subscribe(
          () => {
            this.alertService.showMessage(
              "Pomyślnie przypisano kierowcę. Karta trafiła do wykazu aktualnie realizowanych zleceń. ",
              MessageType.Info
            );
            this.dialogRef.close(true);
          },
          (error) => {
            this.alertService.showMessage(
              "Wystąpił błąd podczas przypisywania kierowcy",
              MessageType.Error
            );
            this.working = false;
          }
        );
    });
  }
}
