/**
 * BDOEX API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * Contact: mateusz.surma@mae.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * WasteRegisterPublicApiApiModelsElasticsearchTerytEs
 */
export interface WasteRegisterPublicApiApiModelsElasticsearchTerytEs { 
    /**
     * Gets or Sets PK
     */
    pk?: string;
    /**
     * Gets or Sets Fulltext
     */
    fulltext?: string;
    /**
     * Gets or Sets FormattedFulltext
     */
    formattedFulltext?: string;
    /**
     * Gets or Sets HtmlFulltext
     */
    htmlFulltext?: string;
    /**
     * Gets or Sets Wojewodztwo
     */
    wojewodztwo?: string;
    /**
     * Gets or Sets Powiat
     */
    powiat?: string;
    /**
     * Gets or Sets Gmina
     */
    gmina?: string;
    /**
     * Gets or Sets RodzajGminy
     */
    rodzajGminy?: string;
    /**
     * Gets or Sets Miejscowosc
     */
    miejscowosc?: string;
    /**
     * Gets or Sets MiejscowoscPodstawowa
     */
    miejscowoscPodstawowa?: string;
    /**
     * Gets or Sets RodzajMiejscowosci
     */
    rodzajMiejscowosci?: string;
    /**
     * Gets or Sets Ulica
     */
    ulica?: string;
    /**
     * Gets or Sets RodzajUlicy
     */
    rodzajUlicy?: string;
    /**
     * Gets or Sets BrakUlicy
     */
    brakUlicy?: string;
    /**
     * Gets or Sets ExactGmMie
     */
    exactGmMie?: string;
}