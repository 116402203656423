import { Component, OnInit } from '@angular/core';
import { ExtendedTableComponent } from '../../../../helpers/extended-management';
import { AlertService } from '../../../../services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { UserWizardComponent } from '../user-wizard/user-wizard.component';
import { UsersService, UserDto } from '../../../../../../api';

@Component({
  selector: 'app-users-view',
  templateUrl: './users-view.component.html',
  styleUrls: ['./users-view.component.scss']
})
export class UsersViewComponent extends ExtendedTableComponent implements OnInit {
loading = true;
  displayedColumns = ['select', 'name', 'details'];
  constructor(  private alertService: AlertService,
    private userService: UsersService,
    private dialog: MatDialog
    ) { super();}
    openDialog(mode: string, user: UserDto) {
      const dialogRef = this.dialog.open(UserWizardComponent,
          {
              data: { mode: mode, user: user },
              width: '500px'
          });

      dialogRef.afterClosed().subscribe((result: any) => {
          if (result) {
              this.reloadData();
          }
      });
  }
  reloadData() {
      this.loading = true;
      this.userService.getAll().subscribe((result: UserDto[]) => { this.updateTableData(result); });
  }

  ngOnInit() {
      this.reloadData();
  }


}
