import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserService } from "../../../../../api";
import { UserWizardComponent } from "../../pages/users/user-wizard/user-wizard.component";

@Component({
  selector: "app-eula-acceptance-dialog",
  templateUrl: "./eula-acceptance-dialog.component.html",
  styleUrls: ["./eula-acceptance-dialog.component.css"],
})
export class EulaAcceptanceDialogComponent implements OnInit {
  constructor(
    private userService: UserService,
    public dialog: MatDialogRef<UserWizardComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {}

  accept() {
    this.dialog.close(true);
  }

  reject() {
    this.dialog.close(false);
  }
}
