import { Component, OnInit, ViewChild, ElementRef, Inject } from "@angular/core";
import { UserService, UserDto, ExtendedKpoService } from "../../../../api";
import { ChooseLocationDialogComponent } from "../dialogs/choose-location-dialog/choose-location-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { UserWizardComponent } from "../pages/users/user-wizard/user-wizard.component";
import { UserWizardDialogComponent } from "../dialogs/user-wizard-dialog/user-wizard-dialog.component";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { Router } from "@angular/router";
import { Overlay } from "@angular/cdk/overlay";
import { RoleService } from "../../services/role.service";
import { ChangeRoleDialogComponent } from "../dialogs/change-role-dialog/change-role-dialog.component";
import { delay, retryWhen, take } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-person-small-info",
  templateUrl: "./person-small-info.component.html",
  styleUrls: ["./person-small-info.component.scss"],
})
export class PersonSmallInfoComponent implements OnInit {
  user: UserDto;
  currentHeadquarter: any;
  role: string;

  @ViewChild("profileButton") profileButtonRef: ElementRef;
  scrollStrategy = this.overlay.scrollStrategies.block();

  constructor(private userService: UserService, private cookieService: CookieService, @Inject(LOCAL_STORAGE) private storage: WebStorageService, private extendedKpoService: ExtendedKpoService, private router: Router, public dialog: MatDialog, private overlay: Overlay, private roleService: RoleService) {}

  openDialog(): void {
    const dialogRef = this.dialog.open(ChooseLocationDialogComponent, {
      width: "650px",
      data: {},
      closeOnNavigation: true,
      scrollStrategy: this.scrollStrategy,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === undefined) {
        return;
      }
      this.currentHeadquarter = result;
      this.extendedKpoService.getCompanyToken(result.eupId).subscribe((token) => {
        this.cookieService.set("token", token, 1, "/");
        this.cookieService.set("token", token, { expires: 1, sameSite: "None", secure: true });
      });
    });
  }

  openUserViewDialog(): void {
    const dialogRef = this.dialog.open(UserWizardDialogComponent, {
      width: "650px",
      data: {},
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });
  }

  ngOnInit() {
    this.currentHeadquarter = this.storage.get("place");
    this.roleService.role$.subscribe((role) => (this.role = role));

    this.userService
      .getCurrentUser()
      .pipe(retryWhen((errors) => errors.pipe(delay(1000), take(5))))
      .subscribe(
        (result) => {
          this.user = result;
        },
        (error) => {
          this.router.navigateByUrl("/login");
        }
      );
  }

  openRoleChangeDialog() {
    const dialogRef = this.dialog.open(ChangeRoleDialogComponent, {
      width: "450px",
      data: {},
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });
  }
}
