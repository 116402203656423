<h2 mat-dialog-title>Wygeneruj potwierdzenie</h2>
<div mat-dialog-content>
  <mat-form-field appearance="standard">
    <mat-label>Godzina rozpoczęcia transportu</mat-label>
    <input autocomplete="off" [(ngModel)]="model.realTransportTime" placeholder="00:00" matInput />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Data rozpoczęcia transportu</mat-label>
    <input matInput autocomplete="off" [(ngModel)]="model.realTransportDate" [matDatepicker]="picker" />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <mat-form-field *ngIf="role == 'CARRIER'; else input">
    <mat-label>Wybierz pojazd</mat-label>
    <mat-select [(value)]="car">
      <mat-option *ngFor="let car of cars" [value]="car"> {{ car.name }} [{{ car.registrationNumber }}] </mat-option>
    </mat-select>
  </mat-form-field>
  <ng-template #input>
    <mat-form-field>
      <mat-label>Numer rejestracyjny</mat-label>
      <input matInput [(ngModel)]="car" />
    </mat-form-field>
  </ng-template>
</div>
<div mat-dialog-actions>
  <button mat-button color="warn" (click)="dialogRef.close()">Anuluj</button>
  <button mat-button (click)="save()" [disabled]="working">Zapisz</button>
</div>
