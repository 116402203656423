<div fxLayout="row" fxLayoutAlign="space-around center">
  <ng-container *ngFor="let route of routing">
    <ng-container *ngIf="route.data.asExtended !== true; else extendedMenu">
      <ng-container *ngFor="let childRoute of route.children">
        <a class="menu-item" #rla="routerLinkActive" [routerLink]="route.path + '/' + childRoute.path" routerLinkActive="active" *ngIf="shouldRenderItem(childRoute)">
          <div class="menu-icon">
            <img [class.hidden]="rla.isActive" [src]="childRoute.data.icon" />
            <img [class.hidden]="!rla.isActive" [src]="childRoute.data.activeIcon" />
          </div>
        </a>
      </ng-container>
    </ng-container>
    <ng-template #extendedMenu>
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Extended menu" class="extended-menu-button">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <ng-container *ngFor="let childRoute of route.children">
          <a mat-menu-item class="menu-item" #rla="routerLinkActive" [routerLink]="route.path + '/' + childRoute.path" routerLinkActive="active" *ngIf="shouldRenderItem(childRoute)">
            {{ childRoute.data.title }}
          </a>
        </ng-container>
      </mat-menu>
    </ng-template>
  </ng-container>
</div>
