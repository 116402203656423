<mat-spinner *ngIf="loading" style="margin: 90px auto;"></mat-spinner>
<div [ngStyle]="{ display: loading ? 'none' : 'inherit' }">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Podmioty powiązane</mat-card-title>
      <mat-card-subtitle>Podgląd lisy podmiotów powiązanych z systemem</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field>
        <mat-label>Filtruj podmioty</mat-label>
        <input matInput autocomplete="off" (keyup)="applyFilter($event)" />
      </mat-form-field>

      <mat-spinner *ngIf="loading" style="margin: 20px auto;"></mat-spinner>

      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nazwa podmiotu </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
        <ng-container matColumnDef="active">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Aktywny </th>
          <td mat-cell *matCellDef="let element">
            <mat-slide-toggle [(ngModel)]="element.active" (change)="switch($event, element)"></mat-slide-toggle>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" style="text-align: right;">
            <button mat-button color="primary" (click)="removeSubject(element)">Usuń</button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator [pageSize]="20" [pageSizeOptions]="[ 10, 20, 30, 50]"></mat-paginator>
    </mat-card-content>
    <mat-card-actions>
      <button (click)="openDialog()" mat-button>Dodaj</button>

    </mat-card-actions>
  </mat-card>
</div>