/**
 * BDOEX API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * Contact: mateusz.surma@mae.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteRecordCardV1KeoForwardedListItem
 */
export interface WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteRecordCardV1KeoForwardedListItem { 
    /**
     * Id wpisu Przekazane odpady
     */
    keoForwardedId?: string;
    /**
     * Gets or Sets WasteCollectionDate
     */
    wasteCollectionDate?: Date;
    /**
     * Masa odpadów
     */
    wasteMass?: number;
    /**
     * Numer karty kpo
     */
    cardNumberKpo?: string;
    /**
     * Numer karty kpok
     */
    cardNumberKpok?: string;
    /**
     * Możliwe wartości: 'BASED_ON_KPO_CARD',  'BASED_ON_KPOK_CARD', 'EXPORT', 'TO_PHISICAL_PERSONS',  'NOT_OBLIGATED_WASTE_HOLDER'
     */
    transferWay?: string;
    /**
     * Gets or Sets TransportDate
     */
    transportDate?: Date;
    /**
     * Utworzone przez użytkownika
     */
    createdByUser?: string;
}