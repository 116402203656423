

    <mat-card class="mat-elevation-z2">
        <mat-card-header>
            <mat-card-title>Dziennik zdarzeń</mat-card-title>
        </mat-card-header>
        <mat-card-content>
                <div [ngStyle]="{'display': (loading?'none':'inherit')}">
            <table mat-table [dataSource]="dataSource">           
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef> Data </th>
                    <td mat-cell *matCellDef="let element"> {{moment(element.date).format('LLLL')}} </td>
                </ng-container>
                <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef> Typ </th>
                        <td mat-cell *matCellDef="let element"> 
                                <span *ngIf="element.type === 1">Informacja</span> 
                                <span *ngIf="element.type === 0">Wyjątek</span> 
                                <span *ngIf="element.type === 2">Błąd</span> 
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="message">
                            <th mat-header-cell *matHeaderCellDef> Wiadomość </th>
                            <td mat-cell *matCellDef="let element"> {{element.message}} </td>
                        </ng-container>
                <ng-container matColumnDef="details">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-form-field style="float:right;">
                            <input matInput autocomplete="off" (keyup)="applyFilter($event.target.value)" placeholder="Filtruj">
                        </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index;" style="text-align: right;">
                          <button mat-button [matMenuTriggerFor]="proportiesMenu" color="primary">Właściwości</button>
                          <mat-menu #proportiesMenu="matMenu">
                              <button mat-menu-item color="primary" (click)="viewDialog(element)">Podgląd</button>
                              <button mat-menu-item color="primary" (click)="editDialog(element)">Edytuj</button>
                          </mat-menu>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
           
  </div>
        </mat-card-content>
        <div mat-dialog-actions>
                <button mat-button (click)="clear();">Wyczyść</button>
        </div>
    </mat-card>