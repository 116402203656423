import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AuthenticationService } from './api/authentication.service';
import { BackupService } from './api/backup.service';
import { CarsService } from './api/cars.service';
import { ConsoleService } from './api/console.service';
import { DraftCardsService } from './api/draftCards.service';
import { DriverAppService } from './api/driverApp.service';
import { ExtendedKpoService } from './api/extendedKpo.service';
import { GroupsService } from './api/groups.service';
import { MobileAppService } from './api/mobileApp.service';
import { RecordCardService } from './api/recordCard.service';
import { ServerManagementService } from './api/serverManagement.service';
import { SubjectAuthenticationService } from './api/subjectAuthentication.service';
import { UserService } from './api/user.service';
import { UsersService } from './api/users.service';
import { VersionService } from './api/version.service';
import { WasteTransferCardService } from './api/wasteTransferCard.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AuthenticationService,
    BackupService,
    CarsService,
    ConsoleService,
    DraftCardsService,
    DriverAppService,
    ExtendedKpoService,
    GroupsService,
    MobileAppService,
    RecordCardService,
    ServerManagementService,
    SubjectAuthenticationService,
    UserService,
    UsersService,
    VersionService,
    WasteTransferCardService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
