/**
 * BDOEX API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * Contact: mateusz.surma@mae.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1UpdateApprovedKpoAndGenerateConfirmationAsCarrierRequest
 */
export interface WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1UpdateApprovedKpoAndGenerateConfirmationAsCarrierRequest { 
    /**
     * Id karty przekazania odpadów
     */
    kpoId?: string;
    /**
     * Numer rejestracyjny środka transportu
     */
    vehicleRegNumber?: string;
    /**
     * Rzeczywista godzina rozpoczęcia transportu
     */
    realTransportTime?: string;
    /**
     * Godzina rozpoczęcia transportu
     */
    realTransportDate?: Date;
}