import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { KEOGeneratedDto, RecordCardService } from "../../../../../api";
import { AlertService, MessageType } from "../../../services/alert.service";

@UntilDestroy()
@Component({
  selector: "app-filed-generated-waste-transfer-cards",
  templateUrl: "./filed-generated-waste-transfer-cards.component.html",
  styleUrls: ["./filed-generated-waste-transfer-cards.component.scss"],
})
export class FiledGeneratedWasteTransferCardsComponent implements OnInit {
  public details: KEOGeneratedDto;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  loading = false;
  constructor(public dialogRef: MatDialogRef<FiledGeneratedWasteTransferCardsComponent>, private service: RecordCardService, @Inject(MAT_DIALOG_DATA) public data: { cardId: string }, private alertService: AlertService) {}

  load() {
    this.loading = true;
    this.service.getKEOGenerateList(this.data.cardId, this.paginator.pageIndex + 1, this.paginator.pageSize).subscribe(
      (result) => {
        this.details = result;
        this.paginator.length = result.generatedKeos.totalResultNumber;
      },
      (error) => this.alertService.showMessage(error, MessageType.Error),
      () => (this.loading = false)
    );
  }

  ngOnInit(): void {
    this.load();
    this.paginator.page.pipe(untilDestroyed(this)).subscribe((p) => this.load());
  }
}
