<mat-card>
  <mat-card-header>
    <mat-card-title>Wykaz użytkowników</mat-card-title>
    <mat-card-subtitle>Zarządzanie kontami użytkowników</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <mat-spinner *ngIf="isLoading; else results" style="margin: 90px auto"></mat-spinner>

    <ng-template #results>
      <ng-container *ngFor="let item of filteredResults$ | async">
        <mat-accordion>
          <app-card-block header="Nazwa" text="{{ item.firstName }} {{ item.lastName }}" [value]="item">
            <div fxLayout="row wrap">
              <app-card-block-field header="Login" *ngIf="item.username" fxFlex="100%" fxFlex.gt-lg="50%">
                {{ item.username }}
              </app-card-block-field>
              <app-card-block-field header="Login" *ngIf="item.group" fxFlex="100%" fxFlex.gt-lg="50%">
                {{ item.group.name }}
              </app-card-block-field>
            </div>
            <button mat-button color="primary" *ngIf="item.group?.name === 'Dyspozytor'" (click)="openWizard('dispatcher', 'edit', item)" class="actions-menu">Edytuj</button>
            <button mat-button color="primary" *ngIf="item.group?.name === 'Kierowca'" (click)="openWizard('driver', 'edit', item)" class="actions-menu">Edytuj</button>
          </app-card-block>
        </mat-accordion>
      </ng-container>
    </ng-template>
    <mat-paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 30, 50]"></mat-paginator>
    <div style="text-align: right; width: 100%; margin-top: 16px">
      <button mat-flat-button color="primary" (click)="openWizard('driver', 'create')" class="actions-menu" *ngIf="role == 'CARRIER'">Dodaj konto kierowcy</button>
      <button mat-flat-button color="primary" (click)="openWizard('dispatcher', 'create')" class="actions-menu" *ngIf="role == 'CARRIER'">Dodaj konto dyspozytora</button>
      <button mat-flat-button color="primary" (click)="openWizard('dispatcher', 'create')" class="actions-menu" *ngIf="role == 'SENDER'">Dodaj konto</button>
    </div>
  </mat-card-content>
</mat-card>
