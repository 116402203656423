<mat-spinner *ngIf="loading" style="margin: 90px auto;"></mat-spinner>

<div [ngStyle]="{'display': (loading?'none':'inherit')}">

    <mat-card>
        <mat-card-header>
            <mat-card-title>Wykaz rejestru zmian</mat-card-title>
            <mat-card-subtitle>Rejestr zmian wszystkich kart w systemie</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>

            <table mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef style="width: 80px">
                        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
                            <span>&nbsp;</span>
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="change">
                    <th mat-header-cell *matHeaderCellDef> Akcja </th>
                    <td mat-cell *matCellDef="let element"> {{element.change}} </td>
                </ng-container>
                <ng-container matColumnDef="dateTime">
                    <th mat-header-cell *matHeaderCellDef> Data </th>
                    
                    <td mat-cell *matCellDef="let element"> {{this.datepipe.transform(element.dateTime, 'dd.MM.yyyy HH:mm')}} </td>
                </ng-container>
                <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef> Użytkownik </th>
                    <td mat-cell *matCellDef="let element"> {{element.user.firstName}} {{element.user.lastName}} </td>
                </ng-container>
                <ng-container matColumnDef="subject">
                    <th mat-header-cell *matHeaderCellDef> Podmiot </th>
                    <td mat-cell *matCellDef="let element"> {{element.subject?.name}} </td>
                </ng-container>
                <ng-container matColumnDef="kpoId">
                    <th mat-header-cell *matHeaderCellDef> ID KPO </th>
                    <td mat-cell *matCellDef="let element"> {{element.kpoId}} </td>
                </ng-container>
                <ng-container matColumnDef="details">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-form-field style="float:right;">
                            <input matInput autocomplete="off" (keyup)="applyFilter($event.target.value)" placeholder="Filtruj">
                        </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index;" style="text-align: right;">
                        <!-- <button mat-button color="primary" (click)="openDialog('edit', element)">Edytuj</button> -->
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 30, 50]" showFirstLastButtons></mat-paginator>
        </mat-card-content>
    </mat-card>
</div>