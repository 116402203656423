<h2 mat-dialog-title> Opisz powód odrzucenia transportu </h2>
<div mat-dialog-content>
    <mat-form-field class="example-full-width">
        <mat-label>Powód odrzucenia transportu</mat-label>
        <input matInput autocomplete="off" [(ngModel)]="remarks" placeholder="..." />
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button color="warn" (click)="dialogRef.close();">Anuluj</button>
    <button mat-button (click)="reject()" [disabled]="working">Odrzuć</button>
</div>