import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { CarsService, CarDto, UserService, ChangesRegisterEntityDto, SubjectDto, SubjectAuthenticationService, DraftCardsService, DraftCardDto, ExtendedKpoService } from '../../../../../api';
import { ExtendedTableComponent } from '../../../helpers/extended-management';
import { AlertService, MessageType } from '../../../services/alert.service';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { CarWizardComponent } from '../cars/car-wizard/car-wizard.component';

@Component({
  selector: 'app-user-actions-log',
  templateUrl: './user-actions-log.component.html',
  styleUrls: ['./user-actions-log.component.css']
})
export class UserActionsLogComponent extends ExtendedTableComponent implements OnInit {
  displayedColumns = ["select", "dateTime", "user", "subject", "kpoId", "details"];

  public subject: SubjectDto;
  public subjects: SubjectDto[];
  public currentDraftCards: DraftCardDto[];

  constructor(
    private alertService: AlertService,
    private userService: UserService,
    private draftCardsService: DraftCardsService,
    private extendedKpoService: ExtendedKpoService,
    private subjectAuthenticationService: SubjectAuthenticationService,
    public datepipe: DatePipe,
    private dialog: MatDialog
  ) {
    super();
  }

  // openDialog(mode: string, car: CarDto = {}) {
  //   const dialogRef = this.dialog.open(CarWizardComponent, {
  //     data: { mode: mode, car: { ...car } },
  //     width: "500px",
  //   });

  //   dialogRef.afterClosed().subscribe((result: any) => {
  //     if (result) {
  //       this.reloadData();
  //     }
  //   });
  // }

  // deleteSelected() {
  //   const dialogRef = this.dialog.open(ConfirmDialogComponent, {
  //     width: "350px",
  //     data: { message: "Czy na pewno chcesz usunąć ten podmiot?" },
  //   });

  //   dialogRef.afterClosed().subscribe((result) => {
  //     if (result) {
  //       this.selection.selected.forEach((val, index, array) => {
  //         this.carService._delete(val.id).subscribe(
  //           (next) => {
  //             this.alertService.showMessage(
  //               "Pomyślnie usunięto pojazd",
  //               MessageType.Info
  //             );
  //             this.selection.clear();
  //             this.reloadData();
  //           },
  //           (error) => {
  //             this.alertService.showMessage(
  //               "Wystąpił błąd podczas usuwania pojazdu: " + error.error,
  //               MessageType.Error
  //             );
  //           }
  //         );
  //       });
  //     }
  //   });
  // }
  

  reloadData() {
    this.loading = true;
    this.subjectAuthenticationService.getSubjects().subscribe(result => {
      this.subjects = result;
      this.userService.getChangesRegister().subscribe((result: ChangesRegisterEntityDto[]) => {
        let o = result.map(r => 
          Object.assign({subject: this.subjects.find(s => s.userId == r.user?.id)}, r));

        let arr = [];
        o.forEach(element => {
          if(arr.find(x => 
            element.kpoId == x.kpoId && 
            element.user?.firstName == x.user?.firstName && 
            element.user?.lastName == x.user?.lastName) == null) {
            arr.push(element);
          }
        });
        this.updateTableData(arr);
      });
    });

    //this.draftCardsService.getAll(this.subject.companyId).subscribe(result => this.currentDraftCards = result);
    //this.extendedKpoService.getKpoAsCarrier()

    
  }

  

  exportData() {
    const data = JSON.stringify(this.dataSource.data, null, 2);
    const blob: Blob = new Blob([data], { type: "text/json" });
    const fileName = "export.json";
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement(
      "a"
    ) as HTMLAnchorElement;

    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }
  ngOnInit() {
    this.reloadData();
  }
}
