import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  SubjectAuthenticationService,
  SubjectCreationDto,
  UserService
} from '../../../../../../../api';
import { AlertService, MessageType } from '../../../../../services/alert.service';

@Component({
  selector: 'app-add-subject-dialog',
  templateUrl: './add-subject-dialog.component.html',
  styleUrls: ['./add-subject-dialog.component.scss']
})
export class AddSubjectDialogComponent implements OnInit {
  constructor(
    private alertService: AlertService,
    private userService: UserService,
    private subjectAuthenticationService: SubjectAuthenticationService,
    public dialogRef: MatDialogRef<AddSubjectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  model: SubjectCreationDto = {};

  ngOnInit() {}

  addSubject() {
    this.userService.getCurrentUser().subscribe(result => {
      this.model.userId = result.id;
      this.model.clientId = this.model.clientId.trim();
      this.model.clientSecret = this.model.clientSecret.trim();
      this.subjectAuthenticationService.addSubject(this.model, this.data?.user?.id).subscribe(result => {
        this.alertService.showMessage('Pomyślnie dodano podmiot!', MessageType.Info);
        this.dialogRef.close();
      }, error => this.alertService.showMessage("Błędny klucz API. Spróbuj ponownie. ", MessageType.Error));
    });
  }
}
