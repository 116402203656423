<mat-card>
  <mat-card-header>
    <mat-card-title>Statystyka obsłużonych KPO</mat-card-title>
    <mat-card-subtitle>Wykaz kart podlegających rozliczeniu w ramach umowy</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <app-bdo-filter [allowedCardStatuses]="filterCardStatuses" showRole="true">
    </app-bdo-filter>

    <mat-spinner *ngIf="loading" style="margin: 90px auto;"></mat-spinner>
    <button mat-raised-button style="margin: 90px auto;" (click)="ngOnInit()" *ngIf="error && !loading">
      Ponów próbę
    </button>
    <ng-container>
      <ng-container *ngFor="let item of filteredResults$ | async">
        <mat-accordion>
          <app-card-block [details]="item">
            <app-card-block-field header="Numer karty" *ngIf="item.cardNumber">
              {{item.cardNumber}}
            </app-card-block-field>
            <app-card-block-field header="Kod odpadów">
              {{item.wasteCode}}
            </app-card-block-field>
            <app-card-block-field header="Przypisany kierowca" *ngIf="item.user">
              {{item.user.firstName}} {{item.user.lastName}}
            </app-card-block-field>
            <app-card-block-field header="Data utworzenia/modyfikacji">
              {{getLastModificationDate(item) | date: 'dd.MM.yyyy HH:mm'}}
            </app-card-block-field>
            <app-card-block-field header="Status karty">
              {{item.cardStatus}}
            </app-card-block-field>
            <app-card-block-field header="Planowany transport">
              {{item.plannedTransportTime | date: 'dd.MM.yyyy HH:mm'}}
            </app-card-block-field>
            <button mat-flat-button color="primary" [matMenuTriggerFor]="menu" class="actions-menu">Akcje</button>
            <mat-menu #menu="matMenu">
              <button (click)="openDetailsDialog(item)" mat-menu-item>Podgląd</button>
            </mat-menu>
          </app-card-block>
        </mat-accordion>
      </ng-container>
    </ng-container>
    <mat-paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 30, 50]"></mat-paginator>
    <mat-card-actions><button mat-flat-button color="primary" (click)="generateData([])"
        [disabled]="isDocumentGenerating">Generuj CSV <span *ngIf="isDocumentGenerating">{{documentGeneratingStatus}} /
          {{documentGeneratingMaxStatus}}</span></button></mat-card-actions>
  </mat-card-content>
</mat-card>