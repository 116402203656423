import { Component, OnInit } from "@angular/core";
import { ExtendedTableComponent } from "../../../helpers/extended-management";
import { AlertService, MessageType } from "../../../services/alert.service";
import { MatDialog } from "@angular/material/dialog";

import { GroupWizardComponent } from "./group-wizard/group-wizard.component";
import { GroupsService, GroupDto } from "../../../../../api";
import { ConfirmDialogComponent } from "../../dialogs/confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-groups",
  templateUrl: "./groups.component.html",
  styleUrls: ["./groups.component.scss"],
})
export class GroupsComponent extends ExtendedTableComponent implements OnInit {
  displayedColumns = ["select", "name", "operations"];
  constructor(
    private alertService: AlertService,
    private groupService: GroupsService,
    private dialog: MatDialog
  ) {
    super();
  }

  openDialog(mode: string, group: GroupDto = {}) {
    const dialogRef = this.dialog.open(GroupWizardComponent, {
      data: { mode: mode, group: group },
      width: "500px",
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.reloadData();
      }
    });
  }

  deleteSelected() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "350px",
      data: { message: "Czy na pewno chcesz usunąć ten podmiot?" },
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selection.selected.forEach((element) => {
          this.groupService._delete(element.id).subscribe(
            (next) => {
              this.alertService.showMessage(
                "Pomyślnie usunięto grupę",
                MessageType.Info
              );

              this.selection.clear();
              this.reloadData();
            },
            (error) =>
              this.alertService.showMessage(
                "Błąd podczas usuwania grupy",
                MessageType.Error
              )
          );
        });
      }
    });
  }

  exportData() {
    const data = JSON.stringify(this.dataSource.data, null, 2);
    const blob: Blob = new Blob([data], { type: "text/json" });
    const fileName = "export.json";
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement(
      "a"
    ) as HTMLAnchorElement;

    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }

  reloadData() {
    this.loading = true;
    this.groupService.getAll().subscribe((result) => {
      this.updateTableData(result);
    });
  }

  ngOnInit() {
    this.reloadData();
  }
}
