/**
 * BDOEX API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * Contact: mateusz.surma@mae.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteRecordCardV1KeoCollectedListItem
 */
export interface WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteRecordCardV1KeoCollectedListItem { 
    /**
     * Id wpisu Przyjęte odpady
     */
    keoCollectedId?: string;
    /**
     * Utworzone przez użytkownika
     */
    createdByUser?: string;
    /**
     * Data przejęcia odpadów
     */
    collectedDate?: Date;
    /**
     * Gets or sets possible values include: 'NOT_SELECTED',  'BASED_ON_KPO_CARD', 'BASED_ON_KPOK_CARD', 'IMPORT',  'NOT_OBLIGATED_WASTE_HOLDER'
     */
    collectionWay?: string;
    /**
     * Numer karty KPO
     */
    kpoCardNumber?: string;
    /**
     * Numer karty KPOK
     */
    kpokCardNumber?: string;
    /**
     * Masa przejętych odpadów [Mg]
     */
    collectedMass?: number;
    /**
     * Nazwa instalacji
     */
    installationName?: string;
}