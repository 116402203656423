<div *ngIf="data.mode=='create'">
    <h2 mat-dialog-title> Dodawanie pojazdu </h2>
</div>
<div *ngIf="data.mode=='edit'">
    <h2 mat-dialog-title> Edycja </h2>
</div>
<div *ngIf="data.mode=='view'">
    <h2 mat-dialog-title> Podgląd </h2>
</div>



<form #form="ngForm" (ngSubmit)="form.valid && onSubmit(form)" autocomplete="off">
    <div mat-dialog-content>

        <mat-form-field class="col" appearance="outline">
            <mat-label>Nazwa</mat-label>
            <input matInput autocomplete="off" name="firstName" placeholder="..." [(ngModel)]="car.name"
                [readonly]="data.mode=='view'" [required]="data.mode!='view'">
        </mat-form-field>

        <mat-form-field class="col" appearance="outline">
            <mat-label>Numer rejestracyjny</mat-label>
            <input matInput autocomplete="off" name="lastName" placeholder="XXX 00000"
                [(ngModel)]="car.registrationNumber" [readonly]="data.mode=='view'"
                [required]="data.mode!='view'">
        </mat-form-field>


    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="dialog.close(false);" type="button" *ngIf="data.mode==='view'">Zamknij</button>
        <button mat-button color="warn" (click)="dialog.close(false);" type="button"
            *ngIf="data.mode!='view'">Anuluj</button>
        <button mat-button color="primary" style="float:right;" type="submit" *ngIf="data.mode!='view'">Zapisz</button>



    </div>
</form>