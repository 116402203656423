<mat-card cdk-scrollable>
  <mat-card-header>
    <mat-card-title> Wydruk KPO </mat-card-title>
    <mat-card-subtitle>
      Pobieranie kpo o statusie Zrealizowane przejęcie jako plik PDF* <br /><br />
      ( * ) KPO doładowywane są cyklicznie z urzędowej bazy. Karta, której szukasz może pojawić się w wykazie z kilkuminutowym opóźnieniem.
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <app-bdo-filter> </app-bdo-filter>

    <mat-spinner *ngIf="loading" style="margin: 90px auto"></mat-spinner>

    <ng-container>
      <ng-container *ngFor="let item of filteredResults">
        <mat-accordion>
          <app-card-block [details]="item" [check]="true" [header]="item.wasteTransferCard.cardNumber" (checked)="item.checked = $event" [checkboxChecked]="item.checked" class="green-label">
            <div fxLayout="row wrap">
              <app-card-block-field header="Kod odpadów" fxFlex="50%" fxFlex.gt-lg="50%">
                {{ item.wasteTransferCard.wasteCode }}
              </app-card-block-field>
              <app-card-block-field header="Nazwa przejmującego" *ngIf="item.wasteTransferCard.receiverName" fxFlex="50%" fxFlex.gt-lg="50%">
                {{ item.wasteTransferCard.receiverName }}
              </app-card-block-field>
              <app-card-block-field header="Masa odpadów" *ngIf="item.wasteTransferCardDetails.wasteMass" fxFlex="50%" fxFlex.gt-lg="50%">
                {{ item.wasteTransferCardDetails.wasteMass }}
              </app-card-block-field>

              <app-card-block-field header="Data przekazania" fxFlex="50%" fxFlex.gt-lg="50%">
                {{ item.wasteTransferCardDetails.transportConfirmationTime | date : "dd.MM.yyyy HH:mm" }}
              </app-card-block-field>
            </div>
            <!-- <button mat-button color="primary" [matMenuTriggerFor]="menu"
                              class="actions-menu">Akcje</button>
                          <mat-menu #menu="matMenu">
                              <button (click)="openDetailsDialog(item)" mat-menu-item>Podgląd</button>
                          </mat-menu> -->
          </app-card-block>
        </mat-accordion>
      </ng-container>
    </ng-container>
    <mat-paginator [pageSize]="50" [pageSizeOptions]="[10, 20, 30, 50, 100, 200, 500, 1000]"></mat-paginator>
    <mat-card-actions style="text-align: right">
      <button mat-flat-button color="secondary" style="margin: 8px" (click)="selectAll()">Zaznacz wszystkie</button>
      <button mat-flat-button color="primary" style="margin: 8px" (click)="downloadArchive()" [disabled]="isWorking || !anySelected()">Pobierz PDF</button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
