/**
 * BDOEX API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * Contact: mateusz.surma@mae.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1GetWithdrawnKpoDto
 */
export interface WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1GetWithdrawnKpoDto { 
    /**
     * Gets or Sets IsWasteGenerating
     */
    isWasteGenerating?: boolean;
    /**
     * Gets or Sets WasteGeneratedTeryt
     */
    wasteGeneratedTeryt?: string;
    /**
     * Gets or Sets WasteGeneratingAdditionalInfo
     */
    wasteGeneratingAdditionalInfo?: string;
    /**
     * Gets or Sets WasteGeneratedTerytPk
     */
    wasteGeneratedTerytPk?: string;
    /**
     * Id karty przekazania odpadów
     */
    kpoId?: string;
    /**
     * Rok kalendarzowy
     */
    year?: number;
    /**
     * Numer Karty Przekazania Odpadów
     */
    cardNumber?: string;
    /**
     * Id statusu Karty Przekazania Odpadów
     */
    cardStatusId?: number;
    /**
     * Status karty
     */
    cardStatus?: string;
    /**
     * Poprzedni kod statusu
     */
    previousStatusCodeName?: string;
    /**
     * Uwagi
     */
    remarks?: string;
    /**
     * Id podmiotu przekazującego odpady
     */
    senderCompanyId?: string;
    /**
     * Id miejsca prowadzenia działalności przekazującego odpady
     */
    senderEupId?: string;
    /**
     * Id podmiotu transportującego odpady
     */
    carrierCompanyId?: string;
    /**
     * Id podmiotu przejmującego odpady
     */
    receiverCompanyId?: string;
    /**
     * Id miejsca prowadzenia działalności przejmującego odpady
     */
    receiverEupId?: string;
    /**
     * Id kodu odpadu
     */
    wasteCodeId?: number;
    /**
     * Numer rejestracyjny środka transportu
     */
    vehicleRegNumber?: string;
    /**
     * Masa odpadów
     */
    wasteMass?: number;
    /**
     * Planowana data i godzina transportu
     */
    plannedTransportTime?: Date;
    /**
     * Data zatwierdzenia karty
     */
    cardApprovalTime?: Date;
    /**
     * Użytkownik zatwierdzający kartę
     */
    approvalUser?: string;
    /**
     * Rzeczywista data i godzina transportu
     */
    realTransportTime?: Date;
    /**
     * Id procesu przetwarzania odpadów
     */
    wasteProcessId?: number;
    /**
     * Numer certyfikatu oraz numery pojemników
     */
    certificateNumberAndBoxNumbers?: string;
    /**
     * Data wycofania karty
     */
    cardWithdrawalTime?: Date;
    /**
     * Gets or Sets WithdrawnByUser
     */
    withdrawnByUser?: string;
    /**
     * Użytkownik generujący potwierdzenie
     */
    generatingConfirmationUser?: string;
    /**
     * Data wygenerowania potwierdzenia
     */
    generatingConfirmationTime?: Date;
    /**
     * Oznaczenie kodowe statusu karty
     */
    cardStatusCodeName?: string;
    /**
     * Kod ex
     */
    wasteCodeExtended?: boolean;
    /**
     * Rodzaj odpadu ex
     */
    wasteCodeExtendedDescription?: string;
    /**
     * Zmiana statusu odpadów niebezpiecznych na odpady inne niż niebezpieczne
     */
    hazardousWasteReclassification?: boolean;
    /**
     * Informacje dodatkowe
     */
    additionalInfo?: string;
    /**
     * Rodzaj odpadu
     */
    hazardousWasteReclassificationDescription?: string;
}