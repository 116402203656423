<div *ngIf="data.mode=='create'">
    <h2 mat-dialog-title> Tworzenie konta </h2>
</div>
<div *ngIf="data.mode=='edit'">
    <h2 mat-dialog-title> Edycja </h2>
</div>
<div *ngIf="data.mode=='view'">
    <h2 mat-dialog-title> Podgląd </h2>
</div>

<form #form="ngForm" (ngSubmit)="form.valid && onSubmit(form)" autocomplete="off" autocomplete="off">
    <div mat-dialog-content fxLayout="wrap row" fxLayoutAlign="left" fxLayoutGap="1%">
        <mat-form-field appearance="outline" fxFlex="49%" fxFlex.lt-md="100%">
            <mat-label>Imię</mat-label>
            <input matInput autocomplete="off" name="firstName" placeholder="Jan" [(ngModel)]="user.firstName"
                [readonly]="data.mode=='view'" [required]="data.mode!='view'" (ngModelChange)="nameChange($event)">
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="49%" fxFlex.lt-md="100%">
            <mat-label>Nazwisko</mat-label>
            <input matInput autocomplete="off" name="lastName" placeholder="Kowalski" [(ngModel)]="user.lastName"
                [readonly]="data.mode=='view'" [required]="data.mode!='view'" (ngModelChange)="nameChange($event)">
        </mat-form-field>
        <mat-form-field class="full-width" appearance="outline">
            <mat-label>Nazwa użytkownika</mat-label>
            <input matInput autocomplete="off" placeholder="username" name="username" [(ngModel)]="user.username"
                [readonly]="data.mode=='view'" [required]="data.mode!='view'">
            <span matPrefix style="font-size: 16px;">{{randomPrefix}}_</span>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
            <mat-label>Pin</mat-label>
            <input matInput autocomplete="off" name="pin" [type]="hidePassword ? 'password' : 'text'"
                [(ngModel)]="password" (keypress)="passwordChanged($event)" (keyup)="passwordChanged($event)"
                minlength="4" maxlength="8" [required]="data.mode=='create'"
                (ngModelChange)="passwordModelChanged($event)">
            <mat-icon matSuffix (click)="hidePassword = !hidePassword">
                {{hidePassword ? 'visibility' : 'visibility_off'}}
            </mat-icon>
        </mat-form-field>

        <!-- <mat-checkbox [checked]="sendMail" (change)="valueChange($event)" 
            *ngIf="data.mode!='view'">
            Wyślij potwierdzenie na adres E-Mail
        </mat-checkbox> -->
        <p>&nbsp;</p>


    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="dialog.close(false);" type="button" *ngIf="data.mode==='view'">Zamknij</button>
        <button mat-button color="warn" (click)="dialog.close(false);" type="button"
            *ngIf="data.mode!='view'">Anuluj</button>
        <button mat-button color="primary" style="float:right;" type="submit" *ngIf="data.mode!='view'">Zapisz</button>
    </div>
</form>