import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable } from "rxjs";
import { delay, take } from "rxjs/operators";
import { UserService } from "../../../../../api";

@UntilDestroy()
@Component({
  selector: "app-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"],
})
export class LandingPageComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router, private userService: UserService) {}

  ngOnInit(): void {
    this.userService.getCurrentUser().subscribe(
      (result) => {
        this.route.queryParams.pipe(take(1)).subscribe((params) => this.router.navigateByUrl(params.redirect));
      },
      (error) => this.router.navigateByUrl("/login")
    );
  }
}
