import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExtendedKpoService, DraftCardsService,
  WasteRegisterPublicApiApiModelsElasticsearchCompanyEs,
  WasteRegisterPublicApiApiModelsElasticsearchEupEs, DraftCardDto, BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto } from '../../../../../../../api';
import { AlertService, MessageType } from '../../../../../services/alert.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-view-draft-card-dialog',
  templateUrl: './view-draft-card-dialog.component.html',
  styleUrls: ['./view-draft-card-dialog.component.css']
})
export class ViewDraftCardDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ViewDraftCardDialogComponent>,
    private extendedKpoService: ExtendedKpoService,
    private draftCardsService: DraftCardsService,
    private alertService: AlertService,
    public datepipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any) { }



  details: DraftCardDto;
  wasteProcess: BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto;
  senderCompany: WasteRegisterPublicApiApiModelsElasticsearchCompanyEs = undefined;
  senderCompanyEup: WasteRegisterPublicApiApiModelsElasticsearchEupEs = undefined;
  receiverCompany: WasteRegisterPublicApiApiModelsElasticsearchCompanyEs = undefined;
  receiverCompanyEup: WasteRegisterPublicApiApiModelsElasticsearchEupEs = undefined;

  ngOnInit() {
      this.details = this.data.model;
      if (this.details.wasteProcessId != null) {
        this.extendedKpoService
          .getWasteProcessById(this.details.wasteProcessId)
          .subscribe(result => this.wasteProcess = result);
      }
      this.extendedKpoService.getCompany(this.details.senderCompanyId).subscribe(senderCompany => {
        this.senderCompany = senderCompany;
        this.extendedKpoService.getCompanyEup(this.details.senderEupId).subscribe(senderEup => {
          this.senderCompanyEup = senderEup;
        }, error => this.alertService.showMessage("Nie można załadować danych siedziby firmy przekazującego", MessageType.Error));
      }, error => this.alertService.showMessage("Nie można załadować danych firmy przekazującego", MessageType.Error));

      if (this.details.receiverCompanyId != undefined) {
        this.extendedKpoService.getCompany(this.details.receiverCompanyId).subscribe(receiverCompany => {
          this.receiverCompany = receiverCompany;
          this.extendedKpoService.getCompanyEup(this.details.senderEupId).subscribe(
            receiverEup => this.receiverCompanyEup = receiverEup
          , error => this.alertService.showMessage("Nie można załadować danych siedziby firmy przejmującego", MessageType.Error));
        }, error => { this.alertService.showMessage("Nie można załadować danych firmy przejmującego", MessageType.Error); });
      }
  }
}
