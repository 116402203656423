<div *ngIf="data.mode=='create'">
    <h2 mat-dialog-title> Tworzenie konta </h2>
</div>
<div *ngIf="data.mode=='edit'">
    <h2 mat-dialog-title> Edycja </h2>
</div>
<div *ngIf="data.mode=='view'">
    <h2 mat-dialog-title> Podgląd </h2>
</div>

<form #form="ngForm" (ngSubmit)="form.valid && onSubmit(form)" autocomplete="off" autocomplete="off">
    <div mat-dialog-content fxLayout="wrap row" fxLayoutAlign="left" fxLayoutGap="1%">

        <mat-form-field appearance="outline" fxFlex="49%" fxFlex.lt-md="100%">
            <mat-label>Imię</mat-label>
            <input matInput autocomplete="off" name="firstName" placeholder="Jan" [(ngModel)]="user.firstName"
                [readonly]="data.mode=='view'" [required]="data.mode!='view'">
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="49%" fxFlex.lt-md="100%">
            <mat-label>Nazwisko</mat-label>
            <input matInput autocomplete="off" name="lastName" placeholder="Kowalski" [(ngModel)]="user.lastName"
                [readonly]="data.mode=='view'" [required]="data.mode!='view'">
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
            <mat-label>E-Mail</mat-label>
            <input matInput autocomplete="off" placeholder="example@mae.pl" name="username" [(ngModel)]="user.username"
                [readonly]="data.mode=='view'" [required]="data.mode!='view'" email>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
            <mat-label *ngIf="data.mode=='create'">Hasło</mat-label>
            <mat-label *ngIf="data.mode=='edit'">Hasło</mat-label>
            <input matInput autocomplete="off" name="password" [type]="hidePassword ? 'password' : 'text'"
                [(ngModel)]="password" [required]="data.mode=='create'" minlength="4" maxlength="32">
            <mat-icon matSuffix (click)="hidePassword = !hidePassword">
                {{hidePassword ? 'visibility' : 'visibility_off'}}
            </mat-icon>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline" *ngIf="data.mode!='view'||user.phoneNumber==null">
            <mat-label>Numer telefonu</mat-label>
            <span matPrefix>+48 &nbsp;</span>
            <input matInput autocomplete="off" placeholder="Numer telefonu" name="phoneNumber"
                [(ngModel)]="user.phoneNumber" [readonly]="data.mode=='view'">
        </mat-form-field>



        <p>&nbsp;</p>


    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="dialog.close(false);" *ngIf="data.mode==='view'">Zamknij</button>
        <button mat-button color="warn" (click)="dialog.close(false);" *ngIf="data.mode!='view'">Anuluj</button>
        <button mat-button color="primary" style="float:right;" type="submit" *ngIf="data.mode!='view'">Zapisz</button>



    </div>
</form>