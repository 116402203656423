<mat-card cdk-scrollable>
    <mat-card-header>
        <mat-card-title> Odpady zewidencjonowane </mat-card-title>
        <mat-card-subtitle> Wykaz zewidencjonowanych odpadów </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>

        <app-bdo-filter> </app-bdo-filter>

        <mat-spinner *ngIf="loading" style="margin: 90px auto;"></mat-spinner>

        <ng-container>
            <ng-container *ngFor="let item of filteredResults">
                <mat-accordion>
                    <app-card-block [details]="item" [check]="true"
                        [header]="item.wasteTransferCard.cardNumber + (item.markedForFiling ? ' (Oczekuje na zewidencjonowanie)' : '')"
                        (checked)="item.checked = $event">
                        <div fxLayout="row wrap">
                            <app-card-block-field header="Kod odpadów" fxFlex="50%" fxFlex.gt-lg="50%">
                                {{item.wasteTransferCard.wasteCode}}
                            </app-card-block-field>
                            <app-card-block-field header="Nazwa przekazującego"
                                *ngIf="item.wasteTransferCard.senderName" fxFlex="50%" fxFlex.gt-lg="50%">
                                {{item.wasteTransferCard.senderName}}
                            </app-card-block-field>
                            <app-card-block-field header="Data przekazania" fxFlex="50%" fxFlex.gt-lg="50%">
                                {{item.wasteTransferCardDetails.transportConfirmationTime}}
                            </app-card-block-field>
                            <app-card-block-field header="Nazwa przejmującego"
                                *ngIf="item.wasteTransferCard.receiverName" fxFlex="50%" fxFlex.gt-lg="50%">
                                {{item.wasteTransferCard.receiverName}}
                            </app-card-block-field>
                            <app-card-block-field header="Ostatni błąd"
                                *ngIf="item.errorStatusCode != undefined && item.errorStatusCode != null" fxFlex="50%"
                                fxFlex.gt-lg="50%">
                                {{item.errorStatusCode}}
                            </app-card-block-field>
                            <!--                            <app-card-block-field header="Numer karty" *ngIf="item.wasteTransferCardDetails.cardNumber" fxFlex="50%" fxFlex.gt-lg="50%">-->
                            <!--                                {{item.wasteTransferCard.cardNumber}}-->
                            <!--                            </app-card-block-field>-->
                            <app-card-block-field header="Masa odpadów" *ngIf="item.wasteTransferCard.wasteMass"
                                fxFlex="50%" fxFlex.gt-lg="50%">
                                {{item.wasteTransferCard.wasteMass}}
                            </app-card-block-field>
                        </div>
                        <button mat-button color="primary" [matMenuTriggerFor]="menu"
                            class="actions-menu">Akcje</button>
                        <mat-menu #menu="matMenu">
                            <button (click)="openDetailsDialog(item)" mat-menu-item>Podgląd</button>
                        </mat-menu>
                    </app-card-block>
                </mat-accordion>
            </ng-container>
        </ng-container>
        <mat-paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 30, 50]"></mat-paginator>
    </mat-card-content>

</mat-card>