/**
 * BDOEX API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * Contact: mateusz.surma@mae.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * BDOApiClientModelsCompanyEupDto
 */
export interface BDOApiClientModelsCompanyEupDto { 
    /**
     * Gets or Sets EupId
     */
    eupId?: string;
    /**
     * Gets or Sets CompanyId
     */
    companyId?: string;
    /**
     * Gets or Sets Name
     */
    name?: string;
    /**
     * Gets or Sets IdentificationNumber
     */
    identificationNumber?: string;
    /**
     * Gets or Sets Province
     */
    province?: string;
    /**
     * Gets or Sets District
     */
    district?: string;
    /**
     * Gets or Sets Commune
     */
    commune?: string;
    /**
     * Gets or Sets Locality
     */
    locality?: string;
    /**
     * Gets or Sets Street
     */
    street?: string;
    /**
     * Gets or Sets BuildingNumber
     */
    buildingNumber?: string;
    /**
     * Gets or Sets LocalNumber
     */
    localNumber?: string;
    /**
     * Gets or Sets AddressHtml
     */
    addressHtml?: string;
    /**
     * Gets or Sets IsActive
     */
    isActive?: boolean;
}