import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-value-field',
  templateUrl: './value-field.component.html',
  styleUrls: ['./value-field.component.scss']
})
export class ValueFieldComponent implements OnInit {
  @Input() optional = true;
  @Input() value = undefined;
  @HostBinding('class.hidden') public hidden = false;

  constructor() { }

  ngOnInit(): void {
    this.hidden = (this.value == undefined || this.value == null || this.value == '');
  }

}
