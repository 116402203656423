<div class="container">
  <mat-card class="login-card">
    <mat-card-header>
      <h5 class="mat-h1">Przywracanie hasła</h5>
    </mat-card-header>
    <mat-card-content>
      <form #form="ngForm" (ngSubmit)="form.valid && onSubmit(form)" autocomplete="off">
        <div mat-dialog-content>

          <mat-form-field class="full-width" appearance="fill">
            <mat-label>e-mail</mat-label>
            <input matInput name="username" [(ngModel)]="email" required="true" />
          </mat-form-field>

        </div>
        <div mat-dialog-actions>
          <button mat-flat-button color="primary" type="submit">Wyślij</button>
        </div>
      </form>
      <div class="footer" fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="gappx">
        <a routerLink="/login" routerLinkActive="active">powrót do logowania</a>
      </div>
    </mat-card-content>
  </mat-card>
</div>