import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { element } from "protractor";
import { RoleService } from "../../services/role.service";

@Component({
  selector: "app-role-picker",
  templateUrl: "./role-picker.component.html",
  styleUrls: ["./role-picker.component.scss"],
})
export class RolePickerComponent implements OnInit {
  displayedColumns: string[] = ["name", "radio"];
  dataSource = [
    { name: "przekazujący", value: "SENDER" },
    { name: "transportujący / łączący role", value: "CARRIER" },
  ];
  constructor(private cookieService: CookieService, private roleService: RoleService, @Inject(LOCAL_STORAGE) private storage: WebStorageService, private router: Router) {}

  ngOnInit(): void {}

  select(element) {
    this.roleService.setRole(element.value);
    this.router.navigateByUrl("/");
  }
}
