<ng-container *ngIf="data.mode == 'approve'; else approveAndGenerateView">
  <h2 mat-dialog-title>Zmiana statusu karty planowanej</h2>
  <div mat-dialog-content>Czy na pewno chcesz zatwierdzić wybraną kartę?</div>

  <div mat-dialog-actions>
    <button mat-flat-button (click)="dialogRef.close(false)">Anuluj</button>
    <button mat-flat-button color="primary" (click)="approve()">Zatwierdź</button>
  </div>
</ng-container>

<ng-template #approveAndGenerateView>
  <h2 mat-dialog-title>Zmiana statusu karty planowanej</h2>
  <div mat-dialog-content>
    <mat-form-field appearance="standard">
      <mat-label>Godzina rozpoczęcia transportu</mat-label>
      <input autocomplete="off" [(ngModel)]="generateRequest.realTransportTime" placeholder="00:00" matInput />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Data rozpoczęcia transportu</mat-label>
      <input matInput autocomplete="off" [(ngModel)]="generateRequest.realTransportDate" [matDatepicker]="picker" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <!-- <mat-form-field *ngIf="role == 'CARRIER'; else input">
      <mat-label>Wybierz pojazd</mat-label>
      <mat-select [(value)]="generateRequest.registrationNumber">
        <mat-option *ngFor="let car of cars" [value]="car"> {{ car.name }} [{{ car.registrationNumber }}] </mat-option>
      </mat-select>
    </mat-form-field> -->
    <!-- <ng-template #input> -->
    <mat-form-field>
      <mat-label>Numer rejestracyjny</mat-label>
      <input matInput [(ngModel)]="generateRequest.vehicleRegNumber" />
    </mat-form-field>
    <!-- </ng-template> -->
  </div>
  <div mat-dialog-actions>
    <button mat-flat-button (click)="dialogRef.close(false)">Anuluj</button>
    <button mat-flat-button color="primary" (click)="approveAndGenerateConfirmation()">Zatwierdź</button>
  </div>
</ng-template>
