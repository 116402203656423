import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, ReplaySubject, Subject } from "rxjs";

@Injectable()
export class RoleService {
  public role$: BehaviorSubject<"SENDER" | "CARRIER" | undefined>;
  private currentRole: "SENDER" | "CARRIER" | undefined;

  constructor(private cookieService: CookieService) {
    this.role$ = new BehaviorSubject<"SENDER" | "CARRIER" | undefined>((cookieService.get("role") as "SENDER" | "CARRIER" | undefined) ?? "CARRIER");
    this.currentRole = (cookieService.get("role") as "SENDER" | "CARRIER" | undefined) ?? "CARRIER";
    this.role$.subscribe((r) => (this.currentRole = r));
  }

  public setRole(role: "SENDER" | "CARRIER" | undefined) {
    this.role$.next(role);
    if (role) {
      this.cookieService.set("role", role, 1, "/");
      this.cookieService.set("role", role, { expires: 1, sameSite: "None", secure: true });
    }
  }

  public getRole() {
    return this.currentRole;
  }
}
