import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SubjectAuthenticationService, UserService } from "../../../../../api";
import { AlertService, MessageType } from "../../../services/alert.service";

@Component({
  selector: "app-subject-selection",
  templateUrl: "./subject-selection.component.html",
  styleUrls: ["./subject-selection.component.scss"],
})
export class SubjectSelectionComponent implements OnInit {
  constructor(
    private router: Router,
    private alertService: AlertService,
    private userService: UserService,
    private subjectAuthenticationService: SubjectAuthenticationService
  ) {}

  subjects = [];
  loading = true;

  isAdmin = false;
  role = "Transportujący";
  filterValue = "";

  ngOnInit(): void {
    this.userService.getCurrentUser().subscribe((user) => {
      this.isAdmin = user.group.name === "Administrator";
    });
    this.subjectAuthenticationService.getSubjects().subscribe(
      (result) => {
        this.subjects = result.filter((s) => !s.isUserSubject);
        this.loading = false;
      },
      () => {
        this.alertService.showMessage("Błąd sesji", MessageType.Error);
        this.router.navigateByUrl("/login");
      }
    );
  }

  filter() {
    this.loading = true;
    this.subjectAuthenticationService.getSubjects().subscribe(
      (result) => {
        if (this.role === "Użytkownik") {
          this.subjects = result.filter((s) => s.isUserSubject);
        } else {
          this.subjects = result.filter((s) => !s.isUserSubject);
        }
        this.loading = false;
      },
      () => {
        this.alertService.showMessage("Błąd sesji", MessageType.Error);
        this.router.navigateByUrl("/login");
      }
    );
  }

  selected() {
    this.router.navigateByUrl("/subject-selection");
  }
}
