import { Component, OnInit, Inject } from '@angular/core';
import { CarDto, CarsService } from '../../../../../../api';
import { AlertService, MessageType } from '../../../../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-car-wizard',
  templateUrl: './car-wizard.component.html',
  styleUrls: ['./car-wizard.component.css']
})
export class CarWizardComponent implements OnInit {

  password: string;
  hidePassword = true;
  sendMail = false;

  car: CarDto = {};

  constructor(private carService: CarsService,
    private alertService: AlertService,
    public dialog: MatDialogRef<CarWizardComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {

  }

  ngOnInit() {
    this.car = this.data.car === undefined ? null : this.data.car;
  }

  onSubmit(form): void {
    switch (this.data.mode) {
      case 'create':
        this.carService.add(this.car).subscribe((result: any) => {
          this.alertService.showMessage('Pomyślnie dodano pojazd', MessageType.Info);
          this.dialog.close(true);
        });
        break;
      case 'edit':
        this.carService.update(this.data.car.id, this.car).subscribe((result: any) => {
          this.alertService.showMessage('Pomyślnie zaktualizowano dane o pojeździe', MessageType.Info);
          this.dialog.close(true);
        });
        break;
    }

  }

}
