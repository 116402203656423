import { Component, OnInit, Inject } from "@angular/core";
import { ErrorStateMatcher } from "@angular/material/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl, Validators } from "@angular/forms";
import { GroupDto, UserDto, UsersService, GroupsService, UserService } from "../../../../../api";
import { AlertService, MessageType } from "../../../services/alert.service";
import { UserWizardComponent } from "../../pages/users/user-wizard/user-wizard.component";
import { VirtualTimeScheduler } from "rxjs";
import { Group } from "../../../../../api/model/group";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-user-wizard-dialog",
  templateUrl: "./user-wizard-dialog.component.html",
  styleUrls: ["./user-wizard-dialog.component.scss"],
})
export class UserWizardDialogComponent implements OnInit {
  password: string;
  hidePassword = true;
  sendMail = false;

  userGroups: GroupDto[];
  user: UserDto = { firstName: "", lastName: "", username: "" };

  constructor(private groupService: GroupsService, private userService: UserService, private alertService: AlertService, public dialog: MatDialogRef<UserWizardComponent>, @Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit() {
    this.userService
      .getCurrentUser()
      .pipe(untilDestroyed(this))
      .subscribe((user) => {
        this.user = user;
      });

    this.groupService.getAll().subscribe((value) => {
      this.userGroups = value;
    });
  }

  getUserGroup(): GroupDto {
    return this.userGroups?.find((g) => g.id === this.user.groupId);
  }

  onSubmit(form): void {
    this.userService.passwordChange({ password: this.password }).subscribe(
      (result) => {
        this.alertService.showMessage("Pomyślnie zaktualizowano hasło", MessageType.Info);
        this.dialog.close(true);
      },
      (error) => {
        this.alertService.showMessage("Błąd podczas próby zmiany hasła", MessageType.Error);
      }
    );
  }
}
