/**
 * BDOEX API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * Contact: mateusz.surma@mae.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * WasteRegisterPublicApiApiModelsCollectionsOrder
 */
export interface WasteRegisterPublicApiApiModelsCollectionsOrder { 
    /**
     * Flaga ustawiana na true, jeśli zbiór ma być posortowany rosnąco, false w przeciwnym razie
     */
    isAscending?: boolean;
    /**
     * Identyfikator kolumny, według której ma być zrelizowane sortowanie
     */
    orderColumn?: string;
}