import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DraftCardsService } from "../../../../../../../api";
import {
  AlertService,
  MessageType,
} from "../../../../../services/alert.service";
import { CardService } from "../../../../../services/card.service";

@Component({
  selector: "app-reject-card-dialog",
  templateUrl: "./reject-card-dialog.component.html",
  styleUrls: ["./reject-card-dialog.component.css"],
})
export class RejectCardDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<RejectCardDialogComponent>,
    private cardService: CardService,
    private draftCardsService: DraftCardsService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  rejectionMessage = "";

  reject() {
    const model = {
      ...this.data.model,
      cardStatus: this.cardService.rejectedStatus,
      rejectionCause: this.rejectionMessage,
    };
    this.draftCardsService
      .update(model.id, model, "odrzucenie karty")
      .subscribe(() => {
        this.alertService.showMessage(
          "Pomyślnie odrzucono kartę. Informacja o odmowie przyjęcia karty została przesłana do przekazującego.",
          MessageType.Info
        );

        this.dialogRef.close(true);
      });
  }
}
