import { Component, OnInit, Input, Inject } from "@angular/core";
import {
  SubjectDto,
  UserService,
  SubjectAuthenticationService,
} from "../../../../../../api";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AlertService, MessageType } from "../../../../services/alert.service";
import { AddSubjectDialogComponent } from "../../login/subject-list/add-subject-dialog/add-subject-dialog.component";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-subject-wizard",
  templateUrl: "./subject-wizard.component.html",
  styleUrls: ["./subject-wizard.component.scss"],
})
export class SubjectWizardComponent implements OnInit {
  subjects: SubjectDto[];
  loading = true;
  displayedColumns: string[] = ["name", "radio"];
  dataSource = null;
  hasAdminRights = false;
  

  constructor(
    public matDialog: MatDialog,
    private router: Router,
    private userService: UserService,
    private subjectAuthenticationService: SubjectAuthenticationService,
    private alertService: AlertService,
    public dialog: MatDialogRef<SubjectWizardComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  openDialog(): void {
    const dialogRef = this.matDialog.open(AddSubjectDialogComponent, {
      width: "550px",
      data: { user: this.data.user },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.loading = true;
      this.reload();
    });
  }

  removeSubject(subject: SubjectDto) {
    this.subjectAuthenticationService
      .removeSubject(subject.id)
      .subscribe((result) => {
        this.alertService.showMessage(
          "Pomyślnie usunięto klucz API podmiotu",
          MessageType.Info
        );
        this.reload();
      });
  }

  reload() {
    this.loading = true;
    this.subjectAuthenticationService.getSubjects(this.data.user.id).subscribe(
      (result) => {
        this.subjects = result;
        this.dataSource = this.subjects;
        this.loading = false;
      },
      (error) => {
        this.alertService.showMessage(
          "Błąd podczas ładowania listy podmiotów",
          MessageType.Error
        );
        this.router.navigateByUrl("/login");
      }
    );
  }

  ngOnInit(): void {
    this.subjectAuthenticationService.getSubjects(this.data.user.id).subscribe(
      (subjects) => {
        this.subjects = subjects;
        this.dataSource = this.subjects;
      },
      (error) => this.alertService.showMessage(error.error, MessageType.Error)
    );
  }

  selected() {
    this.router.navigateByUrl("/");
  }
}
