<div class="container">
  <mat-card class="login-card">
    <img mat-card-image src="/assets/logo.png" />
    <!-- <p class="subject">{{ subjectName }}</p> -->
    <mat-card-header>
      <mat-card-title> Wybór roli </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="radio">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" style="text-align: right">
            <button (click)="select(element)" color="primary" mat-button>Wybierz</button>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Nazwa roli</th>
          <td mat-cell *matCellDef="let element">
            <span>
              {{ element.name }}
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </mat-card-content>
  </mat-card>
</div>
