import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { CookieService } from "ngx-cookie-service";
import { switchMap } from "rxjs/operators";
import { BdoServiceModelsEnumerationsCompanyType, ExtendedKpoService } from "../../../../../../api";
import { AlertService, MessageType } from "../../../../services/alert.service";
import { RoleService } from "../../../../services/role.service";
@UntilDestroy()
@Component({
  selector: "app-confirm-recive-dialog",
  templateUrl: "./confirm-recive-dialog.component.html",
  styleUrls: ["./confirm-recive-dialog.component.scss"],
})
export class ConfirmReciveDialogComponent implements OnInit {
  working = false;
  receiver = "";
  wasteMass = 1;
  remarks = "";

  constructor(private extendedKpoService: ExtendedKpoService, public dialogRef: MatDialogRef<ConfirmReciveDialogComponent>, private alertService: AlertService, private cookieService: CookieService, private roleService: RoleService, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.roleService.role$
      .pipe(
        untilDestroyed(this),
        switchMap((role) => this.extendedKpoService.getKpoDetails(this.data.model.kpoId, "CONFIRMATION_GENERATED", role == "CARRIER" ? BdoServiceModelsEnumerationsCompanyType.NUMBER_2 : BdoServiceModelsEnumerationsCompanyType.NUMBER_1))
      )
      .subscribe((res) => (this.wasteMass = res.wasteMass));
  }

  confirm() {
    this.working = true;
    this.extendedKpoService.assignReceiveConfirmation(this.data.model.kpoId, this.receiver, this.wasteMass, this.remarks).subscribe(
      () => {
        this.alertService.showMessage("Pomyślnie przejęto odpad.", MessageType.Info);
        this.dialogRef.close(true);
      },
      (error) => {
        this.alertService.showMessage("Wystąpił błąd podczas przejmowania odpadu", MessageType.Info);
        this.working = false;
      }
    );
  }
}
