import { Directive } from '@angular/core';

@Directive({
  selector: '[appWasteCode]'
})
export class WasteCodeDirective {

  constructor() { }

}
