import { Overlay } from "@angular/cdk/overlay";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { CookieService } from "ngx-cookie-service";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { Subject, Observable } from "rxjs";
import { tap, map, debounceTime, startWith, switchMap, finalize } from "rxjs/operators";
import { ExtendedKpoService, WasteTransferCardService } from "../../../../../api";
import { DraftCard } from "../../../../../api/model/draftCard";
import { AlertService, MessageType } from "../../../services/alert.service";
import { CardService } from "../../../services/card.service";
import { MessagingService } from "../../../services/messaging.service";
import { RoleService } from "../../../services/role.service";
import { BdoFilterComponent, AdvancedFilterQuery } from "../../bdo-filter/bdo-filter.component";
import { KpoDetailsDialogComponent } from "../archive/archive-details-dialog/kpo-details-dialog.component";

@UntilDestroy()
@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.scss"],
})
export class ReportComponent implements OnInit {
  public loading = false;
  public error = false;

  scrollStrategy = this.overlay.scrollStrategies.block();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(BdoFilterComponent, { static: true }) bdoFilter: BdoFilterComponent;
  role: string;
  subjectName: any;
  dateRangeFrom: Date;
  dateRangeTo: Date;
  teryt: string;
  isWorking: boolean = false;
  headquarterAddress: string;
  wasteCode: string;
  additionalInfo: string;

  constructor(private alertService: AlertService, private messagingService: MessagingService, @Inject(LOCAL_STORAGE) private storage: WebStorageService, private extendedKpoService: ExtendedKpoService, public dialog: MatDialog, private overlay: Overlay, private cookieService: CookieService, private roleService: RoleService, private wtcService: WasteTransferCardService) {
    this.roleService.role$.pipe(untilDestroyed(this)).subscribe((r) => (this.role = r));
  }

  senderCompanyControl = new FormControl("");
  options: string[] = ["One", "Two", "Three"];
  filteredOptions: Observable<{ label: string; value: string }[]>;

  ngOnInit() {
    this.filteredOptions = this.senderCompanyControl.valueChanges.pipe(
      debounceTime(500),
      switchMap((query) => this.extendedKpoService.searchCompany(query)),
      tap(console.log),
      map((result: any) =>
        result.map((item) => {
          return { label: item.name, value: item.companyId };
        })
      )
    );
    this.subjectName = this.storage.get("SubjectName");
  }

  generateReport() {
    this.isWorking = true;
    this.wtcService
      .getFilteredWasteTransferCardsReport(this.senderCompanyControl.value, this.dateRangeFrom, this.dateRangeTo, this.headquarterAddress, this.teryt, this.wasteCode)
      .pipe(finalize(() => (this.isWorking = false)))
      .subscribe(
        (file) => this.downloadFile(file),
        (err) => console.log(err)
      );
  }

  downloadFile(data: Blob) {
    console.log(data);
    const url = window.URL.createObjectURL(data);
    window.open(url);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) => option.toLowerCase().includes(filterValue));
  }
}
