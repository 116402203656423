<mat-card>
    <mat-card-header>
        <mat-card-title>Wykaz pojazdów</mat-card-title>
        <mat-card-subtitle>Zarządzanie pojazdami firmy</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <mat-spinner *ngIf="isLoading; else results" style="margin: 90px auto;"></mat-spinner>

        <ng-template #results>
            <ng-container *ngFor="let item of filteredResults$ | async">
                <mat-accordion>
                    <app-card-block header="Nazwa" [text]="item.name" [value]="item">
                        <div fxLayout="row wrap">
                            <app-card-block-field header="Rejestracja" *ngIf="item.registrationNumber" fxFlex="100%"
                                fxFlex.gt-lg="50%">
                                {{item.registrationNumber}}
                            </app-card-block-field>
                        </div>
                        <button mat-button color="primary" (click)="openDialog('edit', item)"
                            class="actions-menu">Edycja</button>
                    </app-card-block>
                </mat-accordion>
            </ng-container>


        </ng-template>
        <mat-paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 30, 50]"></mat-paginator>
        <div style="text-align: right; width: 100%; margin-top: 16px;">
            <button mat-flat-button color="primary" (click)="openDialog('create')" class="actions-menu">Dodaj
                pojazd</button>
        </div>
    </mat-card-content>
</mat-card>