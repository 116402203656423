/**
 * BDOEX API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * Contact: mateusz.surma@mae.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SearchResultItemDto { 
    /**
     * Planowana data i godzina transportu
     */
    plannedTransportTime?: Date;
    /**
     * Rzeczywista data i godzina transportu
     */
    realTransportTime?: Date;
    /**
     * Kod odpadu
     */
    wasteCode?: string;
    /**
     * Opis kodu odpadu
     */
    wasteCodeDescription?: string;
    /**
     * Numer rejestracyjny środka transportu
     */
    vehicleRegNumber?: string;
    /**
     * Status Karty Przekazania Odpadów
     */
    cardStatus?: string;
    /**
     * Numer Karty Przekazania Odpadów
     */
    cardNumber?: string;
    /**
     * Kod statusu karty
     */
    cardStatusCodeName?: string;
    /**
     * Nazwa przekazującego
     */
    senderName?: string;
    /**
     * Nazwa przejmującego
     */
    receiverName?: string;
    /**
     * Id karty przekazania odpadów
     */
    kpoId?: string;
    /**
     * Gets or Sets KpoLastModifiedAt
     */
    kpoLastModifiedAt?: Date;
    /**
     * Imię i Nazwisko przekazującego
     */
    senderFirstNameAndLastName?: string;
    /**
     * Imię i Nazwisko przejmującego
     */
    receiverFirstAndLastName?: string;
    /**
     * Numer korekty
     */
    revisionNumber?: number;
    /**
     * Data odrzucenia
     */
    cardRejectionTime?: Date;
    /**
     * Kod ex
     */
    wasteCodeExtended?: boolean;
    /**
     * Zmiana statusu odpadów niebezpiecznych na odpady inne niż niebezpieczne
     */
    hazardousWasteReclassification?: boolean;
    /**
     * Rodzaj odpadu ex
     */
    wasteCodeExtendedDescription?: string;
    /**
     * Rodzaj odpadu
     */
    hazardousWasteReclassificationDescription?: string;
}