import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-data-dialog',
  templateUrl: './edit-data-dialog.component.html',
  styleUrls: ['./edit-data-dialog.component.css']
})
export class EditDataDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
