import { Component, ViewChild, OnDestroy, HostListener, Inject, OnInit, AfterViewInit } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { catchError, startWith, switchMap, take } from "rxjs/operators";
import { ExtendedKpoService, DraftCardDto, WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsCarrierSearchResultDto, BdoServiceModelsEnumerationsCompanyType } from "../../../../../api";
import { MatDialog } from "@angular/material/dialog";
import { ViewDraftCardDialogComponent } from "../home/kpo/view-draft-card-dialog/view-draft-card-dialog.component";
import { AlertService, MessageType } from "../../../services/alert.service";
import { SelectDriverDialogComponent } from "./select-driver-dialog/select-driver-dialog.component";
import { GenerateConfirmationDialogComponent } from "./generate-confirmation-dialog/generate-confirmation-dialog.component";
import { ConfirmationDownloaderComponent } from "./confirmation-downloader/confirmation-downloader.component";
import { KpoDetailsDialogComponent } from "../archive/archive-details-dialog/kpo-details-dialog.component";
import { ConfirmReciveDialogComponent } from "./confirm-recive-dialog/confirm-recive-dialog.component";
import { RejectReceiveDialogComponent } from "./reject-receive-dialog/reject-receive-dialog.component";
import { combineLatest, Observable, of, Subject, Subscription } from "rxjs";
import { tap, map, debounceTime } from "rxjs/operators";
import { DraftCard } from "../../../../../api/model/draftCard";
import { CardService } from "../../../services/card.service";
import { MessagingService } from "../../../services/messaging.service";
import { AdvancedFilterQuery, BdoFilterComponent } from "../../bdo-filter/bdo-filter.component";
import { NewKpoDialogComponent } from "../home/kpo/new-kpo-dialog/new-kpo-dialog.component";
import { SelectReceiverDialogComponent } from "../home/kpo/select-receiver-dialog/select-receiver-dialog.component";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { Overlay } from "@angular/cdk/overlay";
import { CookieService } from "ngx-cookie-service";
import { ConfirmWithdrawalDialogComponent } from "./confirm-withdrawal-dialog/confirm-withdrawal-dialog.component";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { RoleService } from "../../../services/role.service";
import { ReviseCardDialogComponent } from "./revise-card-dialog/revise-card-dialog.component";
import { ActivatedRoute } from "@angular/router";

type KpoCard = WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsCarrierSearchResultDto;

@UntilDestroy()
@Component({
  selector: "app-realising-now",
  templateUrl: "./realising-now.component.html",
  styleUrls: ["./realising-now.component.scss"],
})
export class RealisingNowComponent implements OnInit, AfterViewInit {
  public loading = false;
  public error = false;
  carrierName: string;
  role: string;
  scrollStrategy = this.overlay.scrollStrategies.block();

  filterCardStatuses: { label: string; value: string }[];

  reloadRequest$: Subject<void> = new Subject();

  filteredResult$: Observable<(DraftCard | KpoCard)[]>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(BdoFilterComponent, { static: true })
  bdoFilter: BdoFilterComponent;
  initialStatus: string;
  constructor(private activatedRoute: ActivatedRoute, public cardService: CardService, private alertService: AlertService, private messagingService: MessagingService, @Inject(LOCAL_STORAGE) private storage: WebStorageService, private extendedKpoService: ExtendedKpoService, public dialog: MatDialog, private overlay: Overlay, private cookieService: CookieService, private roleService: RoleService) {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      if (params.status !== undefined) this.initialStatus = params.status;
    });

    this.roleService.role$.pipe(untilDestroyed(this)).subscribe((r) => {
      this.role = r;
      if (this.initialStatus != "REJECTED") this.initialStatus = r == "CARRIER" ? "APPROVED" : "CONFIRMATION_GENERATED";
      this.filterCardStatuses =
        r == "CARRIER"
          ? [
              { label: "Przydzielone", value: "APPROVED" },
              { label: "Potwierdzenie wygenerowane", value: "CONFIRMATION_GENERATED" },
              { label: "Zrealizowane przejęcie", value: "RECEIVE_CONFIRMATION" },
              { label: "Odrzucone", value: "REJECTED" },
            ]
          : [
              { label: "Potwierdzenie wygenerowane", value: "CONFIRMATION_GENERATED" },
              { label: "Zrealizowane przejęcie", value: "RECEIVE_CONFIRMATION" },
              { label: "Odrzucone", value: "REJECTED" },
            ];
    });
  }

  ngOnInit(): void {
    this.carrierName = this.storage.get("SubjectName");
  }

  ngAfterViewInit(): void {
    this.filteredResult$ = combineLatest([this.reloadRequest$.pipe(startWith(0)), this.paginator.page.pipe(startWith(0)), this.bdoFilter.advancedQueryTextChanged.pipe(startWith(0), debounceTime(800)), this.bdoFilter.advancedQueryChanged.pipe(startWith(0)), this.roleService.role$.pipe(startWith(0)), this.messagingService.messageReceived$.pipe(startWith(0))]).pipe(
      debounceTime(500),
      untilDestroyed(this),
      tap(() => {
        this.error = false;
        this.loading = true;
      }),
      switchMap((s) => {
        return this.cardService.getRealisingNowCards(this.bdoFilter.advancedQuery, this.paginator.pageIndex, this.paginator.pageSize).pipe(
          tap((cards) => {
            this.paginator.length = cards.totalItemsCount;
          }),
          catchError((error) => {
            this.alertService.showMessage("Wystąpił błąd podczas ładowania listy", MessageType.Error);
            console.error(error);
            return of(error);
          }),
          map((cards) =>
            cards.items.map((m) => {
              const kpoCard = m as KpoCard;
              return {
                ...m,
                user: kpoCard ? cards.userAttributions[kpoCard.kpoId] : undefined,
              };
            })
          ),
          tap(() => {
            this.loading = false;
            this.error = false;
          })
        );
      })
    );
    this.handleRefresh();
  }

  handleRefresh() {
    this.messagingService.messageReceived$.pipe(untilDestroyed(this)).subscribe((payload) => {
      this.alertService.showMessage(payload.data.body.replace("\r\n", " | "), MessageType.Notification);
    });
  }

  // @HostListener("window:focus", ["$event"])
  // onFocus(event: FocusEvent): void {
  //   this.reloadRequest.next();
  // }

  getLastModificationDate(kpo: any) {
    if (kpo.creationDateTime === undefined) {
      kpo.creationDateTime = kpo.kpoLastModifiedAt;
    }
    return kpo.creationDateTime === undefined ? kpo.kpoLastModifiedAt : kpo.creationDateTime;
  }

  openCardCreationDialog() {
    const dialogRef = this.dialog.open(NewKpoDialogComponent, {
      width: "1100px",
      data: {},
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.bdoFilter.advancedQuery.cardStatusCodeName == result;
      this.reloadRequest$.next();
    });
  }

  openDetailsDialog(card): void {
    this.dialog.open(KpoDetailsDialogComponent, {
      width: "950px",
      data: { model: card },
      panelClass: "dialog",
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });
  }

  openDriverSelectionDialog(card): void {
    const model = { ...card };
    const dialogRef = this.dialog.open(SelectDriverDialogComponent, {
      width: "550px",
      data: { model: { ...model } },
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });
    dialogRef.afterClosed().subscribe((result) => (result ? this.reloadRequest$.next() : null));
  }

  openReceiverSelectionDialog(card: DraftCardDto): void {
    const dialogRef = this.dialog.open(SelectReceiverDialogComponent, {
      width: "950px",
      data: { model: { ...card } },
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result) => (result ? this.reloadRequest$.next() : null));
  }

  openCardConfirmationDialog(model): void {
    const dialogRef = this.dialog.open(ConfirmReciveDialogComponent, {
      width: "950px",
      data: { model: { ...model } },
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result) => (result ? this.reloadRequest$.next() : null));
  }

  openCardRejectionDialog(model): void {
    const dialogRef = this.dialog.open(RejectReceiveDialogComponent, {
      width: "950px",
      data: { model: { ...model } },
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result) => (result ? this.reloadRequest$.next() : null));
  }

  confirmTransport(element) {
    this.extendedKpoService.confirmTransport(element.kpoId).subscribe(() => {
      this.alertService.showMessage("Pomyślnie potwierdzono transport. Obieg karty został zakończony. Karta trafiła do archiwum KPO.", MessageType.Info);
      this.reloadRequest$.next();
    });
  }

  rejectTransport(element) {
    this.extendedKpoService.rejectTransport(element.kpoId).subscribe(() => {
      this.alertService.showMessage("Pomyślnie odrzucono transport.", MessageType.Info);
      this.reloadRequest$.next();
    });
  }

  downloadConfirmation(element) {
    this.dialog.open(ConfirmationDownloaderComponent, {
      width: "550px",
      data: { model: element },
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });
  }

  openConfirmationGenerationDialog(element): void {
    const dialogRef = this.dialog.open(GenerateConfirmationDialogComponent, {
      width: "550px",
      data: { model: { ...element } },
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result) => (result ? this.reloadRequest$.next() : null));
  }

  openCardWithdrawalDialog(element): void {
    const dialogRef = this.dialog.open(ConfirmWithdrawalDialogComponent, {
      width: "550px",
      data: { model: { ...element } },
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result) => (result ? this.reloadRequest$.next() : null));
  }

  openCardRevisionDialog(element): void {
    const dialogRef = this.dialog.open(ReviseCardDialogComponent, {
      width: "850px",
      data: { model: { ...element } },
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result) => (result ? this.reloadRequest$.next() : null));
  }

  confirmAllTransports() {
    this.extendedKpoService.confirmTransports().subscribe(
      () => {
        this.alertService.showMessage("Pomyślnie potwierdzono wszystkie transporty z ostatniego tygodnia", MessageType.Info);
        this.reloadRequest$.next();
      },
      () => this.alertService.showMessage("Błąd podczas potwierdzania transportów", MessageType.Error)
    );
  }

  loadEupInfo(item: KpoCard) {
    this.extendedKpoService
      .getKpoDetails(item.kpoId, item.cardStatusCodeName, this.role == "CARRIER" ? BdoServiceModelsEnumerationsCompanyType.NUMBER_2 : BdoServiceModelsEnumerationsCompanyType.NUMBER_1)
      .pipe(switchMap((details) => this.extendedKpoService.getEupDetails(details.senderEupId)))
      .subscribe((r) => (item.eup = r.registrationNumber + " " + r.name));
  }
}
