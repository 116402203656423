<mat-card>
  <mat-card-header>
    <mat-card-title>Aktualnie realizowane zlecenia</mat-card-title>
    <mat-card-subtitle>Zarządzanie zadaniami w trakcie realizacji</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <app-bdo-filter [allowedCardStatuses]="filterCardStatuses" [initialStatus]="initialStatus" #bdoFilter></app-bdo-filter>

    <button mat-raised-button style="margin: 90px auto" (click)="ngOnInit()" *ngIf="error && !loading">Ponów próbę</button>
    <div *ngIf="filteredResult$ | async as filteredResult">
      <mat-spinner *ngIf="loading; else view" style="margin: 90px auto"></mat-spinner>
      <ng-template #view>
        <ng-container *ngFor="let item of filteredResult">
          <mat-accordion>
            <app-card-block [details]="item">
              <div fxLayout="row wrap">
                <app-card-block-field header="Numer karty" *ngIf="item.cardNumber" fxFlex="100%" fxFlex.gt-lg="50%">
                  {{ item.cardNumber }}
                </app-card-block-field>
                <app-card-block-field header="Kod odpadów" fxFlex="100%" fxFlex.gt-lg="50%">
                  {{ item.wasteCode }}
                </app-card-block-field>
                <app-card-block-field header="Przypisany kierowca" *ngIf="item.user" fxFlex="100%" fxFlex.gt-lg="50%"> {{ item.user.firstName }} {{ item.user.lastName }} </app-card-block-field>
                <app-card-block-field header="Numer rejestracyjny" fxFlex="100%" fxFlex.gt-lg="50%" *ngIf="item.vehicleRegNumber != undefined && item.vehicleRegNumber != ''">
                  {{ item.vehicleRegNumber }}
                </app-card-block-field>
                <!-- <app-card-block-field header="Data utworzenia/modyfikacji">
              {{getLastModificationDate(item) | date: 'dd.MM.yyyy HH:mm'}}
          </app-card-block-field> -->
                <app-card-block-field header="Status karty" fxFlex="100%" fxFlex.gt-lg="50%">
                  {{ item.cardStatus }}
                </app-card-block-field>
                <app-card-block-field header="Planowany transport" fxFlex="100%" fxFlex.gt-lg="50%">
                  {{ item.plannedTransportTime | date : "dd.MM.yyyy HH:mm" }}
                </app-card-block-field>
                <app-card-block-field header="MPD przekazującego" fxFlex="100%" fxFlex.gt-lg="50%">
                  <button mat-button color="primary" *ngIf="item.eup == undefined; else eup" (click)="loadEupInfo(item)">Załaduj</button>
                  <ng-template #eup> {{ item.eup }}</ng-template>
                </app-card-block-field>
              </div>
              <button mat-button color="primary" [matMenuTriggerFor]="menu" class="actions-menu">Akcje</button>
              <mat-menu #menu="matMenu">
                <button (click)="openDetailsDialog(item)" mat-menu-item>Podgląd</button>
                <button (click)="openDriverSelectionDialog(item)" mat-menu-item *ngIf="role == 'CARRIER'">Zmień kierowcę</button>
                <button (click)="openConfirmationGenerationDialog(item)" *ngIf="item.cardStatusCodeName === 'APPROVED' && role == 'CARRIER'" mat-menu-item>Wygeneruj potwierdzenie</button>
                <button (click)="downloadConfirmation(item)" *ngIf="item.cardStatusCodeName === 'CONFIRMATION_GENERATED'" mat-menu-item>Pobierz potwierdzenie</button>
                <button (click)="openCardConfirmationDialog(item)" *ngIf="item.cardStatusCodeName === 'CONFIRMATION_GENERATED' && item.receiverName === carrierName && role == 'CARRIER'" mat-menu-item>Potwierdź przejęcie</button>
                <button (click)="openCardRejectionDialog(item)" *ngIf="item.cardStatusCodeName === 'CONFIRMATION_GENERATED' && item.receiverName === carrierName && role == 'CARRIER'" mat-menu-item>Odrzuć</button>
                <button (click)="confirmTransport(item)" *ngIf="item.cardStatusCodeName === 'RECEIVE_CONFIRMATION' && role == 'CARRIER'" mat-menu-item>Potwierdź transport</button>
                <button (click)="openCardWithdrawalDialog(item)" *ngIf="(item.cardStatusCodeName === 'APPROVED' || item.cardStatusCodeName === 'CONFIRMATION_GENERATED') && role == 'SENDER'" mat-menu-item>Wycofaj</button>

                <button (click)="openCardRevisionDialog(item)" *ngIf="item.cardStatusCodeName === 'REJECTED' && role == 'SENDER'" mat-menu-item>Skoryguj</button>
              </mat-menu>
            </app-card-block>
          </mat-accordion>
        </ng-container>
      </ng-template>
    </div>
    <mat-paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 30, 50]"></mat-paginator>
  </mat-card-content>
  <mat-card-actions><button mat-button (click)="confirmAllTransports()" *ngIf="role == 'CARRIER' && bdoFilter?.advancedQuery?.cardStatusCodeName === 'RECEIVE_CONFIRMATION'">Potwierdź wszystkie transporty z ostatniego tygodnia</button></mat-card-actions>
</mat-card>
