import { LoginComponent } from "./components/pages/login/login.component";
import { AuthGuard, CanDeactivateGuard } from "./guards/auth.guard";
import { UsersComponent } from "./components/pages/users/users.component";
import { GroupsComponent } from "./components/pages/groups/groups.component";
import { Route } from "@angular/router";
import { RegistrationComponent } from "./components/pages/registration/registration.component";
import { CarsComponent } from "./components/pages/cars/cars.component";
import { DriversComponent } from "./components/pages/drivers/drivers.component";
import { RealisingNowComponent } from "./components/pages/realising-now/realising-now.component";
import { SubjectSelectionComponent } from "./components/pages/subject-selection/subject-selection.component";
import { HeadquarterSelectionComponent } from "./components/pages/headquarter-selection/headquarter-selection.component";
import { CompanyDetailsComponent } from "./components/pages/company-details/company-details.component";
import { ArchiveComponent } from "./components/pages/archive/archive.component";
import { StatsComponent } from "./components/pages/stats/stats.component";
import { PasswordRecoveryComponent } from "./components/pages/password-recovery/password-recovery.component";
import { WhitelistComponent } from "./components/pages/whitelist/whitelist.component";
import { UserActionsLogComponent } from "./components/pages/user-actions-log/user-actions-log.component";
import { DashboardComponent } from "./components/pages/dashboard/dashboard.component";
import { FilingComponent } from "./components/pages/filing/filing.component";
import { FiledCardsComponent } from "./components/pages/filed-cards/filed-cards.component";
import { WasteRecordCardsComponent } from "./components/pages/waste-record-cards/waste-record-cards.component";
import { RolePickerComponent } from "./components/role-picker/role-picker.component";
import { LandingPageComponent } from "./components/pages/landing-page/landing-page.component";
import { ReportComponent } from "./components/pages/report/report.component";
import { WasteTransferCardsComponent } from "./components/pages/waste-transfer-cards/waste-transfer-cards.component";

export const routing: Route[] = [
  {
    path: "",
    redirectTo: "/dashboard/carrier-kpo",
    pathMatch: "full",
  },
  {
    path: "dashboard",
    data: {
      title: "Moje BDO",
    },
    children: [
      {
        path: "carrier-kpo",
        component: DashboardComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          title: "Tablica zadań",
          icon: "/assets/icons/BDO-web_ikonki_tablica-zadan.png",
          activeIcon: "/assets/icons/BDO-web_ikonki_tablica-zadan_aktywny.png",
          showInRoles: ["CARRIER", "SENDER"],
        },
      },
      {
        path: "realising-now",
        component: RealisingNowComponent,
        data: {
          title: "Aktualnie realizowane zlecenia",
          icon: "/assets/icons/BDO-web_ikonki_aktuane-zlecenia.png",
          activeIcon: "/assets/icons/BDO-web_ikonki_aktuane-zlecenia_aktywny.png",
          showInRoles: ["CARRIER", "SENDER"],
        },
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: "archive",
        component: ArchiveComponent,
        data: {
          title: "Archiwum KPO",
          icon: "/assets/icons/BDO-web_ikonki_archiwum-kpo.png",
          activeIcon: "/assets/icons/BDO-web_ikonki_archiwum-kpo_aktywny.png",
          showInRoles: ["CARRIER", "SENDER"],
        },
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
      },
      // {
      //   path: "filed-cards",
      //   component: FiledCardsComponent,
      //   pathMatch: "full",
      //   canActivate: [AuthGuard],
      //   data: {
      //     title: "Odpady zewidencjonowane",
      //     icon: "/assets/icons/BDO-web_ikonki_tablica-zadan.png",
      //     activeIcon:
      //       "/assets/icons/BDO-web_ikonki_tablica-zadan_aktywny.png",
      //   },
      // },
    ],
  },
  {
    path: "dashboard",
    data: {
      title: "Moja ewidencja",
    },
    children: [
      {
        path: "filed-cards",
        component: WasteRecordCardsComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
        data: {
          title: "Ewidencja odpadów",
          icon: "/assets/icons/BDO-web_ikonki_tablica-zadan.png",
          activeIcon: "/assets/icons/BDO-web_ikonki_tablica-zadan_aktywny.png",
          showInRoles: ["CARRIER", "SENDER"],
        },
      },
      {
        path: "filing",
        component: FilingComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
        data: {
          title: "Dodaj odpady do ewidencji",
          icon: "/assets/icons/BDO-web_ikonki_tablica-zadan.png",
          activeIcon: "/assets/icons/BDO-web_ikonki_tablica-zadan_aktywny.png",
          showInRoles: ["CARRIER", "SENDER"],
        },
      },
    ],
  },
  {
    path: "download",
    data: {
      title: "Pobierz",
    },
    children: [
      {
        path: "report",
        component: ReportComponent,
        data: {
          title: "Dane z kpo (.xls)",
          icon: "/assets/icons/BDO-web_ikonki_archiwum-kpo.png",
          activeIcon: "/assets/icons/BDO-web_ikonki_archiwum-kpo_aktywny.png",
          showInRoles: ["CARRIER", "SENDER"],
        },
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: "waste-transfer-cards",
        component: WasteTransferCardsComponent,
        data: {
          title: "Wydruk kpo (.pdf)",
          icon: "/assets/icons/BDO-web_ikonki_archiwum-kpo.png",
          activeIcon: "/assets/icons/BDO-web_ikonki_archiwum-kpo_aktywny.png",
          showInRoles: ["CARRIER", "SENDER"],
        },
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
      },
    ],
  },
  { path: "login", component: LoginComponent },
  { path: "landing-page", component: LandingPageComponent },
  { path: "subject-selection", component: SubjectSelectionComponent },
  { path: "headquarter-selection", component: HeadquarterSelectionComponent },
  { path: "role-selection", component: RolePickerComponent },
  { path: "register", component: RegistrationComponent },
  { path: "password-recovery", component: PasswordRecoveryComponent },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
  },

  {
    path: "my-company",
    data: {
      title: "Moja firma",
    },
    children: [
      {
        path: "details",
        component: CompanyDetailsComponent,
        data: {
          title: "Dane firmy",
          icon: "/assets/icons/BDO-web_ikonki_dane-firmy.png",
          activeIcon: "/assets/icons/BDO-web_ikonki_dane-firmy_aktywny.png",
          showInRoles: ["CARRIER", "SENDER"],
        },
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: "drivers",
        component: DriversComponent,
        data: {
          title: "Wykaz użytkowników",
          icon: "/assets/icons/BDO-web_ikonki_wykaz-kierowcow.png",
          activeIcon: "/assets/icons/BDO-web_ikonki_wykaz-kierowcow_aktywny.png",
          showInRoles: ["CARRIER", "SENDER"],
        },
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: "cars",
        component: CarsComponent,
        data: {
          title: "Wykaz pojazdów",
          icon: "/assets/icons/BDO-web_ikonki_wykaz-pojazdow.png",
          activeIcon: "/assets/icons/BDO-web_ikonki_wykaz-pojazdow_aktywny.png",
          showInRoles: ["CARRIER"],
        },
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: "stats",
        component: StatsComponent,
        data: {
          title: "Statystyka",
          icon: "/assets/icons/BDO-web_ikonki_dane-firmy.png",
          activeIcon: "/assets/icons/BDO-web_ikonki_dane-firmy_aktywny.png",
          showInRoles: ["CARRIER"],
        },
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
      },
    ],
  },
  {
    path: "admin",
    data: {
      title: "Administracja",
      adminOnly: true,
    },
    children: [
      {
        path: "groups",
        component: GroupsComponent,
        data: {
          adminOnly: true,
          title: "Grupy",
          icon: "/assets/icons/BDO-web_ikonki_dane-firmy.png",
          activeIcon: "/assets/icons/BDO-web_ikonki_dane-firmy_aktywny.png",
        },
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: "users",
        component: UsersComponent,
        data: {
          adminOnly: true,
          title: "Zarządzanie użytkownikami",
          icon: "/assets/icons/BDO-web_ikonki_wykaz-kierowcow.png",
          activeIcon: "/assets/icons/BDO-web_ikonki_wykaz-kierowcow_aktywny.png",
        },
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: "whitelist",
        component: WhitelistComponent,
        data: {
          adminOnly: true,
          title: "Lista powiązanych podmiotów",
          icon: "/assets/icons/BDO-web_ikonki_wykaz-kierowcow.png",
          activeIcon: "/assets/icons/BDO-web_ikonki_wykaz-kierowcow_aktywny.png",
        },
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: "registry",
        component: UserActionsLogComponent,
        data: {
          adminOnly: true,
          title: "Rejestr akcji",
          icon: "/assets/icons/BDO-web_ikonki_wykaz-kierowcow.png",
          activeIcon: "/assets/icons/BDO-web_ikonki_wykaz-kierowcow_aktywny.png",
        },
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
      },
    ],
  },
];
