import { DatePipe } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { CookieService } from "ngx-cookie-service";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { fromEvent, Observable, Subscription } from "rxjs";
import { switchMap, tap, debounceTime, distinctUntilChanged, finalize, startWith } from "rxjs/operators";
import {
  ExtendedKpoService,
  UsersService,
  GroupsService,
  DraftCardDto,
  WasteRegisterPublicApiApiModelsElasticsearchCompanyEs,
  WasteRegisterPublicApiApiModelsElasticsearchEupEs,
  WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto,
  WasteRegisterPublicApiApiModelsElasticsearchTerytEs,
  BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto,
  UserDto,
} from "../../../../../api";
import { CanDeactivateGuard } from "../../../guards/auth.guard";
import { AlertService, MessageType } from "../../../services/alert.service";
import { RoleService } from "../../../services/role.service";

@UntilDestroy()
@Component({
  selector: "app-planned-card-edit-dialog",
  templateUrl: "./planned-card-edit-dialog.component.html",
  styleUrls: ["./planned-card-edit-dialog.component.scss"],
})
export class PlannedCardEditDialogComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<PlannedCardEditDialogComponent>,
    private extendedKpoService: ExtendedKpoService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private alertService: AlertService,
    public datepipe: DatePipe,
    private driversService: UsersService,
    private groupsService: GroupsService,
    private roleService: RoleService,
    private router: Router,
    private guard: CanDeactivateGuard,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.roleService.role$.pipe(untilDestroyed(this)).subscribe((r) => (this.role = r));

    this.extendedKpoService.getKpoDetails(data.model.kpoId, "PLANNED").subscribe((details) => {
      this.details = details;
      this.wasteMassValue = this.details.wasteMass.toFixed(4);
      this.plannedTime = new Date(this.details.plannedTransportTime);
      this.plannedDate = new Date(this.details.plannedTransportTime);

      this.wasteGeneratedTerytPk.setValue(this.details.wasteGeneratedTeryt.replace(/(<([^>]+)>)/gi, ""));

      this.extendedKpoService.getWasteCodes(this.details.wasteCode).subscribe((w) => {
        this.copyOfWasteCodes = w;

        this.wasteCodeModel = this.copyOfWasteCodes.find((x) => x.wasteCodeId === this.details.wasteCodeId);
        this.wasteCode.setValue(this.wasteCodeModel.code);
      });
      if (this.details.wasteProcessId) {
        this.extendedKpoService.getWasteProcessById(this.details.wasteProcessId).subscribe((wasteProcess) => {
          this.wasteProcess.setValue(wasteProcess.codeName);
          this.wasteProcessDescription = wasteProcess.name;

          this.wasteProcessModel = wasteProcess;
          this.wasteProcessDescription = this.wasteProcessModel?.name;
        });
      }
      this.extendedKpoService.getCompany(this.details.senderCompanyId).subscribe((c1) => {
        this.senderCompany = c1;
      });

      this.extendedKpoService.getCompany(this.details.carrierCompanyId).subscribe((c1) => {
        this.carrierCompany = c1;
      });

      this.extendedKpoService.getCompany(this.details.receiverCompanyId).subscribe((c1) => {
        this.receiverCompany = c1;
      });

      this.extendedKpoService.getCompanyEup(this.details.receiverEupId).subscribe((result) => {
        this.receiverCompanyEup = result;
      });
    });
  }

  role: string;

  details: DraftCardDto;

  senderCompany: WasteRegisterPublicApiApiModelsElasticsearchCompanyEs = undefined;
  senderCompanyEup: WasteRegisterPublicApiApiModelsElasticsearchEupEs = undefined;
  carrierCompany: WasteRegisterPublicApiApiModelsElasticsearchCompanyEs = undefined;
  carrierAdditionalCompanies: WasteRegisterPublicApiApiModelsElasticsearchCompanyEs[] = [];
  preselectedCarrierCompanyEx: WasteRegisterPublicApiApiModelsElasticsearchCompanyEs = undefined;

  carrierCompanyEup: WasteRegisterPublicApiApiModelsElasticsearchEupEs = undefined;
  receiverCompany: WasteRegisterPublicApiApiModelsElasticsearchCompanyEs;
  receiverCompanyEup: WasteRegisterPublicApiApiModelsElasticsearchEupEs;
  preselectedReceiverCompany: WasteRegisterPublicApiApiModelsElasticsearchCompanyEs;
  preselectedReceiverCompanyEup: WasteRegisterPublicApiApiModelsElasticsearchEupEs;
  wasteCodeDescription = "";
  wasteProcessDescription = "";
  wasteCodeModel: WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto;
  wasteGeneratedTerytPkModel: WasteRegisterPublicApiApiModelsElasticsearchTerytEs;
  wasteProcessModel: BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto;
  query = "";
  kpoQueryText = "";
  regNumber = "";
  loading = false;
  addingCard = false;
  plannedTime;
  wasteMassValue = "";
  plannedDate;
  subscription = new Subscription();
  drivers: UserDto[];
  driver: UserDto;
  wasteCodeExtendedDescriptions: { [key: string]: string };

  wasteGeneratedTerytPk = new FormControl();
  copyOfWasteGeneratedTerytPks: WasteRegisterPublicApiApiModelsElasticsearchTerytEs[];
  filteredWasteGeneratedTerytPks: Observable<WasteRegisterPublicApiApiModelsElasticsearchTerytEs[]>;

  wasteCode = new FormControl();
  copyOfWasteCodes: WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto[] = [];
  filteredWasteCodes: Observable<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto[]>;

  wasteProcess = new FormControl();
  copyOfWasteProcesses: BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto[] = [];
  filteredWasteProcesses: Observable<BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto[]>;

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  carrierCompanyChanged() {
    this.carrierCompany = this.carrierCompany;
    console.log("changing");
  }

  ngOnInit() {
    this.extendedKpoService.getWasteCodeExtendedDescriptions().subscribe((result) => {
      this.wasteCodeExtendedDescriptions = result;
    });

    this.groupsService.getAll().subscribe((result) => {
      const groupId = result.find((g) => g.name === "Kierowca").id;

      this.driversService.getAllAssigned(groupId).subscribe((drivers) => (this.drivers = drivers));
    });

    this.extendedKpoService.getWasteProcess(this.data.model.wasteProcess).subscribe((w) => {
      this.copyOfWasteProcesses = w;
    });

    this.filteredWasteGeneratedTerytPks = this.wasteGeneratedTerytPk.valueChanges.pipe(
      debounceTime(400),
      startWith(""),
      distinctUntilChanged(),
      switchMap((value) => this.extendedKpoService.getTeryts(value)),
      tap((w) => console.log((this.copyOfWasteGeneratedTerytPks = w)))
    );

    this.filteredWasteCodes = this.wasteCode.valueChanges.pipe(
      debounceTime(400),
      startWith(this.data.model.wasteCode),
      distinctUntilChanged(),
      switchMap((value) => this.extendedKpoService.getWasteCodes(value)),
      tap((w) => (this.copyOfWasteCodes = w))
    );

    this.filteredWasteProcesses = this.wasteProcess.valueChanges.pipe(
      debounceTime(400),
      startWith(""),
      distinctUntilChanged(),
      switchMap((value) => this.extendedKpoService.getWasteProcess(value)),
      tap((w) => (this.copyOfWasteProcesses = w))
    );

    this.extendedKpoService.getSelfCompany().subscribe(
      (result) => {
        this.senderCompany = result;
      },
      (error) => this.alertService.showMessage("Błąd podczas pobierania danych podmiotu", MessageType.Error)
    );
    this.extendedKpoService.getCompanyEup(this.storage.get("eupId")).subscribe(
      (result) => {
        this.senderCompanyEup = result;
      },
      (error) => this.alertService.showMessage("Błąd podczas pobierania danych podmiotu", MessageType.Error)
    );
  }

  parseAddress(address: string) {
    if (address === undefined) {
      return "";
    }
    const elements = address.split(", ");
    const town = elements.find((el) => el.split(": ")[0] === "Miejscowość")?.split(": ")[1];
    const street = elements.find((el) => el.split(": ")[0] === "Ulica")?.split(": ")[1];
    return town + (street === undefined ? "" : " ul. " + street);
  }

  setCompany(company: WasteRegisterPublicApiApiModelsElasticsearchCompanyEs) {
    this.receiverCompany = company;
    console.log(this.receiverCompany);
  }

  setCarrierCompany(company: WasteRegisterPublicApiApiModelsElasticsearchCompanyEs) {
    this.carrierCompany = company;
    console.log(this.carrierCompany);
  }

  setEup(eup: WasteRegisterPublicApiApiModelsElasticsearchEupEs) {
    this.receiverCompanyEup = eup;
  }

  formatTime($event) {}

  wasteGeneratedTerytPkFocusout($event) {
    const optionValue = $event?.option?.value == undefined || $event?.option?.value == "" ? $event?.target?.value : $event?.option?.value;
    if (optionValue == undefined || optionValue == "") return;
    const value = this.copyOfWasteGeneratedTerytPks.find((x) => x.fulltext === optionValue);
    if (value) this.wasteGeneratedTerytPkModel = value;
    console.log("WASTEGT", value);
  }
  wasteGeneratedTerytPkActivated($event) {
    const optionValue = $event?.option?.value == undefined || $event?.option?.value == "" ? $event?.target?.value : $event?.option?.value;
    if (optionValue == undefined || optionValue == "") return;
    const value = this.copyOfWasteGeneratedTerytPks.find((x) => x.fulltext === optionValue);
    if (value) this.wasteGeneratedTerytPkModel = value;
    console.log("WASTEGT", value);
  }
  wasteCodeActivated($event) {
    const optionValue = $event?.option?.value == undefined || $event?.option?.value == "" ? $event?.target?.value : $event?.option?.value;
    if (this.copyOfWasteCodes === undefined || optionValue == undefined || optionValue == "") {
      return;
    }
    this.wasteCodeModel = this.copyOfWasteCodes.find((x) => x.code === optionValue);
    this.fillWasteCodeExtended(this.wasteCodeModel?.code);
  }

  wasteCodeFocusout($event) {
    const optionValue = $event?.option?.value == undefined || $event?.option?.value == "" ? $event?.target?.value : $event?.option?.value;
    if (this.copyOfWasteCodes === undefined || optionValue == undefined || optionValue == "") {
      return;
    }

    console.log(optionValue);

    this.wasteCodeModel = this.copyOfWasteCodes.find((x) => x.code === optionValue);

    if (this.wasteCodeModel === undefined) {
      this.wasteCode.setErrors({ notUnique: true });
    }

    this.fillWasteCodeExtended(this.wasteCodeModel?.code);
  }

  fillWasteCodeExtended(wasteCode) {
    const desc = this.wasteCodeExtendedDescriptions[wasteCode];
    if (desc) {
      this.details.wasteCodeExtendedDescription = desc;
      this.details.wasteCodeExtended = true;
    } else {
      this.details.wasteCodeExtendedDescription = "";
      this.details.wasteCodeExtended = false;
    }
  }

  wasteProcessActivated($event) {
    const optionValue = $event?.option?.value == undefined || $event?.option?.value == "" ? $event?.target?.value : $event?.option?.value;
    if (this.copyOfWasteCodes === undefined || optionValue == undefined) {
      return;
    }
    this.wasteProcessModel = this.copyOfWasteProcesses.find((x) => x.codeName === optionValue);
    this.wasteProcessDescription = this.wasteProcessModel?.name;
  }

  wasteProcessFocusout($event) {
    const optionValue = $event?.option?.value == undefined || $event?.option?.value == "" ? $event?.target?.value : $event?.option?.value;
    if (this.copyOfWasteCodes === undefined || optionValue == undefined) {
      return;
    }
    this.wasteProcessModel = this.copyOfWasteProcesses.find((x) => x.codeName === optionValue);
    this.wasteProcessDescription = this.wasteProcessModel?.name;
  }

  parseWasteMass($event) {
    if (this.wasteMassValue.indexOf(",") !== -1) {
      this.wasteMassValue = this.wasteMassValue.replace(",", ".");
    }
    const parsedMass = parseFloat(this.wasteMassValue);
    if (Number.isNaN(parsedMass)) {
      this.wasteMassValue = "";
      this.details.wasteMass = undefined;
      return;
    }
    this.details.wasteMass = parseFloat(this.wasteMassValue);
    this.wasteMassValue = parsedMass.toFixed(4).replace(".", ",");
  }

  timeChangeHandler($event) {
    console.log($event);
  }

  extendedCodeChecked($event) {
    console.log($event);
  }

  showHeadquarterSelection() {
    this.guard.navigationAllowed = true;
    this.router.navigateByUrl("/headquarter-selection");
    this.dialogRef.close(false);
  }

  updatePlanned(form) {
    if (!form.form.valid) {
      return;
    }

    this.addingCard = true;
    this.details.cardStatus = "Planowana";
    this.details.wasteCode = this.wasteCodeModel.code;
    this.details.wasteCodeDescription = this.wasteCodeModel.description;
    this.details.wasteCodeId = this.wasteCodeModel.wasteCodeId;
    this.details.wasteProcessId = this.wasteProcessModel?.wasteProcessId;
    this.details.senderCompanyId = this.senderCompany.companyId;
    this.details.senderCompanyName = this.senderCompany.name;
    this.details.senderEupId = this.senderCompanyEup.eupId;
    this.details.carrierCompanyId = this.carrierCompany.companyId;
    this.details.carrierCompanyName = this.carrierCompany.name;
    this.details.receiverCompanyId = this.receiverCompany.companyId;
    this.details.receiverEupId = this.receiverCompanyEup.eupId;
    this.details.receiverCompanyAddress = this.receiverCompany.address;
    this.details.receiverCompanyName = this.receiverCompany.name;
    this.details.creationDateTime = new Date();

    if (this.details.wasteCodeExtended != true) this.details.wasteCodeExtendedDescription = undefined;

    if (this.details.hazardousWasteReclassification != true) this.details.hazardousWasteReclassificationDescription = undefined;

    if (this.wasteGeneratedTerytPkModel != undefined) {
      this.details.wasteGeneratedTerytPk = this.details.isWasteGenerating ? this.wasteGeneratedTerytPkModel.pk : undefined;
    }
    if (!this.details.isWasteGenerating) {
      this.details.wasteGeneratingAdditionalInfo = undefined;
    }

    this.details.plannedTransportTime = this.plannedDate;

    this.details.plannedTransportTime.setUTCHours(this.plannedTime.getHours(), this.plannedTime.getMinutes());

    const driverKey = this.driver ? `[nr. kier. ${this.driver.key}]` : this.details.vehicleRegNumber == "" ? undefined : this.details.vehicleRegNumber;

    this.extendedKpoService.updatePlanned((this.details as any).kpoId, this.details, driverKey).subscribe(
      (result) => {
        this.alertService.showMessage("Pomyślnie zaktualizowano kartę!", MessageType.Info);
        this.addingCard = false;
        this.dialogRef.close(true);
      },
      (e) => {
        // Error calling PlanKpo: {\"WasteMass\":[\"'Waste Mass' must not be empty.\"]}
        const error: string = e.error;
        console.log(error);
        const matches = /\{.*?\}/g.exec(error);
        let errorMessage = "";
        if (matches.length === 1) {
          const json = matches[0].replace("\\", "");
          const parsed = JSON.parse(json);
          for (const element in parsed) {
            if (parsed[element]) {
              parsed[element].forEach((line) => {
                errorMessage += `"${line}", `;
              });
            }
          }
          errorMessage = errorMessage.substr(0, errorMessage.length - 2);
          this.alertService.showMessage("Wystąpił błąd podczas aktualizacji karty: \n" + errorMessage, MessageType.Error);
        } else {
          this.alertService.showMessage("Wystąpił niespodziewany podczas dodawania karty", MessageType.Error);
        }
        this.addingCard = false;
        console.error(error);
      }
    );
  }
}
