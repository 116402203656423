<div fxLayout="row wrap" class="filter-container" fxLayoutGap="1%" fxLayoutGap.lt-md="0" class="basic-filter">
  <mat-form-field fxFlex fxFlex.lt-md="100%" appearance="outline" *ngIf="allowedCardStatuses?.length">
    <mat-label>Status</mat-label>
    <mat-select [(ngModel)]="advancedQuery.cardStatusCodeName" (selectionChange)="selectChanged($event)">
      <mat-option *ngFor="let item of allowedCardStatuses" [value]="item.value">{{ item.label }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field fxFlex fxFlex.lt-md="100%" appearance="outline" *ngIf="showTransportDateRange === true">
    <mat-label>Czas transportu</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate placeholder="Początek" [(ngModel)]="advancedQuery.transportDateRange.from" (dateChange)="queryChanged($event)" />
      <input matEndDate placeholder="Koniec" [(ngModel)]="advancedQuery.transportDateRange.to" (dateChange)="queryChanged($event)" />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
</div>
<mat-accordion>
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="header-label">Filtr zaawansowany</div>
        <mat-chip-list aria-label="Filtr zaawansowany">
          <!-- <mat-chip *ngIf="advancedQuery.role !== 'CARRIER'">Rola: {{advancedQuery.role}}</mat-chip> -->
          <mat-chip *ngIf="advancedQuery.cardNumber.length">Numer karty: {{ advancedQuery.cardNumber }} </mat-chip>
          <mat-chip *ngIf="advancedQuery.wasteCode.length">Kod odpadu: {{ advancedQuery.wasteCode }}</mat-chip>
          <mat-chip *ngIf="advancedQuery.vehicleRegistrationNumber?.length">Numer rejestracyjny: {{ advancedQuery.vehicleRegistrationNumber }}</mat-chip>
          <mat-chip *ngIf="advancedQuery.senderName?.length">Nazwa przekazującego: {{ advancedQuery.senderName }}</mat-chip>
          <mat-chip *ngIf="advancedQuery.year?.length">Rok: {{ advancedQuery.year }}</mat-chip>
        </mat-chip-list>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div fxLayout="row wrap" class="filter-container" fxLayoutGap="1%" fxLayoutGap.lt-md="0">
      <mat-form-field fxFlex fxFlex.lt-md="100%" appearance="outline" *ngIf="showRole">
        <mat-label>Rola</mat-label>
        <mat-select [(ngModel)]="advancedQuery.role" (selectionChange)="selectChanged($event)">
          <mat-option value="CARRIER">Transportujący</mat-option>
          <mat-option value="SENDER">Przekazujący</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex fxFlex.lt-md="100%" appearance="outline">
        <mat-label>Numer karty</mat-label>
        <input matInput autocomplete="off" (keyup)="queryChanged($event)" [(ngModel)]="advancedQuery.cardNumber" placeholder="Wyszukaj wg. numeru karty" />
      </mat-form-field>
      <mat-form-field fxFlex fxFlex.lt-md="100%" appearance="outline">
        <mat-label>Kod odpadu</mat-label>
        <input matInput autocomplete="off" (keyup)="queryChanged($event)" [(ngModel)]="advancedQuery.wasteCode" placeholder="Wyszukaj wg. kodu odpadu" />
      </mat-form-field>
      <mat-form-field fxFlex fxFlex.lt-md="100%" appearance="outline">
        <mat-label>Rok</mat-label>
        <input matInput autocomplete="off" (keyup)="queryChanged($event)" [(ngModel)]="advancedQuery.year" placeholder="Wyszukaj wg. roku kalendarzowego" />
      </mat-form-field>
      <mat-form-field fxFlex fxFlex.lt-md="100%" appearance="outline" *ngIf="advancedQuery.vehicleRegistrationNumber !== null">
        <mat-label>Numer rejestracyjny</mat-label>
        <input matInput autocomplete="off" (keyup)="queryChanged($event)" [(ngModel)]="advancedQuery.vehicleRegistrationNumber" placeholder="Wyszukaj wg. numeru rejestracyjnego samochodu" />
      </mat-form-field>
      <mat-form-field fxFlex fxFlex.lt-md="100%" appearance="outline" *ngIf="advancedQuery.senderName !== null">
        <mat-label>Nazwa przekazującego</mat-label>
        <input matInput (keyup)="queryChanged($event)" [(ngModel)]="advancedQuery.senderName" placeholder="Wyszukaj wg. nazwy przekazującego" />
      </mat-form-field>
      <mat-form-field fxFlex fxFlex.lt-md="100%" appearance="outline" *ngIf="advancedQuery.additionalInfo != null">
        <mat-label>Informacje dodatkowe</mat-label>
        <input matInput (keyup)="queryChanged($event)" [(ngModel)]="advancedQuery.additionalInfo" placeholder="Wyszukaj wg. informajci dodatkowych" />
      </mat-form-field>
      <!-- <mat-form-field fxFlex fxFlex.lt-md="100%" appearance="outline">
        <mat-label>Filtruj wyniki</mat-label>
        <input matInput autocomplete="off" (keyup)="applyFilter($event)" [(ngModel)]="generalQuery"
            placeholder="Wpisz wyrażenie z dowolnej kolumny" />
    </mat-form-field> -->
    </div>
  </mat-expansion-panel>
</mat-accordion>
