import { Overlay } from "@angular/cdk/overlay";
import { AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { Observable, Subject, Subscription } from "rxjs";
import { debounceTime, map, take, tap } from "rxjs/operators";
import { DraftCardDto, ExtendedKpoService, WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsCarrierSearchResultDto, WasteTransferCardDto, WasteTransferCardService } from "../../../../../api";
import { DraftCard } from "../../../../../api/model/draftCard";
import { AlertService, MessageType } from "../../../services/alert.service";
import { CardService } from "../../../services/card.service";
import { MessagingService } from "../../../services/messaging.service";
import { BdoFilterComponent } from "../../bdo-filter/bdo-filter.component";
import { FilingConfirmationDialogComponent } from "../../dialogs/filing/filing-confirmation-dialog/filing-confirmation-dialog.component";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { FiledCardDetailsComponent } from "../../dialogs/filed-card-details/filed-card-details.component";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

type KpoCard = WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsCarrierSearchResultDto;

@UntilDestroy()
@Component({
  selector: "app-waste-transfer-cards",
  templateUrl: "./waste-transfer-cards.component.html",
  styleUrls: ["./waste-transfer-cards.component.scss"],
})
export class WasteTransferCardsComponent implements OnInit, AfterViewInit {
  loading = true;
  error = false;

  reloadRequest: Subject<void> = new Subject();
  refreshSubscription = new Subscription();
  requestSubscription = new Subscription();
  scrollStrategy = this.overlay.scrollStrategies.block();
  currentPageSize = 0;

  filteredResults = [];
  filteredResults$: Observable<any[]> = new Observable((observer) => {
    this.paginator.page.pipe(untilDestroyed(this)).subscribe((p) => {
      this.load(observer);
    });
    this.load(observer);
    this.handleQueryChanged(observer);
    this.handleRefresh(observer);
    this.reloadRequest.pipe(untilDestroyed(this)).subscribe(() => this.load(observer));
  });

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(BdoFilterComponent, { static: true })
  bdoFilter: BdoFilterComponent;
  isWorking: boolean;

  constructor(public cardService: CardService, private alertService: AlertService, private messagingService: MessagingService, public dialog: MatDialog, private overlay: Overlay, private wasteTransferCardService: WasteTransferCardService, @Inject(LOCAL_STORAGE) private storage: WebStorageService, private extendedKpoService: ExtendedKpoService) {}

  ngAfterViewInit(): void {
    this.currentPageSize = this.paginator.pageSize;
    this.filteredResults$.subscribe((filteredResults) => (this.filteredResults = filteredResults));
    this.bdoFilter.advancedQuery.additionalInfo = "";
  }

  ngOnInit(): void {
    let currentDate = new Date();
    let startDate = new Date(currentDate.valueOf() - 1000 * 60 * 60 * 24 * 14);
    this.bdoFilter.advancedQuery.transportDateRange.from = startDate;
    this.bdoFilter.advancedQuery.transportDateRange.to = currentDate;
  }

  load(observer) {
    this.error = false;
    this.loading = true;

    let skip = this.paginator.pageIndex * this.paginator.pageSize;
    let take = this.paginator.pageSize;

    this.wasteTransferCardService
      .getFilteredWasteTransferCards(
        {
          cardNumber: this.bdoFilter.advancedQuery.cardNumber,
          cardStatusCodeName: this.bdoFilter.advancedQuery.cardStatusCodeName,
          startTransportDate: this.bdoFilter.advancedQuery.transportDateRange.from,
          endTransportDate: this.bdoFilter.advancedQuery.transportDateRange.to,
          registrationNumber: this.bdoFilter.advancedQuery.vehicleRegistrationNumber,
          senderName: this.bdoFilter.advancedQuery.senderName,
          wasteCode: this.bdoFilter.advancedQuery.wasteCode,
          skip: skip,
          take: take,
          additionalInfo: this.bdoFilter.advancedQuery.additionalInfo,
        },
        undefined,
        undefined,
        this.storage.get("eupId")
      )
      .subscribe(
        (result) => {
          observer.next(result.items);
          this.paginator.length = result.totalItemCount;
          this.loading = false;
          this.error = false;
        },
        () => {
          this.error = true;
          this.loading = false;
          this.alertService.showMessage("Wystąpił błąd podczas ładowania listy", MessageType.Error);
        }
      );
  }

  handleRefresh(observer) {
    this.messagingService.messageReceived$.pipe(untilDestroyed(this)).subscribe((payload) => {
      this.alertService.showMessage(payload.data.body.replace("\r\n", " | "), MessageType.Notification);
      this.load(observer);
    });
  }

  handleQueryChanged(observer) {
    this.bdoFilter.advancedQueryTextChanged.pipe(untilDestroyed(this), debounceTime(300)).subscribe(() => {
      this.load(observer);
    });

    this.bdoFilter.advancedQueryChanged.pipe(untilDestroyed(this)).subscribe(() => {
      this.load(observer);
    });
  }

  @HostListener("window:focus", ["$event"])
  onFocus(event: FocusEvent): void {
    this.reloadRequest.next();
  }

  openDetailsDialog(card): void {
    this.dialog.open(FiledCardDetailsComponent, {
      width: "950px",
      data: { cardId: card.id },
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });
  }

  public saveFile(result: any): void {
    const data = window.URL.createObjectURL(result);

    var link = document.createElement("a");
    link.href = data;
    link.download = result.fileName;
    link.dispatchEvent(new MouseEvent("click", { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);

    this.isWorking = false;
  }

  anySelected() {
    return this.filteredResults.some((r) => r?.checked);
  }

  downloadArchive() {
    this.isWorking = true;
    this.extendedKpoService.getTransportConfirmedCardReport(this.filteredResults.filter((r) => r?.checked).map((r) => r.kpoId)).subscribe((file) => {
      this.saveFile(file);
    });
  }

  selectAll() {
    this.filteredResults.forEach((element) => {
      element.checked = true;
    });
  }
}
