<div class="container">
    <mat-card class="login-card">
        <img mat-card-image src="/assets/logo.png" />
        <p class="subject">{{subjectName}}</p>
      <mat-card-header>
        <mat-card-title>
            Wybór siedziby firmy
        </mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <mat-spinner *ngIf="loading" style="margin: 30px auto;"></mat-spinner>
        <app-headquarter-list
          *ngIf="!loading"
          [headquarters]="headquarters"
        ></app-headquarter-list>
      </mat-card-content>
    </mat-card>
  </div>
  