<mat-form-field appearance="standard" style="width: 100%">
  <mat-label>Wyszukaj</mat-label>
  <input #search matInput [(ngModel)]="query" />
</mat-form-field>
<button mat-raised-button style="margin: 90px auto" (click)="ngOnInit()" *ngIf="error && !loading">Ponów próbę</button>
<mat-spinner *ngIf="loading" style="margin: 90px auto"></mat-spinner>
<mat-radio-group class="accordion-container">
  <ng-container *ngIf="preselectedCompany != undefined">
    <app-card-block header="Nazwa" [text]="preselectedCompany.name" [value]="preselectedCompany" radio="true" [checkboxChecked]="true" expanded="false" (cardSelected)="radioSelected(preselectedCompany)">
      <app-card-block-field header="Numer rejestrowy">
        {{ preselectedCompany.registrationNumber }}
      </app-card-block-field>
      <app-card-block-field header="Adres">
        {{ parseAddress(preselectedCompany.address) }}
      </app-card-block-field>
    </app-card-block>
  </ng-container>
  <ng-container *ngFor="let item of filteredResults$ | async">
    <app-card-block header="Nazwa" [text]="item.name" [value]="item" radio="true" expanded="false" (cardSelected)="radioSelected(item)">
      <app-card-block-field header="Numer rejestrowy">
        {{ item.registrationNumber }}
      </app-card-block-field>
      <app-card-block-field header="Adres">
        {{ parseAddress(item.address) }}
      </app-card-block-field>
    </app-card-block>
  </ng-container>
</mat-radio-group>
