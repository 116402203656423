import {
  Component,
  OnInit,
  Input,
  Inject,
  Output,
  EventEmitter,
} from "@angular/core";
import { AddSubjectDialogComponent } from "./add-subject-dialog/add-subject-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import {
  SubjectAuthenticationService,
  SubjectDto,
  UserService,
  ExtendedKpoService,
} from "../../../../../../api";
import { Router } from "@angular/router";
import { AlertService, MessageType } from "../../../../services/alert.service";
import { tap } from "rxjs/internal/operators/tap";
import { AppComponent } from "../../../../app.component";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { ConfirmDialogComponent } from "../../../dialogs/confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-subject-list",
  templateUrl: "./subject-list.component.html",
  styleUrls: ["./subject-list.component.scss"],
})
export class SubjectListComponent implements OnInit {
  _subjects: SubjectDto[];
  get subjeects(): SubjectDto[] {
    return this._subjects;
  }

  @Input() set subjects(value: SubjectDto[]) {
    this._subjects = value;
    this.dataSource = this._subjects;
  }

  loading = false;
  displayedColumns: string[] = ["name", "radio"];
  dataSource = null;
  hasAdminRights = false;
  @Output() updated = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private userService: UserService,
    private extendedKpoService: ExtendedKpoService,
    private subjectAuthenticationService: SubjectAuthenticationService,
    private alertService: AlertService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) {}

  openDialog(): void {
    const dialogRef = this.dialog.open(AddSubjectDialogComponent, {
      width: "550px",
      data: {},
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.loading = true;
      this.reload();
    });
  }

  select(subject: SubjectDto) {
    this.userService.setSelectedSubject(subject.id).subscribe((result) => {
      if (subject.clientId == null) {
        console.log("selecting", subject);
        this.storage.set("SubjectId", subject.clientId);
        this.storage.set("CompanyId", subject.companyId);
        this.router.navigateByUrl("/dashboard");
        return;
      }

      this.storage.set("SubjectName", subject.name);
      this.storage.set("SubjectId", subject.clientId);
      this.storage.set("CompanyId", subject.companyId);
      this.router.navigateByUrl("/headquarter-selection");
    });
  }

  skip() {
    this.router.navigateByUrl("/admin/users");
  }

  removeSubject(subject: SubjectDto) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "350px",
      data: { message: "Czy na pewno chcesz usunąć ten podmiot?" },
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.subjectAuthenticationService
          .removeSubject(subject.id)
          .subscribe((r) => {
            this.alertService.showMessage(
              "Pomyślnie usunięto klucz API podmiotu",
              MessageType.Info
            );
            this.reload();
          });
      }
    });
  }

  reload() {
    this.loading = true;
    this.updated.emit();
  }

  ngOnInit(): void {
    this.userService.getCurrentUser().subscribe(
      (user) => {
        this.hasAdminRights = user.group.hasAdminRights;
        AppComponent.Instance.showAdminOptions(this.hasAdminRights);
      },
      () =>
        this.alertService.showMessage(
          "Użytkownik nie jest zalogowany",
          MessageType.Error
        )
    );
  }

  selected() {
    this.router.navigateByUrl("/");
  }
}
