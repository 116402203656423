import { Component, OnInit, ViewChild } from "@angular/core";
import { AlertService } from "../../../services/alert.service";
import { UsersService, UserDto } from "../../../../../api";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DriverWizardComponent } from "./driver-wizard/driver-wizard.component";
import { DispatcherWizardComponent } from "./dispatcher-wizard/dispatcher-wizard.component";
import { MatPaginator } from "@angular/material/paginator";
import { Observable } from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { RoleService } from "../../../services/role.service";

@UntilDestroy()
@Component({
  selector: "app-drivers",
  templateUrl: "./drivers.component.html",
  styleUrls: ["./drivers.component.scss"],
})
export class DriversComponent implements OnInit {
  isLoading = false;
  results: UserDto[];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(private alertService: AlertService, private userService: UsersService, private dialog: MatDialog, private cookieService: CookieService, private roleService: RoleService) {
    this.roleService.role$.pipe(untilDestroyed(this)).subscribe((r) => (this.role = r));
  }

  role: string;

  filteredResults$ = new Observable<UserDto[]>((observer) => {
    this.load(observer);
    this.paginator.page.pipe(untilDestroyed(this)).subscribe((p) => this.load(observer));
  });

  load(observer) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    const filteredResults = this.results.slice(startIndex, Math.min(startIndex + this.paginator.pageSize, this.paginator.length));
    observer.next(filteredResults);
  }

  reloadData() {
    this.isLoading = true;
    this.userService.getAllAssigned().subscribe((result: UserDto[]) => {
      this.results = result.filter((f) => f.group.name === "Kierowca" || f.group.name === "Dyspozytor" || f.group.name === "Transportujący");
      this.paginator.length = result.length;
      this.isLoading = false;
    });
  }

  ngOnInit() {
    this.reloadData();
  }

  openWizard(type: "dispatcher" | "driver", mode: string, element = {}) {
    let dialogRef: MatDialogRef<any>;
    switch (type) {
      case "dispatcher":
        dialogRef = this.dialog.open(DispatcherWizardComponent, {
          data: { mode: mode, user: { ...element } },
          width: "500px",
          closeOnNavigation: true,
        });
        break;
      case "driver":
        dialogRef = this.dialog.open(DriverWizardComponent, {
          data: { mode: mode, user: { ...element } },
          width: "500px",
          closeOnNavigation: true,
        });
        break;
    }

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.reloadData();
      }
    });
  }
}
