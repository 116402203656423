import { Component, OnInit } from "@angular/core";
import { Route } from "@angular/router";
import { AuthGuard, CanDeactivateGuard } from "../../guards/auth.guard";
import { ArchiveComponent } from "../pages/archive/archive.component";
import { CarsComponent } from "../pages/cars/cars.component";
import { CompanyDetailsComponent } from "../pages/company-details/company-details.component";
import { DashboardComponent } from "../pages/dashboard/dashboard.component";
import { DriversComponent } from "../pages/drivers/drivers.component";
import { RealisingNowComponent } from "../pages/realising-now/realising-now.component";
import { StatsComponent } from "../pages/stats/stats.component";
import { FilingComponent } from "../pages/filing/filing.component";
import { CookieService } from "ngx-cookie-service";
import { WasteRecordCardsComponent } from "../pages/waste-record-cards/waste-record-cards.component";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { RoleService } from "../../services/role.service";
import { ReportComponent } from "../pages/report/report.component";

@UntilDestroy()
@Component({
  selector: "app-mobile-footer",
  templateUrl: "./mobile-footer.component.html",
  styleUrls: ["./mobile-footer.component.scss"],
})
export class MobileFooterComponent implements OnInit {
  public routing: Route[] = [
    {
      path: "dashboard",
      data: {
        title: "Moje BDO",
      },
      children: [
        {
          path: "carrier-kpo",
          component: DashboardComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
          data: {
            title: "Tablica zadań",
            icon: "/assets/icons/BDO-web_ikonki_tablica-zadan.png",
            activeIcon: "/assets/icons/BDO-web_ikonki_tablica-zadan_aktywny.png",
          },
        },
        {
          path: "realising-now",
          component: RealisingNowComponent,
          data: {
            title: "Aktualnie realizowane zlecenia",
            icon: "/assets/icons/BDO-web_ikonki_aktuane-zlecenia.png",
            activeIcon: "/assets/icons/BDO-web_ikonki_aktuane-zlecenia_aktywny.png",
          },
          canActivate: [AuthGuard],
        },
        {
          path: "archive",
          component: ArchiveComponent,
          data: {
            title: "Archiwum KPO",
            icon: "/assets/icons/BDO-web_ikonki_archiwum-kpo.png",
            activeIcon: "/assets/icons/BDO-web_ikonki_archiwum-kpo_aktywny.png",
          },
          canActivate: [AuthGuard],
        },

        {
          path: "report",
          component: ReportComponent,
          data: {
            title: "Raport",
            icon: "/assets/icons/BDO-web_ikonki_archiwum-kpo.png",
            activeIcon: "/assets/icons/BDO-web_ikonki_archiwum-kpo_aktywny.png",
          },
          canActivate: [AuthGuard],
        },
      ],
    },
    {
      path: "my-company",
      data: {
        title: "Moja firma",
        asExtended: true,
      },
      children: [
        // {
        //   path: "details",
        //   component: CompanyDetailsComponent,
        //   data: {
        //     title: "Dane firmy",
        //     icon: "/assets/icons/BDO-web_ikonki_dane-firmy.png",
        //     activeIcon: "/assets/icons/BDO-web_ikonki_dane-firmy_aktywny.png",
        //   },
        //   canActivate: [AuthGuard],
        //   canDeactivate: [CanDeactivateGuard],
        // },

        {
          path: "filing",
          component: FilingComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
          data: {
            title: "Dodaj odpady do ewidencji",
            icon: "/assets/icons/BDO-web_ikonki_tablica-zadan.png",
            activeIcon: "/assets/icons/BDO-web_ikonki_tablica-zadan_aktywny.png",
          },
        },
        {
          path: "filed-cards",
          component: WasteRecordCardsComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
          data: {
            title: "Ewidencja odpadów",
            icon: "/assets/icons/BDO-web_ikonki_tablica-zadan.png",
            activeIcon: "/assets/icons/BDO-web_ikonki_tablica-zadan_aktywny.png",
            showInRoles: ["CARRIER", "SENDER"],
          },
        },
        {
          path: "drivers",
          component: DriversComponent,
          data: {
            title: "Wykaz użytkowników",
            icon: "/assets/icons/BDO-web_ikonki_wykaz-kierowcow.png",
            activeIcon: "/assets/icons/BDO-web_ikonki_wykaz-kierowcow_aktywny.png",
          },
          canActivate: [AuthGuard],
          canDeactivate: [CanDeactivateGuard],
        },
        {
          path: "cars",
          component: CarsComponent,
          data: {
            title: "Wykaz pojazdów",
            icon: "/assets/icons/BDO-web_ikonki_wykaz-pojazdow.png",
            activeIcon: "/assets/icons/BDO-web_ikonki_wykaz-pojazdow_aktywny.png",
            showInRoles: ["CARRIER"],
          },
          canActivate: [AuthGuard],
          canDeactivate: [CanDeactivateGuard],
        },
        {
          path: "stats",
          component: StatsComponent,
          data: {
            title: "Statystyka",
            icon: "/assets/icons/BDO-web_ikonki_dane-firmy.png",
            activeIcon: "/assets/icons/BDO-web_ikonki_dane-firmy_aktywny.png",
            showInRoles: ["CARRIER"],
          },
          canActivate: [AuthGuard],
          canDeactivate: [CanDeactivateGuard],
        },
      ],
    },
  ];

  role: string;
  constructor(cookieService: CookieService, private roleService: RoleService) {
    this.roleService.role$.pipe(untilDestroyed(this)).subscribe((r) => (this.role = r));
  }

  ngOnInit(): void {}

  shouldRenderItem(route) {
    return route.data.showInRoles == undefined || route.data.showInRoles.indexOf(this.role) != -1;
  }
}
