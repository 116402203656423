import { Component, OnInit, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ExtendedKpoService } from '../../../../../../api';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-downloader',
  templateUrl: './confirmation-downloader.component.html',
  styleUrls: ['./confirmation-downloader.component.css']
})
export class ConfirmationDownloaderComponent implements OnInit {
  fileUrl;
  loading = true;
  constructor(private sanitizer: DomSanitizer,
    private extendedKpoService: ExtendedKpoService,
    public dialogRef: MatDialogRef<ConfirmationDownloaderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  b64toBlob(b64Data, contentType, sliceSize){
    sliceSize = 512;
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  ngOnInit(): void {
    this.extendedKpoService.downloadConfirmation(this.data.model.kpoId).subscribe(result => {
      const blob = this.b64toBlob(result.slice(1,-1), 'application/pdf', 512);
      this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
      this.loading = false;
    });
  }

}
