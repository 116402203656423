/**
 * BDOEX API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * Contact: mateusz.surma@mae.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ApprovedKpoDto
 */
export interface WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ApprovedKpoDto { 
    /**
     * Gets or Sets IsWasteGenerating
     */
    isWasteGenerating?: boolean;
    /**
     * Gets or Sets WasteGeneratedTeryt
     */
    wasteGeneratedTeryt?: string;
    /**
     * Gets or Sets WasteGeneratingAdditionalInfo
     */
    wasteGeneratingAdditionalInfo?: string;
    /**
     * Gets or Sets WasteGeneratedTerytPk
     */
    wasteGeneratedTerytPk?: string;
    /**
     * Id karty przekazania odpadów
     */
    kpoId?: string;
    /**
     * Rok kalendarzowy
     */
    year?: number;
    /**
     * Numer Karty Przekazania Odpadów
     */
    cardNumber?: string;
    /**
     * Id statusu Karty Przekazania Odpadów
     */
    cardStatusId?: number;
    /**
     * Status karty
     */
    cardStatus?: string;
    /**
     * Id podmiotu przekazującego odpady
     */
    senderCompanyId?: string;
    /**
     * Id miejsca prowadzenia działalności przekazującego odpady
     */
    senderEupId?: string;
    /**
     * Id podmiotu transportującego odpady
     */
    carrierCompanyId?: string;
    /**
     * Id podmiotu przejmującego odpady
     */
    receiverCompanyId?: string;
    /**
     * Id miejsca prowadzenia działalności przejmującego odpady
     */
    receiverEupId?: string;
    /**
     * Id kodu odpadu
     */
    wasteCodeId?: number;
    /**
     * Numer rejestracyjny środka transportu
     */
    vehicleRegNumber?: string;
    /**
     * Masa odpadów
     */
    wasteMass?: number;
    /**
     * Planowana data i godzina transportu
     */
    plannedTransportTime?: Date;
    /**
     * Data i godzina zatwierdzenia Karty Przekazania Odpadów
     */
    cardApprovalTime?: Date;
    /**
     * Rzeczywista data i godzina transportu
     */
    realTransportTime?: Date;
    /**
     * Użytkownik zatwierdzający Kartę Przekazania Odpadów
     */
    approvalUser?: string;
    /**
     * Id rodzaju procesu przetwarzania
     */
    wasteProcessId?: number;
    /**
     * Numer certyfikatu oraz numery pojemników
     */
    certificateNumberAndBoxNumbers?: string;
    /**
     * Oznaczenie kodowe statusu karty
     */
    cardStatusCodeName?: string;
    /**
     * Informacje dodatkowe
     */
    additionalInfo?: string;
    /**
     * Flaga ustawiana na true, jeśli kod odpadu ma status extended
     */
    wasteCodeExtended?: boolean;
    /**
     * Rodzaj odpadu extended
     */
    wasteCodeExtendedDescription?: string;
    /**
     * Flaga ustawiana na true, jeśli zmiana statusu odpadów niebezpiecznych na inne niż niebezpieczne
     */
    hazardousWasteReclassification?: boolean;
    /**
     * Rodzaj odpadu po zmianie statusu odpadów niebezpiecznych na inne niż niebezpieczne
     */
    hazardousWasteReclassificationDescription?: string;
}