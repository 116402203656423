import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Subscription, Observable, fromEvent } from "rxjs";
import { ExtendedKpoService, WasteRegisterPublicApiApiModelsElasticsearchCompanyEs } from "../../../../api";
import { SelectReceiverDialogComponent } from "../pages/home/kpo/select-receiver-dialog/select-receiver-dialog.component";

@UntilDestroy()
@Component({
  selector: "app-company-picker",
  templateUrl: "./company-picker.component.html",
  styleUrls: ["./company-picker.component.scss"],
})
export class CompanyPickerComponent implements OnInit, AfterViewInit {
  error = false;
  loading = false;
  @Output()
  public companySelected = new EventEmitter<WasteRegisterPublicApiApiModelsElasticsearchCompanyEs>();
  query = "";
  subscription: Subscription = new Subscription();
  @ViewChild("search", { static: true }) searchInput: ElementRef;
  @Input() preselectedCompany: WasteRegisterPublicApiApiModelsElasticsearchCompanyEs = undefined;

  filteredResults$: Observable<WasteRegisterPublicApiApiModelsElasticsearchCompanyEs[]> = new Observable((observer) => {
    fromEvent<any>(this.searchInput.nativeElement, "keyup")
      .debounceTime(1000)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.loading = true;
        this.extendedKpoService
          .searchCompany(this.query)
          .pipe(untilDestroyed(this))
          .subscribe((result) => {
            this.loading = false;
            observer.next(result as WasteRegisterPublicApiApiModelsElasticsearchCompanyEs[]);
          });
      });
  });

  constructor(private extendedKpoService: ExtendedKpoService, public dialogRef: MatDialogRef<SelectReceiverDialogComponent>) {}
  ngAfterViewInit(): void {}

  ngOnInit(): void {}

  parseAddress(address: string) {
    if (address === undefined) {
      return "";
    }
    const elements = address.split(", ");
    const town = elements.find((el) => el.split(": ")[0] === "Miejscowość")?.split(": ")[1];
    const street = elements.find((el) => el.split(": ")[0] === "Ulica")?.split(": ")[1];
    return town + (street === undefined ? "" : " ul. " + street);
  }

  radioSelected(item) {
    this.companySelected.emit(item);
  }
}
