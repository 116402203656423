import { Overlay } from "@angular/cdk/overlay";
import { AfterViewInit, Component, HostListener, OnDestroy, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { CookieService } from "ngx-cookie-service";
import { combineLatest, Observable, of, Subject, Subscription } from "rxjs";
import { catchError, debounceTime, map, startWith, switchMap, take, tap } from "rxjs/operators";
import { BdoServiceModelsEnumerationsCompanyType, DraftCardDto, ExtendedKpoService, WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsCarrierSearchResultDto } from "../../../../../api";
import { DraftCard } from "../../../../../api/model/draftCard";
import { AlertService, MessageType } from "../../../services/alert.service";
import { CardService } from "../../../services/card.service";
import { MessagingService } from "../../../services/messaging.service";
import { RoleService } from "../../../services/role.service";
import { BdoFilterComponent } from "../../bdo-filter/bdo-filter.component";
import { KpoDetailsDialogComponent } from "../archive/archive-details-dialog/kpo-details-dialog.component";
import { NewKpoDialogComponent } from "../home/kpo/new-kpo-dialog/new-kpo-dialog.component";
import { RejectCardDialogComponent } from "../home/kpo/reject-card-dialog/reject-card-dialog.component";
import { SelectReceiverDialogComponent } from "../home/kpo/select-receiver-dialog/select-receiver-dialog.component";
import { ViewDraftCardDialogComponent } from "../home/kpo/view-draft-card-dialog/view-draft-card-dialog.component";
import { ConfirmWithdrawalDialogComponent } from "../realising-now/confirm-withdrawal-dialog/confirm-withdrawal-dialog.component";
import { GenerateConfirmationDialogComponent } from "../realising-now/generate-confirmation-dialog/generate-confirmation-dialog.component";
import { SelectDriverDialogComponent } from "../realising-now/select-driver-dialog/select-driver-dialog.component";
import { PlannedCardEditDialogComponent } from "../../dialogs/planned-card-edit-dialog/planned-card-edit-dialog.component";
import { PlannedCardApprovalDialogComponent } from "../../dialogs/planned-card-approval-dialog/planned-card-approval-dialog.component";
import { PlannedCardRemovalDialogComponent } from "../../dialogs/planned-card-removal-dialog/planned-card-removal-dialog.component";
type KpoCard = WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsCarrierSearchResultDto;

@UntilDestroy()
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements AfterViewInit {
  loading = true;
  error = false;
  role: string;

  reloadRequest$: Subject<void> = new Subject();
  refreshSubscription = new Subscription();
  requestSubscription = new Subscription();
  scrollStrategy = this.overlay.scrollStrategies.block();

  filteredResult$: Observable<(DraftCard | KpoCard)[]>;

  filterCardStatuses = [
    { label: "Planowane", value: "PLANNED" },
    { label: "Zatwierdzone", value: "APPROVED" },
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(BdoFilterComponent, { static: true })
  bdoFilter: BdoFilterComponent;
  hasRejected = false;
  constructor(public cardService: CardService, private alertService: AlertService, private router: Router, private messagingService: MessagingService, private extendedKpoService: ExtendedKpoService, public dialog: MatDialog, private overlay: Overlay, private cookieService: CookieService, private roleService: RoleService) {
    this.roleService.role$.pipe(untilDestroyed(this)).subscribe((r) => {
      this.role = r;
      this.filterCardStatuses =
        this.role == "CARRIER"
          ? undefined
          : [
              { label: "Planowane", value: "PLANNED" },
              { label: "Zatwierdzone", value: "APPROVED" },
            ];
      if (this.bdoFilter) {
        this.bdoFilter.advancedQuery.cardStatusCodeName = "APPROVED";
        this.reloadRequest$.next();
      }

      console.log("changing bdo filters", this.bdoFilter?.advancedQuery?.cardStatusCodeName);
    });
  }
  ngAfterViewInit(): void {
    this.filteredResult$ = combineLatest([this.reloadRequest$.pipe(startWith(0)), this.paginator.page.pipe(startWith(0)), this.bdoFilter.advancedQueryTextChanged.pipe(startWith(0)), this.bdoFilter.advancedQueryChanged.pipe(startWith(0)), this.roleService.role$.pipe(startWith(0)), this.messagingService.messageReceived$.pipe(startWith(0))]).pipe(
      debounceTime(600),
      untilDestroyed(this),
      tap(() => {
        this.error = false;
        this.loading = true;
      }),
      switchMap((s) => {
        return this.cardService.getDashboardCards(this.bdoFilter.advancedQuery, this.paginator.pageIndex, this.paginator.pageSize).pipe(
          tap((cards) => {
            this.paginator.length = cards.totalItemsCount;
            this.cardService
              .getRealisingNowCards({ ...this.bdoFilter.advancedQuery, cardStatusCodeName: "REJECTED" }, 0, 2)
              .pipe(untilDestroyed(this))
              .subscribe((rejected) => {
                this.hasRejected = rejected.totalItemsCount > 0;
              });
          }),
          catchError((error) => {
            this.alertService.showMessage("Wystąpił błąd podczas ładowania listy", MessageType.Error);
            console.error(error);
            return of(error);
          }),
          map((cards) =>
            cards.items.map((m) => {
              const kpoCard = m as KpoCard;
              return {
                ...m,
                user: kpoCard ? cards.userAttributions[kpoCard.kpoId] : undefined,
              };
            })
          ),
          tap((cards) => {
            this.loading = false;
            this.error = false;
          })
        );
      })
    );
    this.handleRefresh();
  }

  loadEupInfo(item: KpoCard) {
    this.roleService.role$
      .pipe(
        untilDestroyed(this),
        switchMap((role) => this.extendedKpoService.getKpoDetails(item.kpoId, item.cardStatusCodeName, role == "CARRIER" ? BdoServiceModelsEnumerationsCompanyType.NUMBER_2 : BdoServiceModelsEnumerationsCompanyType.NUMBER_1).pipe(switchMap((details) => this.extendedKpoService.getEupDetails(details.senderEupId))))
      )
      .subscribe((r) => (item.eup = r.registrationNumber + " " + r.name));
  }

  handleRefresh() {
    this.messagingService.messageReceived$.pipe(untilDestroyed(this)).subscribe((payload) => {
      this.alertService.showMessage(payload.data.body.replace("\r\n", " | "), MessageType.Notification);
    });
  }

  @HostListener("window:focus", ["$event"])
  onFocus(event: FocusEvent): void {
    this.reloadRequest$.next();
  }

  getLastModificationDate(kpo: any) {
    if (kpo.creationDateTime === undefined) {
      kpo.creationDateTime = kpo.kpoLastModifiedAt;
    }
    return kpo.creationDateTime === undefined ? kpo.kpoLastModifiedAt : kpo.creationDateTime;
  }

  openCardCreationDialog() {
    const dialogRef = this.dialog.open(NewKpoDialogComponent, {
      width: "1100px",
      data: {},
      panelClass: "full-screen-dialog",
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.bdoFilter.advancedQuery.cardStatusCodeName == result;
      this.reloadRequest$.next();
    });
  }

  openDetailsDialog(card): void {
    if (card.cardStatusCodeName === "APPROVED" || card.cardStatusCodeName === "PLANNED") {
      this.dialog.open(KpoDetailsDialogComponent, {
        width: "950px",
        data: { model: card },
        panelClass: "dialog",
        scrollStrategy: this.scrollStrategy,
        closeOnNavigation: true,
      });
    } else {
      this.dialog.open(ViewDraftCardDialogComponent, {
        width: "950px",
        data: { model: card },
        panelClass: "dialog",
        scrollStrategy: this.scrollStrategy,
        closeOnNavigation: true,
      });
    }
  }

  openDriverSelectionDialog(card): void {
    const model = { ...card };
    const dialogRef = this.dialog.open(SelectDriverDialogComponent, {
      width: "550px",
      data: { model: { ...model } },
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });
    dialogRef.afterClosed().subscribe((result) => (result ? this.reloadRequest$.next() : null));
  }

  openReceiverSelectionDialog(card: DraftCardDto): void {
    const dialogRef = this.dialog.open(SelectReceiverDialogComponent, {
      width: "950px",
      data: { model: { ...card } },
      panelClass: "dialog",
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result) => (result ? this.reloadRequest$.next() : null));
  }

  openCardRejectionDialog(card): void {
    const dialogRef = this.dialog.open(RejectCardDialogComponent, {
      width: "750px",
      data: { model: { ...card } },
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result) => (result ? this.reloadRequest$.next() : null));
  }

  openConfirmationGenerationDialog(element): void {
    const dialogRef = this.dialog.open(GenerateConfirmationDialogComponent, {
      width: "550px",
      data: { model: { ...element } },
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result) => (result ? this.reloadRequest$.next() : null));
  }

  openPlannedCardEditDialog(element): void {
    const dialogRef = this.dialog.open(PlannedCardEditDialogComponent, {
      width: "1100px",
      data: { model: { ...element } },
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result) => (result ? this.reloadRequest$.next() : null));
  }

  openPlannedCardApprovalDialog(element, mode = "approve"): void {
    const dialogRef = this.dialog.open(PlannedCardApprovalDialogComponent, {
      width: "550px",
      data: { model: { ...element }, mode: mode },
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result) => (result ? this.reloadRequest$.next() : null));
  }

  openPlannedRemovalDialog(element): void {
    const dialogRef = this.dialog.open(PlannedCardRemovalDialogComponent, {
      width: "550px",
      data: { model: { ...element } },
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result) => (result ? this.reloadRequest$.next() : null));
  }

  openCardWithdrawalDialog(element): void {
    const dialogRef = this.dialog.open(ConfirmWithdrawalDialogComponent, {
      width: "550px",
      data: { model: { ...element } },
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result) => (result ? this.reloadRequest$.next() : null));
  }

  redirectToRejected() {
    this.router.navigate(["/dashboard/realising-now"], { queryParams: { status: "REJECTED" } });
  }
}
