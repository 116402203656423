import { AfterViewInit, Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApproveAndGeneratePlannedCardRequest, BdoServiceModelsEnumerationsCompanyType, ExtendedKpoService } from "../../../../../api";
import { AlertService, MessageType } from "../../../services/alert.service";

@Component({
  selector: "app-planned-card-approval-dialog",
  templateUrl: "./planned-card-approval-dialog.component.html",
  styleUrls: ["./planned-card-approval-dialog.component.scss"],
})
export class PlannedCardApprovalDialogComponent implements OnInit, AfterViewInit {
  generateRequest: ApproveAndGeneratePlannedCardRequest = {};

  constructor(public dialogRef: MatDialogRef<PlannedCardApprovalDialogComponent>, private extendedKpoService: ExtendedKpoService, private alertService: AlertService, @Inject(MAT_DIALOG_DATA) public data: any) {}
  ngAfterViewInit(): void {
    if (this.data.mode == "generate") {
      this.generateRequest.realTransportDate = new Date();
      this.generateRequest.realTransportTime = new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      this.generateRequest.vehicleRegNumber = this.data.model.vehicleRegNumber;
    }
  }

  ngOnInit(): void {}

  approve() {
    this.extendedKpoService.approvePlanned(this.data.model.kpoId).subscribe(
      () => {
        this.alertService.showMessage("Pomyślnie zatwierdzono kartę", MessageType.Info);
        this.dialogRef.close(true);
      },
      (error) => this.alertService.showMessage("Wystąpił błąd podczas zatwierdzania karty", MessageType.Error)
    );
  }

  approveAndGenerateConfirmation() {
    this.extendedKpoService.approvePlannedAndGenerateConfirmation(this.data.model.kpoId, this.generateRequest, BdoServiceModelsEnumerationsCompanyType.NUMBER_1).subscribe(
      () => {
        this.alertService.showMessage("Pomyślnie zatwierdzono kartę i wygenerowano potwierdzenie", MessageType.Info);
        this.dialogRef.close(true);
      },
      (error) => this.alertService.showMessage("Wystąpił błąd podczas zatwierdzania karty", MessageType.Error)
    );
  }
}
