import { Component, OnInit, ViewChild, Inject, HostListener, OnDestroy } from "@angular/core";
import { Observable, of, Subject, Subscription } from "rxjs";
import { ExtendedKpoService, WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsCarrierSearchResultDto } from "../../../../../api";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { KpoDetailsDialogComponent } from "./archive-details-dialog/kpo-details-dialog.component";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { AlertService, MessageType } from "../../../services/alert.service";
import { Overlay } from "@angular/cdk/overlay";
import { tap, map, debounceTime, take } from "rxjs/operators";
import { DraftCard } from "../../../../../api/model/draftCard";
import { CardService } from "../../../services/card.service";
import { MessagingService } from "../../../services/messaging.service";
import { AdvancedFilterQuery, BdoFilterComponent } from "../../bdo-filter/bdo-filter.component";
import { NewKpoDialogComponent } from "../home/kpo/new-kpo-dialog/new-kpo-dialog.component";
import { ViewDraftCardDialogComponent } from "../home/kpo/view-draft-card-dialog/view-draft-card-dialog.component";
import { CookieService } from "ngx-cookie-service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { RoleService } from "../../../services/role.service";
import { EventEmitter, until } from "protractor";
type KpoCard = WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsCarrierSearchResultDto;
@UntilDestroy()
@Component({
  selector: "app-archive",
  templateUrl: "./archive.component.html",
  styleUrls: ["./archive.component.scss"],
})
export class ArchiveComponent implements OnInit {
  public loading = true;
  public error = false;
  carrierName: string;
  role: string;
  scrollStrategy = this.overlay.scrollStrategies.block();

  checkAllEvent = new Subject<Boolean>();

  filterCardStatuses: { label: string; value: string }[] = [
    { label: "Potwierdzony transport", value: "TRANSPORT_CONFIRMATION" },
    { label: "Wycofane", value: "WITHDRAWN" },
  ];

  reloadRequest: Subject<void> = new Subject();

  filteredResults$: Observable<(DraftCard | KpoCard)[]> = new Observable((observer) => {
    this.load(observer, false);
    this.paginator.page.pipe(untilDestroyed(this)).subscribe((p) => this.load(observer, false));
    this.handleQueryChanged(observer);
    this.handleRefresh(observer);
    this.reloadRequest.pipe(untilDestroyed(this)).subscribe(() => this.load(observer, false));
    this.checkAllEvent.pipe(untilDestroyed(this)).subscribe(() => this.load(observer, true));
  }).pipe(
    untilDestroyed(this),
    tap((r) => console.log(r)),
    map((r: (DraftCard | KpoCard)[]) => {
      const a: (DraftCard | KpoCard)[] = r;
      return a;
    })
  );

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(BdoFilterComponent, { static: true })
  bdoFilter: BdoFilterComponent;
  private isWorking: boolean = false;

  constructor(public cardService: CardService, private alertService: AlertService, private messagingService: MessagingService, @Inject(LOCAL_STORAGE) private storage: WebStorageService, private extendedKpoService: ExtendedKpoService, public dialog: MatDialog, private overlay: Overlay, private cookieService: CookieService, private roleService: RoleService) {
    this.roleService.role$.pipe(untilDestroyed(this)).subscribe((r) => (this.role = r));
  }

  ngOnInit(): void {
    this.carrierName = this.storage.get("SubjectName");
    this.bdoFilter.advancedQuery.additionalInfo = null;
  }

  load(observer, checkAll) {
    this.error = false;
    this.loading = true;
    this.cardService.getRealisingNowCards(this.bdoFilter.advancedQuery, this.paginator.pageIndex, this.paginator.pageSize).subscribe(
      (cards) => {
        observer.next(
          cards.items.map((m) => {
            const kpoCard = m as KpoCard;
            return {
              ...m,
              user: kpoCard ? cards.userAttributions[kpoCard.kpoId] : undefined,
              checked: checkAll,
            };
          })
        );
        this.paginator.length = cards.totalItemsCount;
        this.loading = false;
        this.error = false;
      },
      () => {
        this.error = true;
        this.loading = false;
        this.alertService.showMessage("Wystąpił błąd podczas ładowania listy", MessageType.Error);
      }
    );
  }

  handleRefresh(observer) {
    this.messagingService.messageReceived$.pipe(untilDestroyed(this)).subscribe((payload) => {
      this.alertService.showMessage(payload.data.body.replace("\r\n", " | "), MessageType.Notification);
      this.load(observer, false);
    });
  }

  handleQueryChanged(observer) {
    this.bdoFilter.advancedQueryTextChanged
      .pipe(debounceTime(800))
      .pipe(untilDestroyed(this))
      .subscribe((result: AdvancedFilterQuery) => {
        console.log("querychanged");
        this.load(observer, false);
      });

    this.bdoFilter.advancedQueryChanged.pipe(untilDestroyed(this)).subscribe((result: AdvancedFilterQuery) => {
      console.log("querychanged");
      this.load(observer, false);
    });
  }

  // @HostListener("window:focus", ["$event"])
  // onFocus(event: FocusEvent): void {
  //   this.reloadRequest.next();
  // }

  openDetailsDialog(card): void {
    this.dialog.open(KpoDetailsDialogComponent, {
      width: "950px",
      data: { model: card },
      panelClass: "dialog",
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });
  }

  getLastModificationDate(kpo: any) {
    if (kpo.creationDateTime === undefined) {
      kpo.creationDateTime = kpo.kpoLastModifiedAt;
    }
    return kpo.creationDateTime === undefined ? kpo.kpoLastModifiedAt : kpo.creationDateTime;
  }
}
