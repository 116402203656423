import { Component, ViewChild, ElementRef, OnInit, HostListener, NgZone, Inject, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { routing } from "./app.routing";
import { ChooseLocationDialogComponent } from "./components/dialogs/choose-location-dialog/choose-location-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { Configuration, ExtendedKpoService, UserService } from "../../api";
import { MessagingService } from "./services/messaging.service";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { AlertService, MessageType } from "./services/alert.service";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { Overlay } from "@angular/cdk/overlay";
import { CanDeactivateGuard } from "./guards/auth.guard";
import { Subscription } from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { RoleService } from "./services/role.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  public static Instance: AppComponent;
  public hasAdminRights = false;
  userName: string;
  menuRoutes = routing;
  role: string;
  loading = false;
  navbarOpen = true;
  scrollStrategy = this.overlay.scrollStrategies.block();
  subscription = new Subscription();
  place = "Wybierz siedzibę";
  windowWidth: number = window.innerWidth;
  constructor(
    public router: Router,
    public dialog: MatDialog,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private extendedKpoService: ExtendedKpoService,
    private angularFireMessaging: AngularFireMessaging,
    private messagingService: MessagingService,
    private userService: UserService,
    private alertService: AlertService,
    private overlay: Overlay,
    private deactivateGuard: CanDeactivateGuard,
    private roleService: RoleService,
    private cookieService: CookieService,
    private configuration: Configuration
  ) {
    AppComponent.Instance = this;
    const bearer = this.cookieService.get("bearer");
    if (bearer != undefined && bearer != "") this.configuration.apiKeys["Authorization"] = `Bearer ${bearer}`;
    console.log(bearer);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  ngOnInit(): void {
    this.roleService.role$.pipe(untilDestroyed(this)).subscribe((r) => (this.role = r));

    this.dialog.afterOpened.pipe(untilDestroyed(this)).subscribe((r) => {
      this.deactivateGuard.navigationAllowed = false;
    });

    this.angularFireMessaging.tokenChanges.pipe(untilDestroyed(this)).subscribe(
      (token) => {
        this.userService.addDeviceToken({ deviceToken: token }).subscribe(
          () => {},
          (error) => {}
        );
      },
      (error) => {}
    );

    this.messagingService.messageReceived$.pipe(untilDestroyed(this)).subscribe((message) => {
      this.alertService.showMessage(message.notification.body.replace("\r\n", " | "), MessageType.Notification);
    });
  }

  public showAdminOptions(show: boolean) {
    this.hasAdminRights = show;
  }

  filterRoutes(routes) {
    return routes?.filter((r) => r.data?.hidden === undefined || r.data?.hidden === false);
  }

  isOutsideApp() {
    return this.router.url === "/login" || this.router.url === "/register" || this.router.url === "/password-recovery" || this.router.url === "/subject-selection" || this.router.url === "/headquarter-selection" || this.router.url === "/role-selection" || this.router.url.startsWith("/landing-page");
  }

  isInsideApp() {
    return !this.isOutsideApp();
  }

  changePlaceDialog() {
    const dialogRef = this.dialog.open(ChooseLocationDialogComponent, {
      width: "850px",
      data: {},
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result != null) {
        this.place = result.addressHtml;
        this.extendedKpoService.getCompanyToken(result.eupId).subscribe((nres) => {
          this.cookieService.set("token", nres, 1, "/");
          this.cookieService.set("token", nres, { expires: 1, sameSite: "None", secure: true });
        });
      }
    });
  }

  shouldRenderItem(route) {
    return route.data.showInRoles == undefined || route.data.showInRoles.indexOf(this.role) != -1;
  }
}
