/**
 * BDOEX API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * Contact: mateusz.surma@mae.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteRecordCardV1KeoGeneratedListItem
 */
export interface WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteRecordCardV1KeoGeneratedListItem { 
    /**
     * Id wpisu Wytworzone odpady
     */
    keoGeneratedId?: string;
    /**
     * Nazwa instalacji
     */
    installationName?: string;
    /**
     * Masa wytworzonych odpadów: w związku z eksploatacją instalacji [Mg]
     */
    wasteMassInstallation?: number;
    /**
     * Masa wytworzonych odpadów: poza instalacją
     */
    wasteMassExcludingInstallation?: number;
    /**
     * wytwarzanie odpadów - w wyniku świadczenia usług (w rozumieniu art. 3 ust. 1 pkt 32 ustawy o odpadach) i/lub działalności w zakresie obiektów liniowych (w rozumieniu art. 3 pkt 3a ustawy - Prawo budowlane)
     */
    wasteFromServices?: boolean;
    /**
     * Utworzone przez użytkownika
     */
    createdByUser?: string;
    /**
     * Data wytworzenia
     */
    manufactureDate?: Date;
}