<h1 mat-dialog-title>Odpady przekazane</h1>
<mat-dialog-content>
  <mat-tab-group mat-align-tabs="start">
    <mat-tab label="Informacje zbiorcze">
      <mat-spinner *ngIf="details == undefined; else infoTemplate" style="margin: 40px auto"></mat-spinner>
      <ng-template #infoTemplate>
        <p>
          Łączna masa przekazanych odpadów w tonach [Mg]:
          {{ details?.sumOfWasteMass }}
        </p>
        <p>W tym w kraju w tonach [Mg]: {{ details?.countryWasteMass }}</p>
        <p>W tym poza granice RP w tonach [Mg]: {{ details?.abroadWasteMass }}</p>
      </ng-template>
    </mat-tab>
    <mat-tab label="Dane szczegółowe">
      <div style="padding: 5px">
        <mat-spinner *ngIf="details == undefined || loading; else detailsTemplate" style="margin: 40px auto"> </mat-spinner>
        <ng-template #detailsTemplate>
          <ng-container *ngFor="let item of details?.forwardedForwardedKeos?.items">
            <mat-accordion>
              <app-card-block [details]="item" [header]="">
                <div fxLayout="row wrap">
                  <app-card-block-field header="Data przekazania odpadów / Data rozpoczęcia transportu" *ngIf="item.wasteCollectionDate" fxFlex="100%">
                    {{ item.transportDate | date : "dd.MM.yyyy HH:mm" }}
                  </app-card-block-field>
                  <app-card-block-field header="Masa wytworzonych odpadów w tonach [Mg]" *ngIf="item.wasteMass" fxFlex="100%">
                    {{ item.wasteMass }}
                  </app-card-block-field>
                  <app-card-block-field header="Numer karty przekazania odpadów" *ngIf="item.cardNumberKpo" fxFlex="100%">
                    {{ item.kpoCardNumber }}
                  </app-card-block-field>
                  <app-card-block-field header="Numer karty przekazania odpadów komunalnych" *ngIf="item.kpokCardNumber" fxFlex="100%">
                    {{ item.kpokCardNumber }}
                  </app-card-block-field>
                  <app-card-block-field header="Informacja o sposobie przekazania odpadów" *ngIf="item.transferWay" fxFlex="100%">
                    {{ item.transferWay == "BASED_ON_KPO_CARD" ? "na podstawie kpo" : item.transferWay }}
                  </app-card-block-field>
                  <app-card-block-field header="Imię i nazwisko osoby sporządzającej" *ngIf="item.createdByUser" fxFlex="100%">
                    {{ item.createdByUser }}
                  </app-card-block-field>
                </div>
                <!-- <button mat-button color="primary" [matMenuTriggerFor]="menu"
                                    class="actions-menu">Akcje</button>
                                <mat-menu #menu="matMenu">
                                    <button (click)="openDetailsDialog(item)" mat-menu-item>Podgląd</button>
                                </mat-menu> -->
              </app-card-block>
            </mat-accordion>
          </ng-container>
        </ng-template>
        <mat-paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 30, 50]"></mat-paginator>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
