<mat-spinner *ngIf="loading" style="margin: 90px auto;"></mat-spinner>


<br />
<br />
<div [ngStyle]="{'display': (loading?'none':'inherit')}">

    <mat-card>
        <mat-card-header>
            <mat-card-title>Grupy i uprawnienia</mat-card-title>
            <mat-card-subtitle>Zarządzanie grupami użytkowników i uprawnieniami</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>

            <table mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef style="min-width: 100px">
                        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
                            <span style="width: 100px">&nbsp;</span>
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Nazwa </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}}</td>
                </ng-container>
                <ng-container matColumnDef="operations">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-form-field style="float:right;">
                            <input matInput autocomplete="off" (keyup)="applyFilter($event.target.value)" placeholder="Filtruj">
                        </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index;" style="text-align: right;">
                        <button mat-button color="primary" (click)="openDialog('view', element)">Podgląd</button>
                        <button mat-button color="primary" (click)="openDialog('edit', element)">Edytuj</button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>

            <div class="user-tools">

                <button mat-button color="warn" (click)="deleteSelected()" [disabled]="selection == null || selection.selected.length==0">Usuń</button>
                <button mat-button color="basic" [matMenuTriggerFor]="menuExport">Eksport</button>
                <mat-menu #menuExport="matMenu">
                        <button (click)="exportData()" mat-menu-item>Do pliku JSON</button>
                    </mat-menu>
      

                <button mat-button color="basic" (click)="openDialog('create')" style="float:right">Dodaj grupę</button>
            </div>
        </mat-card-content>
    </mat-card>
</div>