<button mat-raised-button style="margin: 200px calc(50% - 115px)" (click)="ngOnInit()" *ngIf="error && !loading">Ponów próbę</button>
<div>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Archiwum KPO</mat-card-title>
      <mat-card-subtitle>Podgląd archiwalnych kart</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <app-bdo-filter [allowedCardStatuses]="filterCardStatuses" initialStatus="TRANSPORT_CONFIRMATION"> </app-bdo-filter>

      <mat-spinner *ngIf="loading" style="margin: 90px auto"></mat-spinner>
      <button mat-raised-button style="margin: 90px auto" (click)="ngOnInit()" *ngIf="error && !loading; else results">Ponów próbę</button>
      <ng-template #results>
        <ng-container *ngFor="let item of filteredResults$ | async">
          <mat-accordion>
            <app-card-block [details]="item">
              <div fxLayout="row wrap">
                <app-card-block-field header="Numer karty" *ngIf="item.cardNumber" fxFlex="100%" fxFlex.gt-lg="50%"> {{ item.cardNumber }} </app-card-block-field>
                <app-card-block-field header="Kod odpadów" fxFlex="100%" fxFlex.gt-lg="50%">
                  {{ item.wasteCode }}
                </app-card-block-field>
                <app-card-block-field header="Przypisany kierowca" *ngIf="item.user" fxFlex="100%" fxFlex.gt-lg="50%"> {{ item.user.firstName }} {{ item.user.lastName }} </app-card-block-field>
                <app-card-block-field header="Numer rejestracyjny" fxFlex="100%" fxFlex.gt-lg="50%" *ngIf="item.vehicleRegNumber != undefined && item.vehicleRegNumber != ''">
                  {{ item.vehicleRegNumber }}
                </app-card-block-field>
                <!-- <app-card-block-field header="Data utworzenia/modyfikacji">
                {{getLastModificationDate(item) | date: 'dd.MM.yyyy HH:mm'}}
            </app-card-block-field> -->
                <app-card-block-field header="Status karty" fxFlex="100%" fxFlex.gt-lg="50%">
                  {{ item.cardStatus }}
                </app-card-block-field>
                <app-card-block-field header="Planowany transport" fxFlex="100%" fxFlex.gt-lg="50%">
                  {{ item.plannedTransportTime | date : "dd.MM.yyyy HH:mm" }}
                </app-card-block-field>
              </div>
              <button mat-button color="primary" [matMenuTriggerFor]="menu" class="actions-menu">Akcje</button>
              <mat-menu #menu="matMenu">
                <button (click)="openDetailsDialog(item)" mat-menu-item>Podgląd</button>
                <button (click)="openDriverSelectionDialog(item)" mat-menu-item *ngIf="role == 'CARRIER'">Zmień kierowcę</button>
                <button (click)="openConfirmationGenerationDialog(item)" *ngIf="item.cardStatusCodeName === 'APPROVED'" mat-menu-item>Wygeneruj potwierdzenie</button>
                <button (click)="downloadConfirmation(item)" *ngIf="item.cardStatusCodeName === 'CONFIRMATION_GENERATED'" mat-menu-item>Pobierz potwierdzenie</button>
                <button (click)="openCardConfirmationDialog(item)" *ngIf="item.cardStatusCodeName === 'CONFIRMATION_GENERATED' && item.receiverName === carrierName && role == 'CARRIER'" mat-menu-item>Potwierdź przejęcie</button>
                <button (click)="openCardRejectionDialog(item)" *ngIf="item.cardStatusCodeName === 'CONFIRMATION_GENERATED' && item.receiverName === carrierName && role == 'CARRIER'" mat-menu-item>Odrzuć</button>
                <button (click)="confirmTransport(item)" *ngIf="item.cardStatusCodeName === 'RECEIVE_CONFIRMATION' && role == 'CARRIER'" mat-menu-item>Potwierdź transport</button>
              </mat-menu>
            </app-card-block>
          </mat-accordion>
        </ng-container>
      </ng-template>
      <mat-paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 30, 50]"></mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
