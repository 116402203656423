<h2 mat-dialog-title>Wybierz kierowcę</h2>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Kierowca</mat-label>
    <mat-select [(value)]="driverId">
      <mat-option *ngFor="let driver of drivers" [value]="driver.id">
        {{ driver.lastName }} {{ driver.firstName }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button color="warn" (click)="dialogRef.close()">Anuluj</button>
  <button mat-button [disabled]="working" (click)="selectDriver(driverId)">
    Wybierz
  </button>
</div>
