<mat-selection-list (selectionChange)="daysSelectionChange($event)" #daysOfWeekList
class="day-list mat-elevation-z2">
<mat-grid-list cols="2" rowHeight="50px">
    <mat-grid-tile>
        <mat-list-option value="All">Wszystkie dni tygodnia</mat-list-option>
    </mat-grid-tile>
    <mat-grid-tile *ngFor="let day of daysOfWeek">
        <mat-list-option [value]="day.value">{{day.name}}</mat-list-option>
    </mat-grid-tile>
</mat-grid-list>
</mat-selection-list>