import { Component, OnInit } from '@angular/core';
import { UserService, GroupsService, UserDto } from '../../../../../api';
import { AlertService, MessageType } from '../../../services/alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit {
  constructor(
    private userService: UserService,
    private groupService: GroupsService,
    private alertService: AlertService,
    private router: Router
  ) {}

  code: string;
  newPassword: string;

  ngOnInit() {}

  onSubmit(form): void {
    this.userService
      .passwordRecovery({token: this.code, newPassword: this.newPassword})
      .subscribe(
        (result: any) => {
          this.alertService.showMessage(
            "Zmiana hasła przebiegła pomyślnie",
            MessageType.Info
          );
          this.router.navigateByUrl("/login");
        },
        error => {
          this.alertService.showMessage(
            error.error,
            MessageType.Error
          );
        }
      );
  }
}
