import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { CookieService } from "ngx-cookie-service";
import { RoleService } from "../../services/role.service";

export class AdvancedFilterQuery {
  wasteCode = "";
  cardNumber = "";
  vehicleRegistrationNumber = "";
  cardStatusCodeName = "";
  senderName = "";
  year = "";
  role: "SENDER" | "CARRIER" = "CARRIER";
  additionalInfo = null;
  transportDateRange = {
    from: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * -7),
    to: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7),
  };
}

@UntilDestroy()
@Component({
  selector: "app-bdo-filter",
  templateUrl: "./bdo-filter.component.html",
  styleUrls: ["./bdo-filter.component.scss"],
})
export class BdoFilterComponent implements OnInit, OnChanges {
  public advancedQuery = new AdvancedFilterQuery();
  generalQuery = "";

  @Input() showRole = false;
  @Input() allowedCardStatuses: { label: string; value: string }[];
  @Input() showTransportDateRange = true;
  @Input() initialStatus = "";

  @Output()
  public advancedQueryTextChanged = new EventEmitter<AdvancedFilterQuery>();
  @Output()
  public advancedQueryChanged = new EventEmitter<AdvancedFilterQuery>();
  @Output() public generalQueryChanged = new EventEmitter<string>();

  constructor(private cookieService: CookieService, private roleService: RoleService) {}

  ngOnInit(): void {
    if (this.initialStatus !== "") {
      this.advancedQuery.cardStatusCodeName = this.initialStatus;
      this.advancedQueryChanged.emit(this.advancedQuery);
    }
    this.advancedQuery.additionalInfo = null;
    this.roleService.role$.pipe(untilDestroyed(this)).subscribe((r) => (this.advancedQuery.role = r as "SENDER" | "CARRIER"));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.initialStatus != undefined) {
      this.advancedQuery.cardStatusCodeName = changes.initialStatus.currentValue;
      this.advancedQueryChanged.emit(this.advancedQuery);
    }
  }

  queryChanged($event) {
    this.advancedQueryTextChanged.emit(this.advancedQuery);
  }

  selectChanged($event) {
    this.advancedQueryChanged.emit(this.advancedQuery);
  }

  applyFilter($event) {
    this.generalQueryChanged.emit(this.generalQuery);
  }
}
