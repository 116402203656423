/**
 * BDOEX API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * Contact: mateusz.surma@mae.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApproveAndGeneratePlannedCardRequest } from '../model/approveAndGeneratePlannedCardRequest';
import { BDOApiClientModelsCompanyEupDto } from '../model/bDOApiClientModelsCompanyEupDto';
import { BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto } from '../model/bdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto';
import { BdoServiceModelsEnumerationsCompanyType } from '../model/bdoServiceModelsEnumerationsCompanyType';
import { CardDetailsResponse } from '../model/cardDetailsResponse';
import { ChangesRegisterEntityDto } from '../model/changesRegisterEntityDto';
import { CorrectRequest } from '../model/correctRequest';
import { DraftCardDto } from '../model/draftCardDto';
import { ElevatedCarrierKpoCardDto } from '../model/elevatedCarrierKpoCardDto';
import { ElevatedSenderKpoCardDto } from '../model/elevatedSenderKpoCardDto';
import { SearchRequestDto } from '../model/searchRequestDto';
import { SearchResultDto } from '../model/searchResultDto';
import { UserDto } from '../model/userDto';
import { WasteRegisterPublicApiApiModelsElasticsearchCompanyEs } from '../model/wasteRegisterPublicApiApiModelsElasticsearchCompanyEs';
import { WasteRegisterPublicApiApiModelsElasticsearchEupEs } from '../model/wasteRegisterPublicApiApiModelsElasticsearchEupEs';
import { WasteRegisterPublicApiApiModelsElasticsearchTerytEs } from '../model/wasteRegisterPublicApiApiModelsElasticsearchTerytEs';
import { WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1PlanKpoRequest } from '../model/wasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1PlanKpoRequest';
import { WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1UpdateApprovedKpoAndGenerateConfirmationAsCarrierRequest } from '../model/wasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1UpdateApprovedKpoAndGenerateConfirmationAsCarrierRequest';
import { WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto } from '../model/wasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto';
import { WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ApprovedKpoDto } from '../model/wasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ApprovedKpoDto';
import { WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1PlannedKpoDto } from '../model/wasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1PlannedKpoDto';
import { WithdrawRequest } from '../model/withdrawRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ExtendedKpoService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param registrationNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addAsApproved(body?: DraftCardDto, registrationNumber?: string, observe?: 'body', reportProgress?: boolean): Observable<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ApprovedKpoDto>;
    public addAsApproved(body?: DraftCardDto, registrationNumber?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ApprovedKpoDto>>;
    public addAsApproved(body?: DraftCardDto, registrationNumber?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ApprovedKpoDto>>;
    public addAsApproved(body?: DraftCardDto, registrationNumber?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (registrationNumber !== undefined && registrationNumber !== null) {
            queryParameters = queryParameters.set('registrationNumber', <any>registrationNumber);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ApprovedKpoDto>('post',`${this.basePath}/api/ExtendedKpo/Carrier/ApprovedCard`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param registrationNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addAsPlanned(body?: DraftCardDto, registrationNumber?: string, observe?: 'body', reportProgress?: boolean): Observable<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1PlannedKpoDto>;
    public addAsPlanned(body?: DraftCardDto, registrationNumber?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1PlannedKpoDto>>;
    public addAsPlanned(body?: DraftCardDto, registrationNumber?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1PlannedKpoDto>>;
    public addAsPlanned(body?: DraftCardDto, registrationNumber?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (registrationNumber !== undefined && registrationNumber !== null) {
            queryParameters = queryParameters.set('registrationNumber', <any>registrationNumber);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1PlannedKpoDto>('post',`${this.basePath}/api/ExtendedKpo/Carrier/PlannedCard`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addKpoAsCarrier(body?: WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1PlanKpoRequest, observe?: 'body', reportProgress?: boolean): Observable<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1PlannedKpoDto>;
    public addKpoAsCarrier(body?: WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1PlanKpoRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1PlannedKpoDto>>;
    public addKpoAsCarrier(body?: WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1PlanKpoRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1PlannedKpoDto>>;
    public addKpoAsCarrier(body?: WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1PlanKpoRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1PlannedKpoDto>('post',`${this.basePath}/api/ExtendedKpo/Carrier/Kpo`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvePlanned(kpoId: string, observe?: 'body', reportProgress?: boolean): Observable<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1PlannedKpoDto>;
    public approvePlanned(kpoId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1PlannedKpoDto>>;
    public approvePlanned(kpoId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1PlannedKpoDto>>;
    public approvePlanned(kpoId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling approvePlanned.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1PlannedKpoDto>('patch',`${this.basePath}/api/ExtendedKpo/Carrier/PlannedCard/${encodeURIComponent(String(kpoId))}/Approve`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param body 
     * @param role 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvePlannedAndGenerateConfirmation(kpoId: string, body?: ApproveAndGeneratePlannedCardRequest, role?: BdoServiceModelsEnumerationsCompanyType, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public approvePlannedAndGenerateConfirmation(kpoId: string, body?: ApproveAndGeneratePlannedCardRequest, role?: BdoServiceModelsEnumerationsCompanyType, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public approvePlannedAndGenerateConfirmation(kpoId: string, body?: ApproveAndGeneratePlannedCardRequest, role?: BdoServiceModelsEnumerationsCompanyType, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public approvePlannedAndGenerateConfirmation(kpoId: string, body?: ApproveAndGeneratePlannedCardRequest, role?: BdoServiceModelsEnumerationsCompanyType, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling approvePlannedAndGenerateConfirmation.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (role !== undefined && role !== null) {
            queryParameters = queryParameters.set('role', <any>role);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('patch',`${this.basePath}/api/ExtendedKpo/${encodeURIComponent(String(kpoId))}/ApproveAndGenerateConfirmation`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param body 
     * @param statusCode 
     * @param companyId 
     * @param eupId 
     * @param changeKpo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assignDriver(kpoId: string, body?: number, statusCode?: string, companyId?: string, eupId?: string, changeKpo?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public assignDriver(kpoId: string, body?: number, statusCode?: string, companyId?: string, eupId?: string, changeKpo?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public assignDriver(kpoId: string, body?: number, statusCode?: string, companyId?: string, eupId?: string, changeKpo?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public assignDriver(kpoId: string, body?: number, statusCode?: string, companyId?: string, eupId?: string, changeKpo?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling assignDriver.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (statusCode !== undefined && statusCode !== null) {
            queryParameters = queryParameters.set('statusCode', <any>statusCode);
        }
        if (companyId !== undefined && companyId !== null) {
            queryParameters = queryParameters.set('companyId', <any>companyId);
        }
        if (eupId !== undefined && eupId !== null) {
            queryParameters = queryParameters.set('eupId', <any>eupId);
        }
        if (changeKpo !== undefined && changeKpo !== null) {
            queryParameters = queryParameters.set('changeKpo', <any>changeKpo);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/ExtendedKpo/Carrier/Kpo/${encodeURIComponent(String(kpoId))}/Driver`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param receiveConfirmationUser 
     * @param correctedWasteMass 
     * @param remarks 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assignReceiveConfirmation(kpoId: string, receiveConfirmationUser?: string, correctedWasteMass?: number, remarks?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public assignReceiveConfirmation(kpoId: string, receiveConfirmationUser?: string, correctedWasteMass?: number, remarks?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public assignReceiveConfirmation(kpoId: string, receiveConfirmationUser?: string, correctedWasteMass?: number, remarks?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public assignReceiveConfirmation(kpoId: string, receiveConfirmationUser?: string, correctedWasteMass?: number, remarks?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling assignReceiveConfirmation.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (receiveConfirmationUser !== undefined && receiveConfirmationUser !== null) {
            queryParameters = queryParameters.set('receiveConfirmationUser', <any>receiveConfirmationUser);
        }
        if (correctedWasteMass !== undefined && correctedWasteMass !== null) {
            queryParameters = queryParameters.set('correctedWasteMass', <any>correctedWasteMass);
        }
        if (remarks !== undefined && remarks !== null) {
            queryParameters = queryParameters.set('remarks', <any>remarks);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/ExtendedKpo/Carrier/Kpo/${encodeURIComponent(String(kpoId))}/ReceiveConfirmation`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public confirmTransport(kpoId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public confirmTransport(kpoId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public confirmTransport(kpoId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public confirmTransport(kpoId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling confirmTransport.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/ExtendedKpo/Carrier/Kpo/${encodeURIComponent(String(kpoId))}/ConfirmTransport`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public confirmTransports(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public confirmTransports(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public confirmTransports(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public confirmTransports(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/ExtendedKpo/Carrier/ConfirmTransports`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadConfirmation(kpoId: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public downloadConfirmation(kpoId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public downloadConfirmation(kpoId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public downloadConfirmation(kpoId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling downloadConfirmation.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/api/ExtendedKpo/Carrier/Kpo/${encodeURIComponent(String(kpoId))}/DownloadConfirmation`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param body 
     * @param role 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateConfirmation(kpoId: string, body?: WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1UpdateApprovedKpoAndGenerateConfirmationAsCarrierRequest, role?: BdoServiceModelsEnumerationsCompanyType, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public generateConfirmation(kpoId: string, body?: WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1UpdateApprovedKpoAndGenerateConfirmationAsCarrierRequest, role?: BdoServiceModelsEnumerationsCompanyType, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public generateConfirmation(kpoId: string, body?: WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1UpdateApprovedKpoAndGenerateConfirmationAsCarrierRequest, role?: BdoServiceModelsEnumerationsCompanyType, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public generateConfirmation(kpoId: string, body?: WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1UpdateApprovedKpoAndGenerateConfirmationAsCarrierRequest, role?: BdoServiceModelsEnumerationsCompanyType, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling generateConfirmation.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (role !== undefined && role !== null) {
            queryParameters = queryParameters.set('role', <any>role);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/ExtendedKpo/Carrier/Kpo/${encodeURIComponent(String(kpoId))}/GenerateConfirmation`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAssignedDriver(kpoId: string, observe?: 'body', reportProgress?: boolean): Observable<UserDto>;
    public getAssignedDriver(kpoId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserDto>>;
    public getAssignedDriver(kpoId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserDto>>;
    public getAssignedDriver(kpoId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling getAssignedDriver.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserDto>('get',`${this.basePath}/api/ExtendedKpo/Carrier/Kpo/${encodeURIComponent(String(kpoId))}/Driver`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChangesRegister(kpoId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ChangesRegisterEntityDto>>;
    public getChangesRegister(kpoId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChangesRegisterEntityDto>>>;
    public getChangesRegister(kpoId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChangesRegisterEntityDto>>>;
    public getChangesRegister(kpoId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling getChangesRegister.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ChangesRegisterEntityDto>>('get',`${this.basePath}/api/ExtendedKpo/Carrier/Kpo/${encodeURIComponent(String(kpoId))}/ChangesRegister`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param companyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompany(companyId: string, observe?: 'body', reportProgress?: boolean): Observable<WasteRegisterPublicApiApiModelsElasticsearchCompanyEs>;
    public getCompany(companyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WasteRegisterPublicApiApiModelsElasticsearchCompanyEs>>;
    public getCompany(companyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WasteRegisterPublicApiApiModelsElasticsearchCompanyEs>>;
    public getCompany(companyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompany.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WasteRegisterPublicApiApiModelsElasticsearchCompanyEs>('get',`${this.basePath}/api/ExtendedKpo/Company/${encodeURIComponent(String(companyId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param eupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyEup(eupId: string, observe?: 'body', reportProgress?: boolean): Observable<WasteRegisterPublicApiApiModelsElasticsearchEupEs>;
    public getCompanyEup(eupId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WasteRegisterPublicApiApiModelsElasticsearchEupEs>>;
    public getCompanyEup(eupId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WasteRegisterPublicApiApiModelsElasticsearchEupEs>>;
    public getCompanyEup(eupId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (eupId === null || eupId === undefined) {
            throw new Error('Required parameter eupId was null or undefined when calling getCompanyEup.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WasteRegisterPublicApiApiModelsElasticsearchEupEs>('get',`${this.basePath}/api/ExtendedKpo/Company/Eup/${encodeURIComponent(String(eupId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param companyId 
     * @param query 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyEups(companyId: string, query?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<WasteRegisterPublicApiApiModelsElasticsearchEupEs>>;
    public getCompanyEups(companyId: string, query?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WasteRegisterPublicApiApiModelsElasticsearchEupEs>>>;
    public getCompanyEups(companyId: string, query?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WasteRegisterPublicApiApiModelsElasticsearchEupEs>>>;
    public getCompanyEups(companyId: string, query?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyEups.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<WasteRegisterPublicApiApiModelsElasticsearchEupEs>>('get',`${this.basePath}/api/ExtendedKpo/Company/${encodeURIComponent(String(companyId))}/Eup`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyHeadquarters(observe?: 'body', reportProgress?: boolean): Observable<Array<BDOApiClientModelsCompanyEupDto>>;
    public getCompanyHeadquarters(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BDOApiClientModelsCompanyEupDto>>>;
    public getCompanyHeadquarters(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BDOApiClientModelsCompanyEupDto>>>;
    public getCompanyHeadquarters(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<BDOApiClientModelsCompanyEupDto>>('get',`${this.basePath}/api/ExtendedKpo/Headquarters`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param eup 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyToken(eup?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getCompanyToken(eup?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getCompanyToken(eup?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getCompanyToken(eup?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (eup !== undefined && eup !== null) {
            queryParameters = queryParameters.set('eup', <any>eup);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/api/ExtendedKpo/Token`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param eupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEupDetails(eupId: string, observe?: 'body', reportProgress?: boolean): Observable<WasteRegisterPublicApiApiModelsElasticsearchEupEs>;
    public getEupDetails(eupId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WasteRegisterPublicApiApiModelsElasticsearchEupEs>>;
    public getEupDetails(eupId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WasteRegisterPublicApiApiModelsElasticsearchEupEs>>;
    public getEupDetails(eupId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (eupId === null || eupId === undefined) {
            throw new Error('Required parameter eupId was null or undefined when calling getEupDetails.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WasteRegisterPublicApiApiModelsElasticsearchEupEs>('get',`${this.basePath}/api/ExtendedKpo/Carrier/Kpo/Eup/${encodeURIComponent(String(eupId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param statusCode 
     * @param token 
     * @param index 
     * @param wasteCode 
     * @param cardNumber 
     * @param registrationNumber 
     * @param itemsPerPage 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getKpoAsCarrier(statusCode?: string, token?: string, index?: number, wasteCode?: string, cardNumber?: string, registrationNumber?: string, itemsPerPage?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ElevatedCarrierKpoCardDto>>;
    public getKpoAsCarrier(statusCode?: string, token?: string, index?: number, wasteCode?: string, cardNumber?: string, registrationNumber?: string, itemsPerPage?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ElevatedCarrierKpoCardDto>>>;
    public getKpoAsCarrier(statusCode?: string, token?: string, index?: number, wasteCode?: string, cardNumber?: string, registrationNumber?: string, itemsPerPage?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ElevatedCarrierKpoCardDto>>>;
    public getKpoAsCarrier(statusCode?: string, token?: string, index?: number, wasteCode?: string, cardNumber?: string, registrationNumber?: string, itemsPerPage?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (statusCode !== undefined && statusCode !== null) {
            queryParameters = queryParameters.set('statusCode', <any>statusCode);
        }
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }
        if (index !== undefined && index !== null) {
            queryParameters = queryParameters.set('index', <any>index);
        }
        if (wasteCode !== undefined && wasteCode !== null) {
            queryParameters = queryParameters.set('wasteCode', <any>wasteCode);
        }
        if (cardNumber !== undefined && cardNumber !== null) {
            queryParameters = queryParameters.set('cardNumber', <any>cardNumber);
        }
        if (registrationNumber !== undefined && registrationNumber !== null) {
            queryParameters = queryParameters.set('registrationNumber', <any>registrationNumber);
        }
        if (itemsPerPage !== undefined && itemsPerPage !== null) {
            queryParameters = queryParameters.set('itemsPerPage', <any>itemsPerPage);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ElevatedCarrierKpoCardDto>>('get',`${this.basePath}/api/ExtendedKpo/Carrier/Kpo`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param statusCode 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getKpoAsSender(statusCode?: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ElevatedSenderKpoCardDto>>;
    public getKpoAsSender(statusCode?: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ElevatedSenderKpoCardDto>>>;
    public getKpoAsSender(statusCode?: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ElevatedSenderKpoCardDto>>>;
    public getKpoAsSender(statusCode?: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (statusCode !== undefined && statusCode !== null) {
            queryParameters = queryParameters.set('statusCode', <any>statusCode);
        }
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ElevatedSenderKpoCardDto>>('get',`${this.basePath}/api/ExtendedKpo/Sender/Kpo`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param statusCode 
     * @param role 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getKpoDetails(kpoId: string, statusCode?: string, role?: BdoServiceModelsEnumerationsCompanyType, observe?: 'body', reportProgress?: boolean): Observable<CardDetailsResponse>;
    public getKpoDetails(kpoId: string, statusCode?: string, role?: BdoServiceModelsEnumerationsCompanyType, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CardDetailsResponse>>;
    public getKpoDetails(kpoId: string, statusCode?: string, role?: BdoServiceModelsEnumerationsCompanyType, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CardDetailsResponse>>;
    public getKpoDetails(kpoId: string, statusCode?: string, role?: BdoServiceModelsEnumerationsCompanyType, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling getKpoDetails.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (statusCode !== undefined && statusCode !== null) {
            queryParameters = queryParameters.set('statusCode', <any>statusCode);
        }
        if (role !== undefined && role !== null) {
            queryParameters = queryParameters.set('role', <any>role);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CardDetailsResponse>('get',`${this.basePath}/api/ExtendedKpo/Carrier/Kpo/${encodeURIComponent(String(kpoId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSelfCompany(observe?: 'body', reportProgress?: boolean): Observable<WasteRegisterPublicApiApiModelsElasticsearchCompanyEs>;
    public getSelfCompany(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WasteRegisterPublicApiApiModelsElasticsearchCompanyEs>>;
    public getSelfCompany(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WasteRegisterPublicApiApiModelsElasticsearchCompanyEs>>;
    public getSelfCompany(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WasteRegisterPublicApiApiModelsElasticsearchCompanyEs>('get',`${this.basePath}/api/ExtendedKpo/Carrier/Company`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param query 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTeryts(query?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<WasteRegisterPublicApiApiModelsElasticsearchTerytEs>>;
    public getTeryts(query?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WasteRegisterPublicApiApiModelsElasticsearchTerytEs>>>;
    public getTeryts(query?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WasteRegisterPublicApiApiModelsElasticsearchTerytEs>>>;
    public getTeryts(query?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<WasteRegisterPublicApiApiModelsElasticsearchTerytEs>>('get',`${this.basePath}/api/ExtendedKpo/Carrier/Teryt`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTransportConfirmedCardReport(kpoIds?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getTransportConfirmedCardReport(kpoIds?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getTransportConfirmedCardReport(kpoIds?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getTransportConfirmedCardReport(kpoIds?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (kpoIds) {
            kpoIds.forEach((element) => {
                queryParameters = queryParameters.append('kpoIds', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Blob>(`${this.basePath}/api/ExtendedKpo/Carrier/TransportConfirmedReport`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                responseType: 'blob'
            } as any
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWasteCodeExtendedDescriptions(observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: string; }>;
    public getWasteCodeExtendedDescriptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: string; }>>;
    public getWasteCodeExtendedDescriptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: string; }>>;
    public getWasteCodeExtendedDescriptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<{ [key: string]: string; }>('get',`${this.basePath}/api/ExtendedKpo/Carrier/WasteCodeExtended`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param query 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWasteCodes(query?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto>>;
    public getWasteCodes(query?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto>>>;
    public getWasteCodes(query?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto>>>;
    public getWasteCodes(query?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto>>('get',`${this.basePath}/api/ExtendedKpo/Carrier/WasteCode`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param query 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWasteProcess(query?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto>>;
    public getWasteProcess(query?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto>>>;
    public getWasteProcess(query?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto>>>;
    public getWasteProcess(query?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto>>('get',`${this.basePath}/api/ExtendedKpo/Carrier/WasteProcess`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWasteProcessById(id: number, observe?: 'body', reportProgress?: boolean): Observable<BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto>;
    public getWasteProcessById(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto>>;
    public getWasteProcessById(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto>>;
    public getWasteProcessById(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getWasteProcessById.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto>('get',`${this.basePath}/api/ExtendedKpo/Carrier/WasteProcess/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param reason 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rejectTransport(kpoId: string, reason?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public rejectTransport(kpoId: string, reason?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public rejectTransport(kpoId: string, reason?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public rejectTransport(kpoId: string, reason?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling rejectTransport.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (reason !== undefined && reason !== null) {
            queryParameters = queryParameters.set('reason', <any>reason);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/ExtendedKpo/Carrier/Kpo/${encodeURIComponent(String(kpoId))}/RejectTransport`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeCard(kpoId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public removeCard(kpoId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public removeCard(kpoId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public removeCard(kpoId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling removeCard.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/ExtendedKpo/${encodeURIComponent(String(kpoId))}/Remove`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public revise(kpoId: string, body?: CorrectRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public revise(kpoId: string, body?: CorrectRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public revise(kpoId: string, body?: CorrectRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public revise(kpoId: string, body?: CorrectRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling revise.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/ExtendedKpo/Kpo/${encodeURIComponent(String(kpoId))}/Revise`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param query 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchCompany(query?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Array<WasteRegisterPublicApiApiModelsElasticsearchCompanyEs>>>;
    public searchCompany(query?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Array<WasteRegisterPublicApiApiModelsElasticsearchCompanyEs>>>>;
    public searchCompany(query?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Array<WasteRegisterPublicApiApiModelsElasticsearchCompanyEs>>>>;
    public searchCompany(query?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Array<WasteRegisterPublicApiApiModelsElasticsearchCompanyEs>>>('get',`${this.basePath}/api/ExtendedKpo/Company/Search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param role 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchKpo(body?: SearchRequestDto, role?: string, observe?: 'body', reportProgress?: boolean): Observable<SearchResultDto>;
    public searchKpo(body?: SearchRequestDto, role?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SearchResultDto>>;
    public searchKpo(body?: SearchRequestDto, role?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SearchResultDto>>;
    public searchKpo(body?: SearchRequestDto, role?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (role !== undefined && role !== null) {
            queryParameters = queryParameters.set('role', <any>role);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SearchResultDto>('post',`${this.basePath}/api/ExtendedKpo/Carrier/SearchKpo`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param body 
     * @param registrationNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePlanned(kpoId: string, body?: DraftCardDto, registrationNumber?: string, observe?: 'body', reportProgress?: boolean): Observable<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1PlannedKpoDto>;
    public updatePlanned(kpoId: string, body?: DraftCardDto, registrationNumber?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1PlannedKpoDto>>;
    public updatePlanned(kpoId: string, body?: DraftCardDto, registrationNumber?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1PlannedKpoDto>>;
    public updatePlanned(kpoId: string, body?: DraftCardDto, registrationNumber?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling updatePlanned.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (registrationNumber !== undefined && registrationNumber !== null) {
            queryParameters = queryParameters.set('registrationNumber', <any>registrationNumber);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1PlannedKpoDto>('put',`${this.basePath}/api/ExtendedKpo/Carrier/PlannedCard/${encodeURIComponent(String(kpoId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public withdraw(kpoId: string, body?: WithdrawRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public withdraw(kpoId: string, body?: WithdrawRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public withdraw(kpoId: string, body?: WithdrawRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public withdraw(kpoId: string, body?: WithdrawRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling withdraw.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/ExtendedKpo/Kpo/${encodeURIComponent(String(kpoId))}/Withdraw`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
