import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import { DataSource } from "@angular/cdk/table";
import { Observable, of, forkJoin } from "rxjs";
import {
  ExtendedKpoService,
  ElevatedCarrierKpoCardDto,
  SubjectDto,
  SubjectAuthenticationService,
  UsersService,
  UserDto,
} from "../../../../../api";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { DatePipe } from "@angular/common";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { SubjectWizardComponent } from "../users/subject-wizard/subject-wizard.component";
import { AddSubjectDialogComponent } from "../login/subject-list/add-subject-dialog/add-subject-dialog.component";
import { AlertService, MessageType } from "../../../services/alert.service";
import { AddWhitelistSubjectDialogComponent } from "./add-whitelist-subject-dialog/add-whitelist-subject-dialog.component";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { ConfirmDialogComponent } from "../../dialogs/confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-whitelist",
  templateUrl: "./whitelist.component.html",
  styleUrls: ["./whitelist.component.scss"],
})
export class WhitelistComponent implements OnInit {
  displayedColumns = ["name", "active", "action"];
  dataSource = new MatTableDataSource<any>();
  loading = true;

  subjects: SubjectDto[];
  users: UserDto[];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private extendedKpoService: ExtendedKpoService,
    private usersService: UsersService,
    private subjectAuthenticationService: SubjectAuthenticationService,
    private alertService: AlertService,
    public datepipe: DatePipe,
    public dialog: MatDialog
  ) {}

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit(): void {
    this.reload();
  }

  reload() {
    // forkJoin([this.groupsService.getAll(), this.subjectAuthenticationService.getSubjects()])
    // .subscribe(
    //   result => {
    //     const group = result[0].find(g => g.name === "Transportujący");
    //       this.usersService.getAll(group.id).subscribe(users => {
    //         result[1].map(subject => {
    //           return { user: users.find(u => u.), subject };
    //         });
    //         this.subjects = ,
    //         this.dataSource = new MatTableDataSource(this.subjects);
    //         this.dataSource.paginator = this.paginator;
    //         this.dataSource.sort = this.sort;
    //         this.loading = false;
    //     });
    //   },
    //   error => this.alertService.showMessage(
    //       "Błąd podczas pobierania listy podmiotów",
    //       MessageType.Error))
    // );
    forkJoin([
      this.usersService.getAll(),
      this.subjectAuthenticationService.getSubjects(null, true, true),
    ]).subscribe(
      (result) => {
        this.users = result[0];
        this.subjects = result[1];
        this.dataSource = new MatTableDataSource(this.subjects);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;
      },
      (error) =>
        this.alertService.showMessage(
          "Błąd podczas pobierania listy podmiotów",
          MessageType.Error
        )
    );
  }

  getUser(subject) {
    const u = this.users.find((u) => u.id === subject.userId);
    if (u == null) return "(Brak przypisanego użytkownika)";
    return u.firstName + " " + u.lastName;
  }

  switch($event: MatSlideToggleChange, element) {
    $event.source.disabled = true;
    this.subjectAuthenticationService
      .toggleActive(element.id, $event.checked)
      .subscribe(
        (result) => {
          $event.source.disabled = false;
        },
        (error) => {
          $event.source.checked = !$event.source.checked;
          $event.source.disabled = false;
        }
      );
  }

  removeSubject(subject) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "350px",
      data: { message: "Czy na pewno chcesz usunąć ten podmiot?" },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.subjectAuthenticationService.removeSubject(subject.id).subscribe(
          (res) => {
            this.alertService.showMessage(
              "Pomyślnie usunięto podmiot z listy",
              MessageType.Info
            );
            this.reload();
          },
          (error) =>
            this.alertService.showMessage(
              "Wystąpił błąd podczas usuwania podmiotu",
              MessageType.Error
            )
        );
      }
    });
  }

  getTime(date?: Date) {
    return date != null ? date.getTime() : 0;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(AddWhitelistSubjectDialogComponent, {
      width: "950px",
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.loading = true;
      this.reload();
    });
  }
}
