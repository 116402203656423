import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ExtendedKpoService } from "../../../../../api";
import { AlertService, MessageType } from "../../../services/alert.service";

@Component({
  selector: "app-choose-location-dialog",
  templateUrl: "./choose-location-dialog.component.html",
  styleUrls: ["./choose-location-dialog.component.scss"],
})
export class ChooseLocationDialogComponent implements OnInit {
  displayedColumns: string[] = ["name", "radio"];
  dataSource = null;
  locations = [];

  constructor(
    public dialogRef: MatDialogRef<ChooseLocationDialogComponent>,
    private extendedKpoService: ExtendedKpoService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  set(val): void {
    this.dialogRef.close(val);
  }

  ngOnInit(): void {
    this.extendedKpoService.getCompanyHeadquarters().subscribe(
      (result) => {
        this.dataSource = result;
      },
      (error) => {
        this.alertService.showMessage(
          "Niepoprawny klucz API",
          MessageType.Error
        );
      }
    );
  }
}
