<mat-card cdk-scrollable>
    <mat-card-header>
        <mat-card-title> Ewidencja odpadów </mat-card-title>
        <mat-card-subtitle> Wykaz zewidencjonowanych odpadów </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>

        <app-bdo-filter showTransportDateRange="false"> </app-bdo-filter>

        <mat-spinner *ngIf="loading" style="margin: 90px auto;"></mat-spinner>

        <div [style.display]="loading ? 'none' : 'block'">
            <ng-container *ngFor="let item of filteredResults$ | async">
                <mat-accordion>
                    <app-card-block [details]="item" [header]="item.wasteCode">
                        <div fxLayout="row wrap">
                            <app-card-block-field header="Numer KEO" fxFlex="50%" fxFlex.gt-lg="50%">
                                {{item.cardNumber}}
                            </app-card-block-field>
                            <app-card-block-field header="Kod ex" fxFlex="50%" fxFlex.gt-lg="50%">
                                {{item.wasteCodeExtended === true ? "Tak" : "Nie"}}
                            </app-card-block-field>
                            <app-card-block-field header="Sporządzający" fxFlex="50%" fxFlex.gt-lg="50%">
                                {{item.createdByUser}}
                            </app-card-block-field>
                            <app-card-block-field header="Utrata statusu kodu niebezpiecznego" fxFlex="50%" fxFlex.gt-lg="50%">
                                {{item.hazardousWasteReclassification === true ? "Tak" : "Nie"}}
                            </app-card-block-field>



                            <!-- <app-card-block-field header="Data przekazania" fxFlex="50%" fxFlex.gt-lg="50%">
                                {{item.wasteTransferCardDetails.transportConfirmationTime}}
                            </app-card-block-field> -->
                            <!-- <app-card-block-field header="Nazwa przejmującego"
                                *ngIf="item.wasteTransferCard.receiverName" fxFlex="50%" fxFlex.gt-lg="50%">
                                {{item.wasteTransferCard.receiverName}}
                            </app-card-block-field> -->


                            <!-- <app-card-block-field header="Masa odpadów" *ngIf="item.wasteTransferCard.wasteMass"
                                fxFlex="50%" fxFlex.gt-lg="50%">
                                {{item.wasteTransferCard.wasteMass}}
                            </app-card-block-field> -->
                        </div>
                        <button mat-button color="primary" [matMenuTriggerFor]="menu" class="actions-menu">Akcje</button>
                        <mat-menu #menu="matMenu">
                            <button (click)="openDetailsDialog(item)" mat-menu-item>Podgląd</button>
                            <button (click)="openFiledGeneratedWasteRecordCards(item)" mat-menu-item>Odpady
                                wytworzone</button>
                            <button (click)="openFiledCollectedWasteRecordCards(item)" mat-menu-item>Odpady
                                przyjęte</button>
                            <button (click)="openFiledForwardedWasteRecordCards(item)" mat-menu-item>Odpady
                                przekazane</button>
                        </mat-menu>
                    </app-card-block>
                </mat-accordion>
            </ng-container>
        </div>
        <mat-paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 30, 50]"></mat-paginator>
    </mat-card-content>

</mat-card>