/**
 * BDOEX API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * Contact: mateusz.surma@mae.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * WasteRegisterPublicApiApiModelsElasticsearchEupEs
 */
export interface WasteRegisterPublicApiApiModelsElasticsearchEupEs { 
    /**
     * Id miejsca prowadzenia działalności
     */
    eupId?: string;
    /**
     * Id podmiotu
     */
    companyId?: string;
    /**
     * Numer rejestrowy podmiotu
     */
    registrationNumber?: string;
    /**
     * Nazwa miejsca prowadzenia działalności
     */
    name?: string;
    /**
     * Adres
     */
    address?: string;
    /**
     * Numer budynku
     */
    buildingNumber?: string;
    /**
     * Numer lokalu
     */
    localNumber?: string;
    /**
     * Kod pocztowy
     */
    postalCode?: string;
    /**
     * Teryt
     */
    teryt?: string;
}