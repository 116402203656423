<body>
  <mat-toolbar class="mat-elevation-z8" *ngIf="isInsideApp()" fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="start top" fxLayoutGap.gt-sm="10px">
    <img mat-card-image src="/assets/logo.png" fxHide.lt-md />

    <app-person-small-info></app-person-small-info>
  </mat-toolbar>
  <mat-drawer-container class="container" [class.full-height]="isOutsideApp()" autosize>
    <mat-drawer #drawer class="sidenav" mode="side" [opened]="isInsideApp() && navbarOpen">
      <mat-nav-list>
        <div *ngFor="let route of menuRoutes">
          <ng-container *ngIf="route.data?.adminOnly !== true || hasAdminRights">
            <h3 mat-subheader *ngIf="route.data != null" class="menu-subheader">
              {{ route.data?.title }}
            </h3>
            <ng-container *ngFor="let childRoute of filterRoutes(route.children)">
              <a class="menu-item" #rla="routerLinkActive" [routerLink]="route.path + '/' + childRoute.path" routerLinkActive="active" *ngIf="shouldRenderItem(childRoute)">
                <mat-list-item>
                  <!-- <mat-icon matListIcon> {{ childRoute.data.icon }} </mat-icon> -->
                  <div class="menu-icon">
                    <img [class.hidden]="rla.isActive" [src]="childRoute.data.icon" />
                    <img [class.hidden]="!rla.isActive" [src]="childRoute.data.activeIcon" />
                  </div>
                  <span class="menu-header">
                    {{ childRoute.data.title }}
                  </span>
                </mat-list-item>
              </a></ng-container
            >
          </ng-container>
        </div>
      </mat-nav-list>
    </mat-drawer>

    <div class="sidenav-content" [class.expanded]="router.url != '/login' && router.url != '/register' && router.url != '/password-recovery' && router.url != '/subject-selection' && router.url != '/headquarter-selection'">
      <div class="container" [class.gradient-background]="isOutsideApp()">
        <router-outlet>
          <mat-spinner *ngIf="loading" style="margin: 90px auto"></mat-spinner>
        </router-outlet>
      </div>
    </div>
  </mat-drawer-container>
  <app-mobile-footer fxHide.gt-sm *ngIf="isInsideApp()"></app-mobile-footer>
</body>
