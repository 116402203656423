/**
 * BDOEX API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * Contact: mateusz.surma@mae.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { FilteredPaginatedRequest } from '../model/filteredPaginatedRequest';
import { MarkForFillingRequest } from '../model/markForFillingRequest';
import { WasteTransferCardRepositoryEntityDtoPaginatedResult } from '../model/wasteTransferCardRepositoryEntityDtoPaginatedResult';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class WasteTransferCardService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param markedForFiling 
     * @param filingCompleted 
     * @param eupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFilteredWasteTransferCards(body?: FilteredPaginatedRequest, markedForFiling?: boolean, filingCompleted?: boolean, eupId?: string, observe?: 'body', reportProgress?: boolean): Observable<WasteTransferCardRepositoryEntityDtoPaginatedResult>;
    public getFilteredWasteTransferCards(body?: FilteredPaginatedRequest, markedForFiling?: boolean, filingCompleted?: boolean, eupId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WasteTransferCardRepositoryEntityDtoPaginatedResult>>;
    public getFilteredWasteTransferCards(body?: FilteredPaginatedRequest, markedForFiling?: boolean, filingCompleted?: boolean, eupId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WasteTransferCardRepositoryEntityDtoPaginatedResult>>;
    public getFilteredWasteTransferCards(body?: FilteredPaginatedRequest, markedForFiling?: boolean, filingCompleted?: boolean, eupId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (markedForFiling !== undefined && markedForFiling !== null) {
            queryParameters = queryParameters.set('markedForFiling', <any>markedForFiling);
        }
        if (filingCompleted !== undefined && filingCompleted !== null) {
            queryParameters = queryParameters.set('filingCompleted', <any>filingCompleted);
        }
        if (eupId !== undefined && eupId !== null) {
            queryParameters = queryParameters.set('eupId', <any>eupId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<WasteTransferCardRepositoryEntityDtoPaginatedResult>('post',`${this.basePath}/api/WasteTransferCard/filtered-waste-transfer-cards`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param senderName 
     * @param rangeFrom 
     * @param rangeTo 
     * @param address 
     * @param wasteGenerationLocation 
     * @param wasteCode 
     * @param additionalInfo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFilteredWasteTransferCardsReport(senderName?: string, rangeFrom?: Date, rangeTo?: Date, address?: string, wasteGenerationLocation?: string, wasteCode?: string, additionalInfo?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getFilteredWasteTransferCardsReport(senderName?: string, rangeFrom?: Date, rangeTo?: Date, address?: string, wasteGenerationLocation?: string, wasteCode?: string, additionalInfo?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getFilteredWasteTransferCardsReport(senderName?: string, rangeFrom?: Date, rangeTo?: Date, address?: string, wasteGenerationLocation?: string, wasteCode?: string, additionalInfo?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getFilteredWasteTransferCardsReport(senderName?: string, rangeFrom?: Date, rangeTo?: Date, address?: string, wasteGenerationLocation?: string, wasteCode?: string, additionalInfo?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (senderName !== undefined && senderName !== null) {
            queryParameters = queryParameters.set('senderName', <any>senderName);
        }
        if (rangeFrom !== undefined && rangeFrom !== null) {
            queryParameters = queryParameters.set('rangeFrom', <any>rangeFrom.toISOString());
        }
        if (rangeTo !== undefined && rangeTo !== null) {
            queryParameters = queryParameters.set('rangeTo', <any>rangeTo.toISOString());
        }
        if (address !== undefined && address !== null) {
            queryParameters = queryParameters.set('address', <any>address);
        }
        if (wasteGenerationLocation !== undefined && wasteGenerationLocation !== null) {
            queryParameters = queryParameters.set('wasteGenerationLocation', <any>wasteGenerationLocation);
        }
        if (wasteCode !== undefined && wasteCode !== null) {
            queryParameters = queryParameters.set('wasteCode', <any>wasteCode);
        }
        if (additionalInfo !== undefined && additionalInfo !== null) {
            queryParameters = queryParameters.set('additionalInfo', <any>additionalInfo);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/WasteTransferCard/report`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                responseType: "blob",
            } as any
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public markForFiling(body?: MarkForFillingRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public markForFiling(body?: MarkForFillingRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public markForFiling(body?: MarkForFillingRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public markForFiling(body?: MarkForFillingRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/WasteTransferCard/mark-for-filing`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
