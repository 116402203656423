import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { CookieService } from "ngx-cookie-service";
import { Observable, zip } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { ChangesRegisterEntityDto, DraftCardsService, ExtendedKpoService, SearchResultItemDto, UserDto } from "../../../api";
import { DraftCard } from "../../../api/model/draftCard";
import { AdvancedFilterQuery } from "../components/bdo-filter/bdo-filter.component";
import { RoleService } from "./role.service";

export interface DashboardCardsResult {
  items: (DraftCard | SearchResultItemDto)[];
  totalItemsCount: number;
  userAttributions: {
    [key: string]: UserDto;
  };
  changesRegister: {
    [key: string]: ChangesRegisterEntityDto[];
  };
}

export interface RealisingNowResult {
  items: SearchResultItemDto[];
  totalItemsCount: number;
  userAttributions: {
    [key: string]: UserDto;
  };
  changesRegister: {
    [key: string]: ChangesRegisterEntityDto[];
  };
}

@UntilDestroy()
@Injectable({
  providedIn: "root",
})
export class CardService {
  constructor(private extendedKpoService: ExtendedKpoService, private draftCardsService: DraftCardsService, public dialog: MatDialog, private roleService: RoleService) {}

  public awaitingDriverStatus = "Oczekuje na nadanie kierowcy";
  public awaitingAcceptStatus = "Oczekuje na zaakceptowanie";
  public awaitingUserApproval = "Oczekuje na zatwierdzenie przez użytkownika";
  public rejectedStatus = "Odrzucona";
  public plannedStatus = "Planowana";
  public editedStatus = "Edytowana";
  public readdedStatus = "Do weryfikacji";

  public getDashboardCards(filterQuery: AdvancedFilterQuery, index = 0, itemsPerPage = 50): Observable<DashboardCardsResult> {
    const wasteCode = filterQuery.wasteCode === "" ? undefined : filterQuery.wasteCode;
    const cardNumber = filterQuery.cardNumber === "" ? undefined : filterQuery.cardNumber;
    const senderName = filterQuery.senderName === "" ? undefined : filterQuery.senderName;
    const vehicleRegistrationNumber = filterQuery.vehicleRegistrationNumber === "" ? undefined : filterQuery.vehicleRegistrationNumber;

    const draftCards$ = this.draftCardsService.getAll();
    const kpoCards$ = this.roleService.role$.pipe(
      untilDestroyed(this),
      switchMap((role) =>
        this.extendedKpoService.searchKpo(
          {
            cardNumber: cardNumber,
            cardStatusCodeNames: filterQuery.cardStatusCodeName,
            wasteCodeAndDescription: wasteCode,
            vehicleRegNumber: vehicleRegistrationNumber,
            transportDateRange: true,
            transportDateFrom: filterQuery.transportDateRange.from,
            transportDateTo: filterQuery.transportDateRange.to,
            receiveConfirmationDateRange: false,
            paginationParameters: {
              order: { isAscending: false },
              page: {
                index: index + 1,
                size: itemsPerPage,
              },
            },
            searchInReceivers: false,
            searchInSenders: true,
            name: senderName,
          },
          role
        )
      )
    );
    return zip(draftCards$, kpoCards$).pipe(
      map((array) => {
        const draftCards = array[0].filter((d, i, ar) => {
          const statusFilter = d.cardStatus === this.awaitingAcceptStatus || d.cardStatus === this.plannedStatus || d.cardStatus === this.readdedStatus || d.cardStatus === this.editedStatus;
          const wasteCodeFilter = wasteCode === undefined || wasteCode === "" ? true : d.wasteCode.includes(wasteCode);
          const cardNumberFilter = cardNumber === undefined || cardNumber === "";
          console.log(statusFilter, wasteCodeFilter, cardNumberFilter);
          return statusFilter && wasteCodeFilter && cardNumberFilter;
        });
        console.log(array[0], draftCards);
        const kpoCards = array[1];
        const items = kpoCards.items !== undefined ? draftCards.concat(kpoCards.items) : draftCards;
        return {
          items: items,
          totalItemsCount: draftCards.length + kpoCards.totalResultNumber,
          userAttributions: kpoCards.associatedUsers,
          changesRegister: kpoCards.changesRegister,
        };
      })
    );
  }
  public getRealisingNowCards(filterQuery: AdvancedFilterQuery, index = 1, itemsPerPage = 50): Observable<RealisingNowResult> {
    const wasteCode = filterQuery.wasteCode === "" ? undefined : filterQuery.wasteCode;
    const cardNumber = filterQuery.cardNumber === "" ? undefined : filterQuery.cardNumber;
    const senderName = filterQuery.senderName === "" ? undefined : filterQuery.senderName;
    const vehicleRegistrationNumber = filterQuery.vehicleRegistrationNumber === "" ? undefined : filterQuery.vehicleRegistrationNumber;

    const kpoCards$ = this.roleService.role$.pipe(
      untilDestroyed(this),
      switchMap((role) =>
        this.extendedKpoService.searchKpo(
          {
            cardNumber: cardNumber,
            cardStatusCodeNames: filterQuery.cardStatusCodeName,
            wasteCodeAndDescription: wasteCode,
            vehicleRegNumber: vehicleRegistrationNumber,
            transportDateRange: true,
            transportDateFrom: filterQuery.transportDateRange.from,
            transportDateTo: filterQuery.transportDateRange.to,
            receiveConfirmationDateRange: false,
            paginationParameters: {
              order: { isAscending: false },
              page: {
                index: index,
                size: itemsPerPage,
              },
            },
            searchInReceivers: false,
            searchInSenders: true,
            searchInCarriers: false,
            name: senderName,
          },
          role
        )
      )
    );
    return kpoCards$.pipe(
      map((kpoCards) => {
        const items = kpoCards.items;
        return {
          items: items.map((r) => r),
          totalItemsCount: kpoCards.totalResultNumber,
          userAttributions: kpoCards.associatedUsers,
          changesRegister: kpoCards.changesRegister,
        };
      })
    );
  }
}
