import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivate, CanDeactivate } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";
import { Configuration } from "../../../api/configuration";
import { RoleService } from "../services/role.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private configuration: Configuration, private cookieService: CookieService, private roleService: RoleService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const bearer = this.cookieService.get("bearer");

    if (!this.cookieService.check("bearer")) {
      this.router.navigate(["/login"]);
      return false;
    }

    const tokenExpiry = this.cookieService.get("token-expiry");

    if (tokenExpiry == undefined || Date.now() >= parseInt(tokenExpiry)) {
      this.router.navigate(["/subject-selection"]);
      return false;
    }

    if (this.configuration.apiKeys["Authorization"] !== undefined && this.configuration.apiKeys["Authorization"] !== "KEY") {
      return true;
    }

    this.router.navigate(["/landing-page"], { queryParams: { redirect: next.pathFromRoot.map((r) => r.url).join("/") } });

    return false;
  }
}
@Injectable({
  providedIn: "root",
})
export class CanDeactivateGuard implements CanDeactivate<any> {
  public navigationAllowed = true;
  constructor(private readonly dialog: MatDialog) {}

  canDeactivate(component: any, currentRoute: ActivatedRouteSnapshot): boolean {
    if (this.navigationAllowed) {
      return true;
    }
    this.navigationAllowed = true;
    return false;
  }
}
