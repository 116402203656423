<h2 mat-dialog-title>Regulamin</h2>
<div mat-dialog-content>
  Logując się do serwisu BDOsystem danymi wygenerowanymi na podstawie Twojego
  wniosku o rejestrację podmiotu, złożonego na stronie www.bdosystem.pl,
  akceptujesz zapisy
  <a href="/assets/regulamin_serwisu_BDOsystem.pdf" target="_blank">Regulaminu serwisu</a>
  oraz
  <a href="https://bdosystem.pl/polityka-prywatnosci/" target="_blank">Polityki Prywatności</a>.
</div>
<div mat-dialog-actions>
  <button mat-flat-button (click)="reject()">Odrzuć</button>
  <button mat-flat-button color="primary" (click)="accept()">Akceptuj</button>
</div>
