import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dynamic-file-reader',
  templateUrl: './dynamic-file-reader.component.html',
  styleUrls: ['./dynamic-file-reader.component.scss']
})
export class DynamicFileReaderComponent implements OnInit {

  files: File[] = [];
  fileUploaded = false;
  progress: number;
  constructor(  public dialog: MatDialogRef<DynamicFileReaderComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
  }

  dragFile() {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.dialog.close(fileReader.result);
    };
    fileReader.readAsText(this.files[0]);
  }
}
