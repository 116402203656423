<h1 mat-dialog-title>Wybór siedziby firmy</h1>

<mat-spinner *ngIf="dataSource == null" style="margin: 0 auto;"></mat-spinner>

<div mat-dialog-content *ngIf="!(dataSource == null)">
  <table mat-table [dataSource]="dataSource">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <ng-container matColumnDef="radio">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element" style="text-align: right;"> <button (click)="set(element)"
          mat-button>Wybierz</button></td>
    </ng-container>
    <!-- Position Column -->
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef> MPD </th>
      <td mat-cell *matCellDef="let element"> {{element.number}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Adres </th>
      <td mat-cell *matCellDef="let element">
        <div [innerHTML]="element.addressHtml"></div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="null" cdkFocusInitial>Anuluj</button>
</div>