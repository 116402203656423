import { Component, Inject, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { debounceTime, startWith, distinctUntilChanged, switchMap, tap } from "rxjs/operators";
import { BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto, BdoServiceModelsEnumerationsCompanyType, CorrectRequest, ExtendedKpoService, WasteRegisterPublicApiApiModelsElasticsearchTerytEs, WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto } from "../../../../../../api";
import { AlertService, MessageType } from "../../../../services/alert.service";
import { ConfirmReciveDialogComponent } from "../confirm-recive-dialog/confirm-recive-dialog.component";

@Component({
  selector: "app-revise-card-dialog",
  templateUrl: "./revise-card-dialog.component.html",
  styleUrls: ["./revise-card-dialog.component.scss"],
})
export class ReviseCardDialogComponent implements OnInit {
  remarks = "";
  working = false;
  wasteCodeDescription = "";
  wasteCodeModel: WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto;
  loading = true;
  wasteMassValue = "";
  wasteCode = new FormControl();
  copyOfWasteCodes: WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto[];
  filteredWasteCodes: Observable<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto[]>;

  details?: CorrectRequest;
  constructor(private extendedKpoService: ExtendedKpoService, public dialogRef: MatDialogRef<ConfirmReciveDialogComponent>, private alertService: AlertService, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.extendedKpoService.getKpoDetails(this.data.model.kpoId, "REJECTED", BdoServiceModelsEnumerationsCompanyType.NUMBER_1).subscribe((card) => {
      this.details = {
        hazardousWasteReclassification: card.hazardousWasteReclassification,
        hazardousWasteReclassificationDescription: card.hazardousWasteReclassificationDescription,
        wasteCodeExtended: card.wasteCodeExtended,
        wasteCodeExtendedDescription: card.wasteCodeExtendedDescription,
        wasteMass: card.wasteMass,
        wasteCodeId: card.wasteCodeId,
      };
      console.log(card);
      this.remarks = card.remarks;
      this.wasteMassValue = card.wasteMass.toString();
      this.extendedKpoService.getWasteCodes().subscribe((allWasteCodes) => {
        this.wasteCodeModel = allWasteCodes.find((x) => x.wasteCodeId === card.wasteCodeId);
        if (this.wasteCodeModel != undefined) this.wasteCode.setValue(this.wasteCodeModel.code);
        this.loading = false;
      });
    });

    this.filteredWasteCodes = this.wasteCode.valueChanges.pipe(
      debounceTime(400),
      startWith(""),
      distinctUntilChanged(),
      switchMap((value) => this.extendedKpoService.getWasteCodes(value)),
      tap((w) => (this.copyOfWasteCodes = w))
    );
  }

  wasteCodeActivated($event) {
    this.wasteCodeModel = this.copyOfWasteCodes.find((x) => x.code === $event.option.value);
    this.details.wasteCodeId = this.wasteCodeModel.wasteCodeId;
  }

  wasteCodeFocusout($event) {
    if (this.copyOfWasteCodes === undefined) {
      return;
    }

    this.wasteCodeModel = this.copyOfWasteCodes.find((x) => x.code === $event.option.value);

    if (this.wasteCodeModel === undefined) {
      this.wasteCode.setErrors({ notUnique: true });
    }
  }

  parseWasteMass($event) {
    if (this.wasteMassValue.indexOf(",") !== -1) {
      this.wasteMassValue = this.wasteMassValue.replace(",", ".");
    }
    const parsedMass = parseFloat(this.wasteMassValue);
    if (Number.isNaN(parsedMass)) {
      this.wasteMassValue = "";
      this.details.wasteMass = undefined;
      return;
    }
    this.details.wasteMass = parseFloat(this.wasteMassValue);
    this.wasteMassValue = parsedMass.toFixed(4).replace(".", ",");
  }

  clearWasteCodeExtendedDescription(value) {
    if (value) this.details.wasteCodeExtendedDescription = undefined;
  }

  clearHazardousWasteReclassificationDescription(value) {
    if (value) this.details.hazardousWasteReclassificationDescription = undefined;
  }

  confirm(form) {
    if (!form.valid) return;
    this.working = true;
    this.extendedKpoService.revise(this.data.model.kpoId, this.details).subscribe(
      () => {
        this.alertService.showMessage("Pomyślnie skorygowano kartę.", MessageType.Info);
        this.dialogRef.close(true);
      },
      (error) => {
        this.alertService.showMessage("Wystąpił błąd podczas korygowania karty", MessageType.Info);
        this.working = false;
      }
    );
  }
}
