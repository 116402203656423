import { Component, Inject, Input, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { KEOCardDetailsDto, RecordCardService } from "../../../../../api";
import { AlertService, MessageType } from "../../../services/alert.service";

@Component({
  selector: "app-filed-card-details",
  templateUrl: "./filed-card-details.component.html",
  styleUrls: ["./filed-card-details.component.scss"],
})
export class FiledCardDetailsComponent implements OnInit {
  public details: KEOCardDetailsDto;

  constructor(public dialogRef: MatDialogRef<FiledCardDetailsComponent>, private service: RecordCardService, @Inject(MAT_DIALOG_DATA) public data: { cardId: string }, private alertService: AlertService) {}

  ngOnInit(): void {
    this.service.getSingleKEOCard(this.data.cardId).subscribe(
      (result) => (this.details = result),
      (error) => this.alertService.showMessage(error, MessageType.Error)
    );
  }
}
