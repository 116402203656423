import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ExtendedKpoService } from "../../../../../../api";
import { AlertService, MessageType } from "../../../../services/alert.service";

@Component({
  selector: "app-reject-receive-dialog",
  templateUrl: "./reject-receive-dialog.component.html",
  styleUrls: ["./reject-receive-dialog.component.scss"],
})
export class RejectReceiveDialogComponent implements OnInit {
  remarks = "";
  working = false;
  constructor(
    public dialogRef: MatDialogRef<RejectReceiveDialogComponent>,
    private extendedKpoService: ExtendedKpoService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  rejectionMessage = "";

  ngOnInit(): void {}

  reject() {
    this.working = true;
    this.extendedKpoService
      .rejectTransport(this.data.model.kpoId, this.remarks)
      .subscribe(
        (res) => {
          this.alertService.showMessage(
            "Pomyślnie odrzucono odpad.",
            MessageType.Info
          );
          this.dialogRef.close(true);
        },
        (error) => {
          this.alertService.showMessage(
            "Wystąpił błąd podczas odrzucania odpadu",
            MessageType.Info
          );
          this.working = false;
        }
      );
  }
}
