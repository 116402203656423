<h1 mat-dialog-title *ngIf="isSender">
  Jeśli na podstawie wskazanych kart chcesz również dodać wpisy do ewidencji
  odpadów wytworzonych wybierz, gdzie dany odpad został wytworzony
</h1>
<h1 mat-dialog-title *ngIf="!isSender">
  Czy na pewno chcesz dodać wybrane karty do ewidencji?
</h1>
<div mat-dialog-content *ngIf="isSender">
  <br />
  <mat-radio-group aria-label="Wybierz" [(ngModel)]="isInInstallation">
    <mat-radio-button value="true"
      >w związku z eksploatacją instalacji</mat-radio-button
    >
    <mat-radio-button value="false">poza instalacją</mat-radio-button>
  </mat-radio-group>
</div>
<div mat-dialog-actions>
  <button mat-button style="margin-top: 15px" (click)="dialogRef.close(false)">
    Anuluj
  </button>
  <button
    mat-button
    style="margin-top: 15px"
    (click)="markForFiling(false)"
    color="warn"
    *ngIf="isSender"
  >
    Pomiń
  </button>
  <button
    mat-button
    style="margin-top: 15px"
    (click)="markForFiling(true)"
    [disabled]="isInInstallation == undefined && isSender"
    color="primary"
  >
    Zapisz
  </button>
</div>
