<h1 mat-dialog-title>Zmień rolę</h1>
<div mat-dialog-content>
  <mat-radio-group aria-label="Rola" class="radio-group" [(ngModel)]="role">
    <mat-radio-button value="SENDER">przekazujący</mat-radio-button>
    <mat-radio-button value="CARRIER">transportujący / łączący role</mat-radio-button>
  </mat-radio-group>
</div>
<div mat-dialog-actions>
  <button mat-button style="margin-top: 15px" (click)="dialogRef.close(false)">Anuluj</button>
  <button mat-button style="margin-top: 15px" (click)="setRole()" color="primary" cdkFocusInitial>Potwierdź</button>
</div>
