import { Component, OnInit, Inject } from "@angular/core";
import {
  GroupDto,
  UserDto,
  UsersService,
  GroupsService,
} from "../../../../../../api";
import { AlertService, MessageType } from "../../../../services/alert.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { group } from "@angular/animations";

@Component({
  selector: "app-dispatcher-wizard",
  templateUrl: "./dispatcher-wizard.component.html",
  styleUrls: ["./dispatcher-wizard.component.scss"],
})
export class DispatcherWizardComponent implements OnInit {
  password: string;
  hidePassword = true;
  sendMail = false;

  userGroups: GroupDto[];
  user: UserDto = {firstName: '', lastName: '', username: ''};

  constructor(private userService: UsersService,
    private groupService: GroupsService,
    private alertService: AlertService,
    public dialog: MatDialogRef<DispatcherWizardComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {

  }

  ngOnInit() {
    this.groupService.getAll().subscribe(value => { this.userGroups = value; });
    this.user = this.data.user === undefined ? null : this.data.user;
  }

  valueChange($event) {
    this.sendMail = $event.checked;
  }

  onSubmit(form): void {
    switch (this.data.mode) {
      case 'create':
        this.user.group = this.userGroups.find(g => g.name === 'Dyspozytor');
        this.userService
        .add(this.user, this.password === '' ? null : this.password, this.sendMail)
        .subscribe((result: any) => {
          this.alertService.showMessage('Pomyślnie dodano dyspozytora', MessageType.Info);
          this.dialog.close(true);
        }, error => this.alertService.showMessage(error.error, MessageType.Error));
        break;
      case 'edit':
        this.userService
        .update(this.user.id, this.user, this.password === '' ? null : this.password, this.sendMail)
        .subscribe((result: any) => {
          this.alertService.showMessage('Pomyślnie zaktualizowano dane dyspozytora', MessageType.Info);
          this.dialog.close(true);
        }, error => this.alertService.showMessage(error.error, MessageType.Error));
        break;
    }

  }

}
