/**
 * BDOEX API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * Contact: mateusz.surma@mae.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto
 */
export interface WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto { 
    /**
     * Id kodu odpadu
     */
    wasteCodeId?: number;
    /**
     * Data utworzenia wpisu
     */
    dateCreated?: Date;
    /**
     * Kod odpadu
     */
    code?: string;
    /**
     * Opis kodu odpadu
     */
    description?: string;
    /**
     * Flaga ustawiana na true, jeśli odpad należy do kategorii niebezpiecznych, false w przeciwnym wypadku
     */
    isDangerous?: boolean;
    /**
     * Gets or Sets Deleted
     */
    deleted?: boolean;
    /**
     * Gets or Sets Level
     */
    level?: number;
    /**
     * Id odpadu nadrzędnego
     */
    parentId?: number;
}