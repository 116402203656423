/**
 * BDOEX API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * Contact: mateusz.surma@mae.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto } from '../model/bdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto';
import { ChangesRegisterEntityDto } from '../model/changesRegisterEntityDto';
import { CompanySearchResultDto } from '../model/companySearchResultDto';
import { DraftCardDto } from '../model/draftCardDto';
import { ElevatedSenderKpoCardDto } from '../model/elevatedSenderKpoCardDto';
import { WasteRegisterPublicApiApiModelsElasticsearchEupEs } from '../model/wasteRegisterPublicApiApiModelsElasticsearchEupEs';
import { WasteRegisterPublicApiApiModelsElasticsearchTerytEs } from '../model/wasteRegisterPublicApiApiModelsElasticsearchTerytEs';
import { WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardSearchCardsV1SearchKpoAsSenderRequest } from '../model/wasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardSearchCardsV1SearchKpoAsSenderRequest';
import { WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1ReviseKpoRequest } from '../model/wasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1ReviseKpoRequest';
import { WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto } from '../model/wasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto';
import { WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1GetWithdrawnKpoDto } from '../model/wasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1GetWithdrawnKpoDto';
import { WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsSenderSearchResultDto } from '../model/wasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsSenderSearchResultDto';
import { WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1PlannedKpoDto } from '../model/wasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1PlannedKpoDto';
import { WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ReceiveConfirmedKpoDto } from '../model/wasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ReceiveConfirmedKpoDto';
import { WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1RejectedKpoDto } from '../model/wasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1RejectedKpoDto';
import { WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1TransportConfirmationKpoDto } from '../model/wasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1TransportConfirmationKpoDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MobileAppService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addAsApproved(body?: DraftCardDto, token?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public addAsApproved(body?: DraftCardDto, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public addAsApproved(body?: DraftCardDto, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public addAsApproved(body?: DraftCardDto, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/MobileApp/Sender/ApprovedCard`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadConfirmation(kpoId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public downloadConfirmation(kpoId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public downloadConfirmation(kpoId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public downloadConfirmation(kpoId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling downloadConfirmation.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/api/MobileApp/Sender/Kpo/${encodeURIComponent(String(kpoId))}/DownloadConfirmation`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param token 
     * @param registrationNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateConfirmation(kpoId: string, token?: string, registrationNumber?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public generateConfirmation(kpoId: string, token?: string, registrationNumber?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public generateConfirmation(kpoId: string, token?: string, registrationNumber?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public generateConfirmation(kpoId: string, token?: string, registrationNumber?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling generateConfirmation.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }
        if (registrationNumber !== undefined && registrationNumber !== null) {
            queryParameters = queryParameters.set('registrationNumber', <any>registrationNumber);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/MobileApp/Sender/Kpo/${encodeURIComponent(String(kpoId))}/GenerateConfirmation`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChangeRegistryEntities(kpoId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ChangesRegisterEntityDto>>;
    public getChangeRegistryEntities(kpoId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChangesRegisterEntityDto>>>;
    public getChangeRegistryEntities(kpoId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChangesRegisterEntityDto>>>;
    public getChangeRegistryEntities(kpoId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling getChangeRegistryEntities.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ChangesRegisterEntityDto>>('get',`${this.basePath}/api/MobileApp/Sender/Kpo/${encodeURIComponent(String(kpoId))}/RegistryEntities`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param companyId 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompany(companyId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<CompanySearchResultDto>;
    public getCompany(companyId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CompanySearchResultDto>>;
    public getCompany(companyId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CompanySearchResultDto>>;
    public getCompany(companyId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompany.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CompanySearchResultDto>('get',`${this.basePath}/api/MobileApp/Company/${encodeURIComponent(String(companyId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param eupId 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyEup(eupId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<WasteRegisterPublicApiApiModelsElasticsearchEupEs>;
    public getCompanyEup(eupId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WasteRegisterPublicApiApiModelsElasticsearchEupEs>>;
    public getCompanyEup(eupId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WasteRegisterPublicApiApiModelsElasticsearchEupEs>>;
    public getCompanyEup(eupId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (eupId === null || eupId === undefined) {
            throw new Error('Required parameter eupId was null or undefined when calling getCompanyEup.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WasteRegisterPublicApiApiModelsElasticsearchEupEs>('get',`${this.basePath}/api/MobileApp/Sender/Company/Eup/${encodeURIComponent(String(eupId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param companyId 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyEups(companyId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<WasteRegisterPublicApiApiModelsElasticsearchEupEs>>;
    public getCompanyEups(companyId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WasteRegisterPublicApiApiModelsElasticsearchEupEs>>>;
    public getCompanyEups(companyId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WasteRegisterPublicApiApiModelsElasticsearchEupEs>>>;
    public getCompanyEups(companyId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyEups.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<WasteRegisterPublicApiApiModelsElasticsearchEupEs>>('get',`${this.basePath}/api/MobileApp/Sender/Company/${encodeURIComponent(String(companyId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param token 
     * @param statusCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getKpoAsSender(token?: string, statusCode?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ElevatedSenderKpoCardDto>>;
    public getKpoAsSender(token?: string, statusCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ElevatedSenderKpoCardDto>>>;
    public getKpoAsSender(token?: string, statusCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ElevatedSenderKpoCardDto>>>;
    public getKpoAsSender(token?: string, statusCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }
        if (statusCode !== undefined && statusCode !== null) {
            queryParameters = queryParameters.set('statusCode', <any>statusCode);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ElevatedSenderKpoCardDto>>('get',`${this.basePath}/api/MobileApp/Sender/Kpo`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param token 
     * @param statusCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getKpoDetails(kpoId: string, token?: string, statusCode?: string, observe?: 'body', reportProgress?: boolean): Observable<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1PlannedKpoDto>;
    public getKpoDetails(kpoId: string, token?: string, statusCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1PlannedKpoDto>>;
    public getKpoDetails(kpoId: string, token?: string, statusCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1PlannedKpoDto>>;
    public getKpoDetails(kpoId: string, token?: string, statusCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling getKpoDetails.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }
        if (statusCode !== undefined && statusCode !== null) {
            queryParameters = queryParameters.set('statusCode', <any>statusCode);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1PlannedKpoDto>('get',`${this.basePath}/api/MobileApp/Sender/Kpo/${encodeURIComponent(String(kpoId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRejectedKpoDetails(kpoId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1RejectedKpoDto>;
    public getRejectedKpoDetails(kpoId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1RejectedKpoDto>>;
    public getRejectedKpoDetails(kpoId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1RejectedKpoDto>>;
    public getRejectedKpoDetails(kpoId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling getRejectedKpoDetails.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1RejectedKpoDto>('get',`${this.basePath}/api/MobileApp/Sender/Kpo/${encodeURIComponent(String(kpoId))}/AsRejected`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSelfCompany(token?: string, observe?: 'body', reportProgress?: boolean): Observable<CompanySearchResultDto>;
    public getSelfCompany(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CompanySearchResultDto>>;
    public getSelfCompany(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CompanySearchResultDto>>;
    public getSelfCompany(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CompanySearchResultDto>('get',`${this.basePath}/api/MobileApp/Sender/Company`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param query 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTeryts(query: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<WasteRegisterPublicApiApiModelsElasticsearchTerytEs>>;
    public getTeryts(query: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WasteRegisterPublicApiApiModelsElasticsearchTerytEs>>>;
    public getTeryts(query: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WasteRegisterPublicApiApiModelsElasticsearchTerytEs>>>;
    public getTeryts(query: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling getTeryts.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<WasteRegisterPublicApiApiModelsElasticsearchTerytEs>>('get',`${this.basePath}/api/MobileApp/Sender/Teryt/${encodeURIComponent(String(query))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param query 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWasteCodes(query: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto>>;
    public getWasteCodes(query: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto>>>;
    public getWasteCodes(query: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto>>>;
    public getWasteCodes(query: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling getWasteCodes.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto>>('get',`${this.basePath}/api/MobileApp/Sender/WasteCode/${encodeURIComponent(String(query))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param query 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWasteProcess(query?: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto>>;
    public getWasteProcess(query?: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto>>>;
    public getWasteProcess(query?: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto>>>;
    public getWasteProcess(query?: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto>>('get',`${this.basePath}/api/MobileApp/Sender/WasteProcess`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWasteProcessById(id: number, token?: string, observe?: 'body', reportProgress?: boolean): Observable<BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto>;
    public getWasteProcessById(id: number, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto>>;
    public getWasteProcessById(id: number, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto>>;
    public getWasteProcessById(id: number, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getWasteProcessById.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto>('get',`${this.basePath}/api/MobileApp/Sender/WasteProcess/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWithdrawnKpoDetails(kpoId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1GetWithdrawnKpoDto>;
    public getWithdrawnKpoDetails(kpoId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1GetWithdrawnKpoDto>>;
    public getWithdrawnKpoDetails(kpoId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1GetWithdrawnKpoDto>>;
    public getWithdrawnKpoDetails(kpoId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling getWithdrawnKpoDetails.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1GetWithdrawnKpoDto>('get',`${this.basePath}/api/MobileApp/Sender/Kpo/${encodeURIComponent(String(kpoId))}/AsWithdrawn`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public receiveConfirmedKpoDetails(kpoId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ReceiveConfirmedKpoDto>;
    public receiveConfirmedKpoDetails(kpoId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ReceiveConfirmedKpoDto>>;
    public receiveConfirmedKpoDetails(kpoId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ReceiveConfirmedKpoDto>>;
    public receiveConfirmedKpoDetails(kpoId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling receiveConfirmedKpoDetails.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ReceiveConfirmedKpoDto>('get',`${this.basePath}/api/MobileApp/Sender/Kpo/${encodeURIComponent(String(kpoId))}/AsReceiveConfirmed`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param token 
     * @param remarks 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rejectCard(kpoId: string, token?: string, remarks?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public rejectCard(kpoId: string, token?: string, remarks?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public rejectCard(kpoId: string, token?: string, remarks?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public rejectCard(kpoId: string, token?: string, remarks?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling rejectCard.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }
        if (remarks !== undefined && remarks !== null) {
            queryParameters = queryParameters.set('remarks', <any>remarks);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/MobileApp/Sender/Kpo/${encodeURIComponent(String(kpoId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reviseRejected(body?: WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1ReviseKpoRequest, token?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public reviseRejected(body?: WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1ReviseKpoRequest, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public reviseRejected(body?: WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1ReviseKpoRequest, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public reviseRejected(body?: WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1ReviseKpoRequest, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/MobileApp/Sender/ReviceRejected`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param token 
     * @param query 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchCompany(token?: string, query?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CompanySearchResultDto>>;
    public searchCompany(token?: string, query?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CompanySearchResultDto>>>;
    public searchCompany(token?: string, query?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CompanySearchResultDto>>>;
    public searchCompany(token?: string, query?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CompanySearchResultDto>>('get',`${this.basePath}/api/MobileApp/Company`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchKpo(body?: WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardSearchCardsV1SearchKpoAsSenderRequest, token?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsSenderSearchResultDto>>;
    public searchKpo(body?: WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardSearchCardsV1SearchKpoAsSenderRequest, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsSenderSearchResultDto>>>;
    public searchKpo(body?: WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardSearchCardsV1SearchKpoAsSenderRequest, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsSenderSearchResultDto>>>;
    public searchKpo(body?: WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardSearchCardsV1SearchKpoAsSenderRequest, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsSenderSearchResultDto>>('post',`${this.basePath}/api/MobileApp/Sender/Kpo`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public transportConfirmedKpoDetails(kpoId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1TransportConfirmationKpoDto>;
    public transportConfirmedKpoDetails(kpoId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1TransportConfirmationKpoDto>>;
    public transportConfirmedKpoDetails(kpoId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1TransportConfirmationKpoDto>>;
    public transportConfirmedKpoDetails(kpoId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling transportConfirmedKpoDetails.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1TransportConfirmationKpoDto>('get',`${this.basePath}/api/MobileApp/Sender/Kpo/${encodeURIComponent(String(kpoId))}/AsTransportConfirmed`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
