<h2 mat-dialog-title> Opisz powód odrzucenia karty </h2>
<div mat-dialog-content>
    <mat-form-field class="example-full-width">
        <mat-label>Powód odrzucenia karty</mat-label>
        <input matInput autocomplete="off" [(ngModel)]="rejectionMessage" placeholder="..." />
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button color="warn" (click)="dialogRef.close();">Anuluj</button>
    <button mat-button (click)="reject()">Wybierz</button>
</div>