/**
 * BDOEX API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * Contact: mateusz.surma@mae.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BDOApiClientModelsCompanyEupDto } from '../model/bDOApiClientModelsCompanyEupDto';
import { BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto } from '../model/bdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto';
import { CompanySearchResultDto } from '../model/companySearchResultDto';
import { DraftCardDto } from '../model/draftCardDto';
import { ElevatedDriverKpoDto } from '../model/elevatedDriverKpoDto';
import { TasksResponseDto } from '../model/tasksResponseDto';
import { WasteRegisterPublicApiApiModelsElasticsearchCompanyEs } from '../model/wasteRegisterPublicApiApiModelsElasticsearchCompanyEs';
import { WasteRegisterPublicApiApiModelsElasticsearchEupEs } from '../model/wasteRegisterPublicApiApiModelsElasticsearchEupEs';
import { WasteRegisterPublicApiApiModelsElasticsearchTerytEs } from '../model/wasteRegisterPublicApiApiModelsElasticsearchTerytEs';
import { WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardSearchCardsV1SearchKpoAsCarrierRequest } from '../model/wasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardSearchCardsV1SearchKpoAsCarrierRequest';
import { WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1ReviseKpoRequest } from '../model/wasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1ReviseKpoRequest';
import { WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1UpdateApprovedKpoAndGenerateConfirmationAsCarrierRequest } from '../model/wasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1UpdateApprovedKpoAndGenerateConfirmationAsCarrierRequest';
import { WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto } from '../model/wasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto';
import { WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ApprovedKpoDto } from '../model/wasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ApprovedKpoDto';
import { WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ConfirmationGeneratedKpoDto } from '../model/wasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ConfirmationGeneratedKpoDto';
import { WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsCarrierSearchResultDto } from '../model/wasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsCarrierSearchResultDto';
import { WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ReceiveConfirmedKpoDto } from '../model/wasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ReceiveConfirmedKpoDto';
import { WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1RejectedKpoDto } from '../model/wasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1RejectedKpoDto';
import { WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1TransportConfirmationKpoDto } from '../model/wasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1TransportConfirmationKpoDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DriverAppService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param eupId 
     * @param registrationNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addAsApproved(body?: DraftCardDto, eupId?: string, registrationNumber?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public addAsApproved(body?: DraftCardDto, eupId?: string, registrationNumber?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public addAsApproved(body?: DraftCardDto, eupId?: string, registrationNumber?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public addAsApproved(body?: DraftCardDto, eupId?: string, registrationNumber?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (eupId !== undefined && eupId !== null) {
            queryParameters = queryParameters.set('eupId', <any>eupId);
        }
        if (registrationNumber !== undefined && registrationNumber !== null) {
            queryParameters = queryParameters.set('registrationNumber', <any>registrationNumber);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/DriverApp/Sender/ApprovedCard`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public confirmTransport(kpoId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public confirmTransport(kpoId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public confirmTransport(kpoId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public confirmTransport(kpoId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling confirmTransport.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/DriverApp/Kpo/${encodeURIComponent(String(kpoId))}/ConfirmTransport`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadConfirmation(kpoId: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public downloadConfirmation(kpoId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public downloadConfirmation(kpoId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public downloadConfirmation(kpoId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling downloadConfirmation.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/api/DriverApp/Kpo/${encodeURIComponent(String(kpoId))}/DownloadConfirmation`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadQrCode(kpoId: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public downloadQrCode(kpoId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public downloadQrCode(kpoId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public downloadQrCode(kpoId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling downloadQrCode.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/api/DriverApp/Kpo/${encodeURIComponent(String(kpoId))}/DownloadQRCode`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateConfirmation(kpoId: string, body?: WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1UpdateApprovedKpoAndGenerateConfirmationAsCarrierRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public generateConfirmation(kpoId: string, body?: WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1UpdateApprovedKpoAndGenerateConfirmationAsCarrierRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public generateConfirmation(kpoId: string, body?: WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1UpdateApprovedKpoAndGenerateConfirmationAsCarrierRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public generateConfirmation(kpoId: string, body?: WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1UpdateApprovedKpoAndGenerateConfirmationAsCarrierRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling generateConfirmation.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/DriverApp/Kpo/${encodeURIComponent(String(kpoId))}/GenerateConfirmation`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getApprovedKpo(kpoId: string, observe?: 'body', reportProgress?: boolean): Observable<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ApprovedKpoDto>;
    public getApprovedKpo(kpoId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ApprovedKpoDto>>;
    public getApprovedKpo(kpoId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ApprovedKpoDto>>;
    public getApprovedKpo(kpoId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling getApprovedKpo.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ApprovedKpoDto>('get',`${this.basePath}/api/DriverApp/Kpo/${encodeURIComponent(String(kpoId))}/AsApproved`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param companyId 
     * @param kpoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompany(companyId: string, kpoId?: string, observe?: 'body', reportProgress?: boolean): Observable<WasteRegisterPublicApiApiModelsElasticsearchCompanyEs>;
    public getCompany(companyId: string, kpoId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WasteRegisterPublicApiApiModelsElasticsearchCompanyEs>>;
    public getCompany(companyId: string, kpoId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WasteRegisterPublicApiApiModelsElasticsearchCompanyEs>>;
    public getCompany(companyId: string, kpoId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompany.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (kpoId !== undefined && kpoId !== null) {
            queryParameters = queryParameters.set('kpoId', <any>kpoId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WasteRegisterPublicApiApiModelsElasticsearchCompanyEs>('get',`${this.basePath}/api/DriverApp/Company/${encodeURIComponent(String(companyId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param eupId 
     * @param kpoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyEup(eupId: string, kpoId?: string, observe?: 'body', reportProgress?: boolean): Observable<WasteRegisterPublicApiApiModelsElasticsearchEupEs>;
    public getCompanyEup(eupId: string, kpoId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WasteRegisterPublicApiApiModelsElasticsearchEupEs>>;
    public getCompanyEup(eupId: string, kpoId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WasteRegisterPublicApiApiModelsElasticsearchEupEs>>;
    public getCompanyEup(eupId: string, kpoId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (eupId === null || eupId === undefined) {
            throw new Error('Required parameter eupId was null or undefined when calling getCompanyEup.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (kpoId !== undefined && kpoId !== null) {
            queryParameters = queryParameters.set('kpoId', <any>kpoId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WasteRegisterPublicApiApiModelsElasticsearchEupEs>('get',`${this.basePath}/api/DriverApp/Company/Eup/${encodeURIComponent(String(eupId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param companyId 
     * @param eupId 
     * @param query 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyEups(companyId: string, eupId?: string, query?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<WasteRegisterPublicApiApiModelsElasticsearchEupEs>>;
    public getCompanyEups(companyId: string, eupId?: string, query?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WasteRegisterPublicApiApiModelsElasticsearchEupEs>>>;
    public getCompanyEups(companyId: string, eupId?: string, query?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WasteRegisterPublicApiApiModelsElasticsearchEupEs>>>;
    public getCompanyEups(companyId: string, eupId?: string, query?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyEups.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (eupId !== undefined && eupId !== null) {
            queryParameters = queryParameters.set('eupId', <any>eupId);
        }
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<WasteRegisterPublicApiApiModelsElasticsearchEupEs>>('get',`${this.basePath}/api/DriverApp/Sender/Company/${encodeURIComponent(String(companyId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmationGeneratedKpo(kpoId: string, observe?: 'body', reportProgress?: boolean): Observable<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ConfirmationGeneratedKpoDto>;
    public getConfirmationGeneratedKpo(kpoId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ConfirmationGeneratedKpoDto>>;
    public getConfirmationGeneratedKpo(kpoId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ConfirmationGeneratedKpoDto>>;
    public getConfirmationGeneratedKpo(kpoId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling getConfirmationGeneratedKpo.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ConfirmationGeneratedKpoDto>('get',`${this.basePath}/api/DriverApp/Kpo/${encodeURIComponent(String(kpoId))}/AsConfirmationGenerated`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param statusCode 
     * @param registrationNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDriverCards(statusCode?: string, registrationNumber?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ElevatedDriverKpoDto>>;
    public getDriverCards(statusCode?: string, registrationNumber?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ElevatedDriverKpoDto>>>;
    public getDriverCards(statusCode?: string, registrationNumber?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ElevatedDriverKpoDto>>>;
    public getDriverCards(statusCode?: string, registrationNumber?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (statusCode !== undefined && statusCode !== null) {
            queryParameters = queryParameters.set('statusCode', <any>statusCode);
        }
        if (registrationNumber !== undefined && registrationNumber !== null) {
            queryParameters = queryParameters.set('registrationNumber', <any>registrationNumber);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ElevatedDriverKpoDto>>('get',`${this.basePath}/api/DriverApp/Tasks`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param statusCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getKpoDetails(kpoId: string, statusCode?: string, observe?: 'body', reportProgress?: boolean): Observable<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ApprovedKpoDto>;
    public getKpoDetails(kpoId: string, statusCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ApprovedKpoDto>>;
    public getKpoDetails(kpoId: string, statusCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ApprovedKpoDto>>;
    public getKpoDetails(kpoId: string, statusCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling getKpoDetails.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (statusCode !== undefined && statusCode !== null) {
            queryParameters = queryParameters.set('statusCode', <any>statusCode);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ApprovedKpoDto>('get',`${this.basePath}/api/DriverApp/TaskDetails/${encodeURIComponent(String(kpoId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReceiveConfirmedKpo(kpoId: string, observe?: 'body', reportProgress?: boolean): Observable<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ReceiveConfirmedKpoDto>;
    public getReceiveConfirmedKpo(kpoId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ReceiveConfirmedKpoDto>>;
    public getReceiveConfirmedKpo(kpoId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ReceiveConfirmedKpoDto>>;
    public getReceiveConfirmedKpo(kpoId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling getReceiveConfirmedKpo.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1ReceiveConfirmedKpoDto>('get',`${this.basePath}/api/DriverApp/Kpo/${encodeURIComponent(String(kpoId))}/AsReceiveConfirmed`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRejectedKpoDetails(kpoId: string, observe?: 'body', reportProgress?: boolean): Observable<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1RejectedKpoDto>;
    public getRejectedKpoDetails(kpoId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1RejectedKpoDto>>;
    public getRejectedKpoDetails(kpoId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1RejectedKpoDto>>;
    public getRejectedKpoDetails(kpoId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling getRejectedKpoDetails.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1RejectedKpoDto>('get',`${this.basePath}/api/DriverApp/Driver/Kpo/${encodeURIComponent(String(kpoId))}/AsRejected`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param eupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSelfCompany(eupId?: string, observe?: 'body', reportProgress?: boolean): Observable<CompanySearchResultDto>;
    public getSelfCompany(eupId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CompanySearchResultDto>>;
    public getSelfCompany(eupId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CompanySearchResultDto>>;
    public getSelfCompany(eupId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (eupId !== undefined && eupId !== null) {
            queryParameters = queryParameters.set('eupId', <any>eupId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CompanySearchResultDto>('get',`${this.basePath}/api/DriverApp/Sender/Company`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSelfCompanyEups(observe?: 'body', reportProgress?: boolean): Observable<Array<BDOApiClientModelsCompanyEupDto>>;
    public getSelfCompanyEups(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BDOApiClientModelsCompanyEupDto>>>;
    public getSelfCompanyEups(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BDOApiClientModelsCompanyEupDto>>>;
    public getSelfCompanyEups(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<BDOApiClientModelsCompanyEupDto>>('get',`${this.basePath}/api/DriverApp/Sender/SelfCompany`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param query 
     * @param eupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTeryts(query: string, eupId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<WasteRegisterPublicApiApiModelsElasticsearchTerytEs>>;
    public getTeryts(query: string, eupId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WasteRegisterPublicApiApiModelsElasticsearchTerytEs>>>;
    public getTeryts(query: string, eupId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WasteRegisterPublicApiApiModelsElasticsearchTerytEs>>>;
    public getTeryts(query: string, eupId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling getTeryts.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (eupId !== undefined && eupId !== null) {
            queryParameters = queryParameters.set('eupId', <any>eupId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<WasteRegisterPublicApiApiModelsElasticsearchTerytEs>>('get',`${this.basePath}/api/DriverApp/Sender/Teryt/${encodeURIComponent(String(query))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTransportConfirmationKpo(kpoId: string, observe?: 'body', reportProgress?: boolean): Observable<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1TransportConfirmationKpoDto>;
    public getTransportConfirmationKpo(kpoId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1TransportConfirmationKpoDto>>;
    public getTransportConfirmationKpo(kpoId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1TransportConfirmationKpoDto>>;
    public getTransportConfirmationKpo(kpoId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling getTransportConfirmationKpo.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1TransportConfirmationKpoDto>('get',`${this.basePath}/api/DriverApp/Kpo/${encodeURIComponent(String(kpoId))}/AsTransportConfirmation`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param query 
     * @param eupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWasteCodes(query: string, eupId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto>>;
    public getWasteCodes(query: string, eupId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto>>>;
    public getWasteCodes(query: string, eupId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto>>>;
    public getWasteCodes(query: string, eupId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling getWasteCodes.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (eupId !== undefined && eupId !== null) {
            queryParameters = queryParameters.set('eupId', <any>eupId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteCodeV1WasteCodeDto>>('get',`${this.basePath}/api/DriverApp/Sender/WasteCode/${encodeURIComponent(String(query))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param query 
     * @param eupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWasteProcess(query?: string, eupId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto>>;
    public getWasteProcess(query?: string, eupId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto>>>;
    public getWasteProcess(query?: string, eupId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto>>>;
    public getWasteProcess(query?: string, eupId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (eupId !== undefined && eupId !== null) {
            queryParameters = queryParameters.set('eupId', <any>eupId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto>>('get',`${this.basePath}/api/DriverApp/Sender/WasteProcess`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param eupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWasteProcessById(id: number, eupId?: string, observe?: 'body', reportProgress?: boolean): Observable<BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto>;
    public getWasteProcessById(id: number, eupId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto>>;
    public getWasteProcessById(id: number, eupId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto>>;
    public getWasteProcessById(id: number, eupId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getWasteProcessById.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (eupId !== undefined && eupId !== null) {
            queryParameters = queryParameters.set('eupId', <any>eupId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto>('get',`${this.basePath}/api/DriverApp/Sender/WasteProcess/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param registrationNumber 
     * @param index 
     * @param filterByRegistrationNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestDriverCards(registrationNumber?: string, index?: number, filterByRegistrationNumber?: boolean, observe?: 'body', reportProgress?: boolean): Observable<TasksResponseDto>;
    public requestDriverCards(registrationNumber?: string, index?: number, filterByRegistrationNumber?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TasksResponseDto>>;
    public requestDriverCards(registrationNumber?: string, index?: number, filterByRegistrationNumber?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TasksResponseDto>>;
    public requestDriverCards(registrationNumber?: string, index?: number, filterByRegistrationNumber?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (registrationNumber !== undefined && registrationNumber !== null) {
            queryParameters = queryParameters.set('registrationNumber', <any>registrationNumber);
        }
        if (index !== undefined && index !== null) {
            queryParameters = queryParameters.set('index', <any>index);
        }
        if (filterByRegistrationNumber !== undefined && filterByRegistrationNumber !== null) {
            queryParameters = queryParameters.set('filterByRegistrationNumber', <any>filterByRegistrationNumber);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TasksResponseDto>('get',`${this.basePath}/api/DriverApp/TasksRequest`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reviseRejected(body?: WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1ReviseKpoRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public reviseRejected(body?: WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1ReviseKpoRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public reviseRejected(body?: WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1ReviseKpoRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public reviseRejected(body?: WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardV1ReviseKpoRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/DriverApp/Driver/ReviseRejected`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param query 
     * @param eupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchCompany(query?: string, eupId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CompanySearchResultDto>>;
    public searchCompany(query?: string, eupId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CompanySearchResultDto>>>;
    public searchCompany(query?: string, eupId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CompanySearchResultDto>>>;
    public searchCompany(query?: string, eupId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (eupId !== undefined && eupId !== null) {
            queryParameters = queryParameters.set('eupId', <any>eupId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CompanySearchResultDto>>('get',`${this.basePath}/api/DriverApp/Company`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchKpo(body?: WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardSearchCardsV1SearchKpoAsCarrierRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsCarrierSearchResultDto>>;
    public searchKpo(body?: WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardSearchCardsV1SearchKpoAsCarrierRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsCarrierSearchResultDto>>>;
    public searchKpo(body?: WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardSearchCardsV1SearchKpoAsCarrierRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsCarrierSearchResultDto>>>;
    public searchKpo(body?: WasteRegisterPublicApiApiModelsRequestsWasteRegisterWasteTransferCardSearchCardsV1SearchKpoAsCarrierRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsCarrierSearchResultDto>>('post',`${this.basePath}/api/DriverApp/Search`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kpoId 
     * @param acceptance 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setCardAcceptance(kpoId: string, acceptance?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsCarrierSearchResultDto>>;
    public setCardAcceptance(kpoId: string, acceptance?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsCarrierSearchResultDto>>>;
    public setCardAcceptance(kpoId: string, acceptance?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsCarrierSearchResultDto>>>;
    public setCardAcceptance(kpoId: string, acceptance?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kpoId === null || kpoId === undefined) {
            throw new Error('Required parameter kpoId was null or undefined when calling setCardAcceptance.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (acceptance !== undefined && acceptance !== null) {
            queryParameters = queryParameters.set('acceptance', <any>acceptance);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsCarrierSearchResultDto>>('put',`${this.basePath}/api/DriverApp/Tasks/${encodeURIComponent(String(kpoId))}/Accept`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param driverId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setDriverAddCardPermission(driverId: number, body?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public setDriverAddCardPermission(driverId: number, body?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public setDriverAddCardPermission(driverId: number, body?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public setDriverAddCardPermission(driverId: number, body?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (driverId === null || driverId === undefined) {
            throw new Error('Required parameter driverId was null or undefined when calling setDriverAddCardPermission.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('patch',`${this.basePath}/api/DriverApp/${encodeURIComponent(String(driverId))}/AddCardsPermission`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
