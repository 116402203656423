import { Component, ViewChild, OnDestroy, HostListener, Inject, OnInit } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { ExtendedKpoService, DraftCardDto, WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsCarrierSearchResultDto, RecordCardService, KEOCardDto } from "../../../../../api";
import { MatDialog } from "@angular/material/dialog";
import { ViewDraftCardDialogComponent } from "../home/kpo/view-draft-card-dialog/view-draft-card-dialog.component";
import { AlertService, MessageType } from "../../../services/alert.service";
import { Observable, Subject, Subscription } from "rxjs";
import { tap, map, debounceTime } from "rxjs/operators";
import { DraftCard } from "../../../../../api/model/draftCard";
import { CardService } from "../../../services/card.service";
import { MessagingService } from "../../../services/messaging.service";
import { AdvancedFilterQuery, BdoFilterComponent } from "../../bdo-filter/bdo-filter.component";
import { NewKpoDialogComponent } from "../home/kpo/new-kpo-dialog/new-kpo-dialog.component";
import { SelectReceiverDialogComponent } from "../home/kpo/select-receiver-dialog/select-receiver-dialog.component";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { Overlay } from "@angular/cdk/overlay";
import { FiledCardDetailsComponent } from "../../dialogs/filed-card-details/filed-card-details.component";
import { FiledCollectedWasteTransferCardsComponent } from "../../dialogs/filed-collected-waste-transfer-cards/filed-collected-waste-transfer-cards.component";
import { FiledForwardedWasteTransferCardsComponent } from "../../dialogs/filed-forwarded-waste-transfer-cards/filed-forwarded-waste-transfer-cards.component";
import { FiledGeneratedWasteTransferCardsComponent } from "../../dialogs/filed-generated-waste-transfer-cards/filed-generated-waste-transfer-cards.component";

type KpoCard = WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsCarrierSearchResultDto;

@Component({
  selector: "app-waste-record-cards",
  templateUrl: "./waste-record-cards.component.html",
  styleUrls: ["./waste-record-cards.component.scss"],
})
export class WasteRecordCardsComponent implements OnInit, OnDestroy {
  public loading = false;
  public error = false;
  carrierName: string;
  scrollStrategy = this.overlay.scrollStrategies.block();

  filterCardStatuses: { label: string; value: string }[] = [
    { label: "Przydzielone", value: "APPROVED" },
    { label: "Potwierdzenie wygenerowane", value: "CONFIRMATION_GENERATED" },
    { label: "Zrealizowane przejęcie", value: "RECEIVE_CONFIRMATION" },
    { label: "Odrzucone", value: "REJECTED" },
  ];

  reloadRequest: Subject<void> = new Subject();
  refreshSubscription = new Subscription();
  requestSubscription = new Subscription();

  filteredResults$: Observable<KEOCardDto[]> = new Observable((observer) => {
    this.load(observer);
    //this.paginator.page.subscribe((p) => this.load(observer));
    this.handleQueryChanged(observer);
    //this.handleRefresh(observer);
    this.reloadRequest.subscribe(() => this.load(observer));
  });

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(BdoFilterComponent, { static: true })
  bdoFilter: BdoFilterComponent;

  constructor(public cardService: CardService, public recordCardService: RecordCardService, private alertService: AlertService, private messagingService: MessagingService, @Inject(LOCAL_STORAGE) private storage: WebStorageService, private extendedKpoService: ExtendedKpoService, public dialog: MatDialog, private overlay: Overlay) {}

  ngOnInit(): void {
    this.carrierName = this.storage.get("SubjectName");
    this.bdoFilter.advancedQuery.year = new Date().getFullYear().toString();
    this.bdoFilter.advancedQuery.vehicleRegistrationNumber = null;
    this.bdoFilter.advancedQuery.senderName = null;
  }

  load(observer) {
    this.error = false;
    this.loading = true;
    //observer.next([]);
    this.recordCardService.getFilteredKEOCards(this.bdoFilter.advancedQuery.wasteCode ?? null, this.bdoFilter.advancedQuery.cardNumber ?? null, parseInt(this.bdoFilter.advancedQuery.year), this.paginator.pageIndex + 1, this.paginator.pageSize).subscribe(
      (cards) => {
        console.log(cards.items);
        observer.next(cards.items);
        this.paginator.length = cards.totalItemCount;
        this.loading = false;
        this.error = false;
      },
      () => {
        console.log("cards");
        this.error = true;
        this.loading = false;
        this.alertService.showMessage("Wystąpił błąd podczas ładowania listy", MessageType.Error);
      }
    );
  }

  handleRefresh(observer) {
    this.refreshSubscription = this.messagingService.messageReceived$.subscribe((payload) => {
      this.alertService.showMessage(payload.data.body.replace("\r\n", " | "), MessageType.Notification);
      this.load(observer);
    });
  }

  handleQueryChanged(observer) {
    this.requestSubscription.add(
      this.bdoFilter.advancedQueryTextChanged.pipe(debounceTime(800)).subscribe((result: AdvancedFilterQuery) => {
        this.load(observer);
      })
    );
    this.requestSubscription.add(
      this.bdoFilter.advancedQueryChanged.subscribe((result: AdvancedFilterQuery) => {
        this.load(observer);
      })
    );
  }

  ngOnDestroy(): void {
    this.refreshSubscription.unsubscribe();
    this.requestSubscription.unsubscribe();
  }

  // @HostListener("window:focus", ["$event"])
  // onFocus(event: FocusEvent): void {
  //   this.reloadRequest.next();
  // }

  getLastModificationDate(kpo: any) {
    if (kpo.creationDateTime === undefined) {
      kpo.creationDateTime = kpo.kpoLastModifiedAt;
    }
    return kpo.creationDateTime === undefined ? kpo.kpoLastModifiedAt : kpo.creationDateTime;
  }

  openCardCreationDialog() {
    const dialogRef = this.dialog.open(NewKpoDialogComponent, {
      width: "1100px",
      data: {},
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.bdoFilter.advancedQuery.cardStatusCodeName == result;
      this.reloadRequest.next();
    });
  }

  openDetailsDialog(card): void {
    this.dialog.open(FiledCardDetailsComponent, {
      width: "950px",
      data: { cardId: card.keoId },
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });
  }

  openFiledGeneratedWasteRecordCards(card): void {
    this.dialog.open(FiledGeneratedWasteTransferCardsComponent, {
      width: "950px",
      data: { cardId: card.keoId },
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });
  }

  openFiledCollectedWasteRecordCards(card): void {
    this.dialog.open(FiledCollectedWasteTransferCardsComponent, {
      width: "950px",
      data: { cardId: card.keoId },
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });
  }

  openFiledForwardedWasteRecordCards(card): void {
    this.dialog.open(FiledForwardedWasteTransferCardsComponent, {
      width: "950px",
      data: { cardId: card.keoId },
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });
  }
}
