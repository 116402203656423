import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { RoleService } from "../../../services/role.service";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";

@UntilDestroy()
@Component({
  selector: "app-change-role-dialog",
  templateUrl: "./change-role-dialog.component.html",
  styleUrls: ["./change-role-dialog.component.scss"],
})
export class ChangeRoleDialogComponent implements OnInit {
  role: "CARRIER" | "SENDER";

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private roleService: RoleService) {
    roleService.role$.pipe(untilDestroyed(this)).subscribe((role) => (this.role = role));
  }

  ngOnInit(): void {}

  public setRole(): void {
    this.roleService.setRole(this.role);
    this.dialogRef.close();
  }
}
