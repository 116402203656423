import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Subscription, Observable, fromEvent, Subject } from "rxjs";
import { startWith } from "rxjs/operators";
import { WasteRegisterPublicApiApiModelsElasticsearchCompanyEs, WasteRegisterPublicApiApiModelsElasticsearchEupEs, ExtendedKpoService, DraftCardsService } from "../../../../api";
import { AlertService } from "../../services/alert.service";
import { CardService } from "../../services/card.service";
import { SelectReceiverDialogComponent } from "../pages/home/kpo/select-receiver-dialog/select-receiver-dialog.component";

@Component({
  selector: "app-headquarter-picker",
  templateUrl: "./headquarter-picker.component.html",
  styleUrls: ["./headquarter-picker.component.scss"],
})
export class HeadquarterPickerComponent implements OnInit {
  error = false;
  loading = false;
  @Input() set company(val: WasteRegisterPublicApiApiModelsElasticsearchCompanyEs) {
    this._company = val;
    this.companyChanged.next(val);
  }
  companyChanged = new Subject<WasteRegisterPublicApiApiModelsElasticsearchCompanyEs>();

  @Input() preselectedEup: WasteRegisterPublicApiApiModelsElasticsearchCompanyEs;

  private _company: WasteRegisterPublicApiApiModelsElasticsearchCompanyEs;

  @Output()
  public headquarterSelected = new EventEmitter<WasteRegisterPublicApiApiModelsElasticsearchCompanyEs>();
  query = "";
  subscription: Subscription = new Subscription();
  @ViewChild("search", { static: true }) searchInput: ElementRef;

  filteredResults$: Observable<WasteRegisterPublicApiApiModelsElasticsearchCompanyEs[]> = new Observable((observer) => {
    this.subscription.add(
      fromEvent<any>(this.searchInput.nativeElement, "keyup")
        .debounceTime(1000)
        .subscribe((res) => {
          this.loading = true;
          this.extendedKpoService.getCompanyEups(this._company.companyId, this.query).subscribe((result) => {
            this.loading = false;
            observer.next(result);
          });
        })
    );
    this.subscription.add(
      this.companyChanged.subscribe((res) => {
        this.loading = true;
        this.extendedKpoService.getCompanyEups(this._company.companyId, this.query).subscribe((result) => {
          this.loading = false;
          observer.next(result);
        });
      })
    );
  });

  constructor(private extendedKpoService: ExtendedKpoService, public dialogRef: MatDialogRef<SelectReceiverDialogComponent>) {}

  ngOnInit(): void {}

  parseAddress(address: string) {
    if (address === undefined) {
      return "";
    }
    const elements = address.split(", ");
    const town = elements.find((el) => el.split(": ")[0] === "Miejscowość")?.split(": ")[1];
    const street = elements.find((el) => el.split(": ")[0] === "Ulica")?.split(": ")[1];
    return town + (street === undefined ? "" : " ul. " + street);
  }

  radioSelected(item) {
    this.headquarterSelected.emit(item);
  }
}
