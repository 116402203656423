<div *ngIf="data.mode=='create'">
    <h2 mat-dialog-title> Tworzenie konta </h2>
</div>
<div *ngIf="data.mode=='edit'">
    <h2 mat-dialog-title> Edycja </h2>
</div>
<div *ngIf="data.mode=='view'">
    <h2 mat-dialog-title> Podgląd </h2>
</div>
<form #form="ngForm" (ngSubmit)="form.valid && onSubmit(form)" autocomplete="off">
    <div mat-dialog-content fxLayout="wrap row" fxLayoutAlign="left" fxLayoutGap="1%">

        <mat-form-field class="col" appearance="outline" fxFlex="49%" fxFlex.lt-md="100%">
            <mat-label>Imię</mat-label>
            <input matInput autocomplete="off" name="firstName" placeholder="Jan" [(ngModel)]="user.firstName"
                [readonly]="data.mode=='view'" [required]="data.mode!='view'">
        </mat-form-field>

        <mat-form-field class="col" appearance="outline" fxFlex="49%" fxFlex.lt-md="100%">
            <mat-label>Nazwisko</mat-label>
            <input matInput autocomplete="off" name="lastName" placeholder="Kowalski" [(ngModel)]="user.lastName"
                [readonly]="data.mode=='view'" [required]="data.mode!='view'">
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline" fxFlex="100%">
            <mat-label>E-Mail</mat-label>
            <input matInput autocomplete="off" placeholder="example@mae.pl" name="username" [(ngModel)]="user.username"
                [readonly]="data.mode=='view'" [required]="data.mode!='view'" email>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="fill" fxFlex="100%">
            <mat-label *ngIf="data.mode=='create'">Hasło</mat-label>
            <mat-label *ngIf="data.mode=='edit'">Hasło</mat-label>
            <input matInput autocomplete="off" name="password" [type]="hidePassword ? 'password' : 'text'"
                [(ngModel)]="password" [required]="data.mode=='create'">
            <mat-icon matSuffix (click)="hidePassword = !hidePassword">
                {{hidePassword ? 'visibility' : 'visibility_off'}}
            </mat-icon>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline" fxFlex="100%"
            *ngIf="data.mode!='view'||user.phoneNumber==null">
            <mat-label>Numer telefonu</mat-label>
            <span matPrefix>+48 &nbsp;</span>
            <input matInput autocomplete="off" placeholder="Numer telefonu" name="phoneNumber"
                [(ngModel)]="user.phoneNumber" [readonly]="data.mode=='view'">
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline" *ngIf="data.mode!='edit'" fxFlex="100%">
            <mat-label>Grupa</mat-label>
            <mat-select name="group" [(ngModel)]="user.group">
                <mat-option *ngFor="let group of userGroups" [value]="group">{{group.name}}</mat-option>

            </mat-select>

        </mat-form-field>
        <!-- <mat-checkbox [checked]="sendMail" (change)="valueChange($event)" 
            *ngIf="data.mode!='view'">
            Wyślij potwierdzenie na adres E-Mail
        </mat-checkbox> -->
        <p>&nbsp;</p>


    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="dialog.close(false);" type="button" *ngIf="data.mode==='view'">Zamknij</button>
        <button mat-button color="warn" (click)="dialog.close(false);" type="button"
            *ngIf="data.mode!='view'">Anuluj</button>
        <button mat-button color="primary" style="float:right;" type="submit" *ngIf="data.mode!='view'">Zapisz</button>



    </div>
</form>