import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AlertService, MessageType } from "../../../../services/alert.service";
import { ExtendedKpoService, WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1PlannedKpoDto, WasteRegisterPublicApiApiModelsElasticsearchCompanyEs, WasteRegisterPublicApiApiModelsElasticsearchEupEs, UserDto, BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto, ChangesRegisterEntityDto, BdoServiceModelsEnumerationsCompanyType } from "../../../../../../api";
import { DatePipe } from "@angular/common";
import { CookieService } from "ngx-cookie-service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { RoleService } from "../../../../services/role.service";
@UntilDestroy()
@Component({
  selector: "app-kpo-details-dialog",
  templateUrl: "./kpo-details-dialog.component.html",
  styleUrls: ["./kpo-details-dialog.component.scss"],
})
export class KpoDetailsDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<KpoDetailsDialogComponent>, private extendedKpoService: ExtendedKpoService, private alertService: AlertService, public datepipe: DatePipe, private cookieService: CookieService, private roleService: RoleService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.roleService.role$.pipe(untilDestroyed(this)).subscribe((r) => (this.role = r));
  }

  details: WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1PlannedKpoDto;
  wasteProcess: BdoServiceModelsDtoWasteRegisterWasteProcessWasteProcessDto;
  senderCompany: WasteRegisterPublicApiApiModelsElasticsearchCompanyEs = undefined;
  senderCompanyEup: WasteRegisterPublicApiApiModelsElasticsearchEupEs = undefined;
  receiverCompany: WasteRegisterPublicApiApiModelsElasticsearchCompanyEs = undefined;
  receiverCompanyEup: WasteRegisterPublicApiApiModelsElasticsearchEupEs = undefined;
  carrierCompany: WasteRegisterPublicApiApiModelsElasticsearchCompanyEs = undefined;
  driver: UserDto = undefined;
  changes: ChangesRegisterEntityDto[];
  role: string;

  stripHtml(value) {
    return value.replace(/<[^>]*>/g, "");
  }

  ngOnInit() {
    this.extendedKpoService.getChangesRegister(this.data.model.kpoId).subscribe((changes) => {
      this.data.changes = changes.sort((a, b) => {
        return a.dateTime < b.dateTime ? -1 : a.dateTime > b.dateTime ? 1 : 0;
      });
    });
    this.extendedKpoService.getKpoDetails(this.data.model.kpoId, this.data.model.cardStatusCodeName, this.roleService.getRole() == "CARRIER" ? BdoServiceModelsEnumerationsCompanyType.NUMBER_2 : BdoServiceModelsEnumerationsCompanyType.NUMBER_1).subscribe((result) => {
      this.details = result;
      console.log(result);
      if (this.details.wasteProcessId != null) {
        this.extendedKpoService.getWasteProcessById(this.details.wasteProcessId).subscribe((wasteProcess) => (this.wasteProcess = wasteProcess));
      }

      this.extendedKpoService.getCompany(this.details.senderCompanyId).subscribe(
        (senderCompany) => {
          this.senderCompany = senderCompany;
          this.extendedKpoService.getCompanyEup(this.details.senderEupId).subscribe(
            (senderEup) => {
              this.senderCompanyEup = senderEup;
            },
            (error) => this.alertService.showMessage("Nie można załadować danych siedziby firmy przekazującego", MessageType.Error)
          );
        },
        (error) => this.alertService.showMessage("Nie można załadować danych firmy przekazującego", MessageType.Error)
      );

      this.extendedKpoService.getCompany(this.details.receiverCompanyId).subscribe(
        (receiverCompany) => {
          this.receiverCompany = receiverCompany;
          this.extendedKpoService.getCompanyEup(this.details.receiverEupId).subscribe(
            (receiverEup) => (this.receiverCompanyEup = receiverEup),
            (error) => this.alertService.showMessage("Nie można załadować danych siedziby firmy przejmującego", MessageType.Error)
          );
        },
        (error) => {
          this.alertService.showMessage("Nie można załadować danych firmy przejmującego", MessageType.Error);
        }
      );

      if (this.role == "SENDER") {
        this.extendedKpoService.getCompany(this.details.carrierCompanyId).subscribe(
          (carrierCompany) => {
            this.carrierCompany = carrierCompany;
          },
          (error) => {
            this.alertService.showMessage("Nie można załadować danych firmy transportującego", MessageType.Error);
          }
        );
      }
    });
    this.extendedKpoService.getAssignedDriver(this.data.model.kpoId).subscribe(
      (result) => {
        this.driver = result;
      },
      (error) => console.error
    );
  }
}
