<mat-horizontal-stepper [linear]="true" #stepper>
  <mat-step>
    <ng-template matStepLabel>Wybierz przejmującego </ng-template>

    <h2 fxHide.gt-sm>Wybierz przejmującego</h2>
    <app-company-picker (companySelected)="setCompany($event)"></app-company-picker>
    <button mat-button color="primary" matStepperNext class="next-button"
      [disabled]="(receiverCompany == undefined)">Potwierdź</button>

  </mat-step>
  <mat-step>

    <ng-template matStepLabel>Wybierz siedzibę</ng-template>

    <h2 fxHide.gt-sm>Wybierz siedzibę</h2>
    <app-headquarter-picker [company]="receiverCompany" (headquarterSelected)="setEup($event)">
    </app-headquarter-picker>

    <button mat-button color="primary" class="next-button" [disabled]="(receiverCompanyEup == undefined)"
      (click)="changeReceiver()">Potwierdź</button>

    <button mat-button color="secondary" class="next-button" matStepperPrevious>Wstecz</button>
  </mat-step>

</mat-horizontal-stepper>