import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { Subject, Subscription, Observable } from "rxjs";
import { map, debounceTime } from "rxjs/operators";
import { WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsCarrierSearchResultDto } from "../../../../../api";
import { DraftCard } from "../../../../../api/model/draftCard";
import { AlertService, MessageType } from "../../../services/alert.service";
import { CardService } from "../../../services/card.service";
import {
  AdvancedFilterQuery,
  BdoFilterComponent,
} from "../../bdo-filter/bdo-filter.component";
import { ExportToCsv } from "export-to-csv";
import { DatePipe } from "@angular/common";
import { KpoDetailsDialogComponent } from "../archive/archive-details-dialog/kpo-details-dialog.component";
import { ViewDraftCardDialogComponent } from "../home/kpo/view-draft-card-dialog/view-draft-card-dialog.component";
import { Overlay } from "@angular/cdk/overlay";
type KpoCard = WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsCarrierSearchResultDto;

@Component({
  selector: "app-stats",
  templateUrl: "./stats.component.html",
  styleUrls: ["./stats.component.scss"],
})
export class StatsComponent implements OnInit, OnDestroy {
  loading = true;
  error = false;
  isDocumentGenerating = false;
  documentGeneratingStatus = 0;
  documentGeneratingMaxStatus = 0;
  scrollStrategy = this.overlay.scrollStrategies.block();

  filterCardStatuses: { label: string; value: string }[] = [
    { label: "Potwierdzenie transportu ", value: "TRANSPORT_CONFIRMATION" },
  ];

  reloadRequest: Subject<void> = new Subject();
  refreshSubscription = new Subscription();
  requestSubscription = new Subscription();

  filteredResults$: Observable<(DraftCard | KpoCard)[]> = new Observable(
    (observer) => {
      this.load(observer);
      this.handleQueryChanged(observer);
      this.reloadRequest.subscribe(() => this.load(observer));
    }
  ).pipe(map((r: (DraftCard | KpoCard)[]) => (r ? r : [])));

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(BdoFilterComponent, { static: true })
  bdoFilter: BdoFilterComponent;

  constructor(
    public cardService: CardService,
    private alertService: AlertService,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private overlay: Overlay
  ) {}
  ngOnInit(): void {
    this.bdoFilter.advancedQuery.cardStatusCodeName = "TRANSPORT_CONFIRMATION";
  }

  load(observer) {
    this.error = false;
    this.loading = true;
    observer.next([]);
    this.cardService
      .getRealisingNowCards(
        this.bdoFilter.advancedQuery,
        this.paginator.pageIndex,
        this.paginator.pageSize
      )
      .subscribe(
        (cards) => {
          observer.next(
            cards.items.map((m) => {
              const kpoCard = m as KpoCard;
              return {
                ...m,
                user: kpoCard
                  ? cards.userAttributions[kpoCard.kpoId]
                  : undefined,
              };
            })
          );
          this.paginator.length = cards.totalItemsCount;
          this.loading = false;
          this.error = false;
        },
        () => {
          this.error = true;
          this.loading = false;
          this.alertService.showMessage(
            "Wystąpił błąd podczas ładowania listy",
            MessageType.Error
          );
        }
      );
  }

  parseResults(result) {
    return result.map((r) => {
      const val: { [key: string]: string } = {
        "Nazwa przekazującego": r.senderName,
        "Nr karty": r.cardNumber,
        "Data realizacji": this.datepipe.transform(
          r.realTransportTime,
          "dd.MM.yyyy HH:mm:ss"
        ),
        "Kod odpadu": r.wasteCode,
        "Wystawiający ": r.senderFirstNameAndLastName,
        "Status ": r.cardStatus,
        "Przypisany kierowca": r.assignedDriver
          ? `${r.assignedDriver?.firstName} ${r.assignedDriver?.lastName}`
          : "(brak)",
        "Ilość akcji": r.changesRegister.length + 1,
      };
      return val;
    });
  }

  generateDocumentFile(result) {
    const options = {
      fieldSeparator: ";",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title: `Ilość kart: ${result.length}`,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(this.parseResults(result));
    this.isDocumentGenerating = false;
  }

  generateData(result, index = 1) {
    if (index === 1) {
      this.documentGeneratingStatus = index;
    }
    this.isDocumentGenerating = true;
    this.cardService
      .getRealisingNowCards(this.bdoFilter.advancedQuery, index, 50)
      .subscribe(
        (cards) => {
          cards.items.forEach((element) => {
            result.push({
              ...element,
              changesRegister: cards.changesRegister[element.kpoId],
              assignedDriver: cards.userAttributions[element.kpoId],
            });
          });
          if (cards.totalItemsCount > result.length) {
            this.documentGeneratingStatus = index;
            this.documentGeneratingMaxStatus = Math.ceil(
              cards.totalItemsCount / 50
            );
            this.generateData(result, index + 1);
          } else {
            this.generateDocumentFile(
              result.filter(
                (r) => r.changesRegister?.length > 0 || r.assignedDriver
              )
            );
          }
        },
        () => {
          this.alertService.showMessage(
            "Wystąpił błąd podczas generowania listy",
            MessageType.Error
          );
          this.isDocumentGenerating = false;
        }
      );
  }

  handleQueryChanged(observer) {
    this.requestSubscription.add(
      this.bdoFilter.advancedQueryTextChanged
        .pipe(debounceTime(800))
        .subscribe((result: AdvancedFilterQuery) => {
          this.bdoFilter.advancedQuery = result;
          this.load(observer);
        })
    );
    this.requestSubscription.add(
      this.bdoFilter.advancedQueryChanged.subscribe(
        (result: AdvancedFilterQuery) => {
          this.bdoFilter.advancedQuery = result;
          this.load(observer);
        }
      )
    );
  }

  openDetailsDialog(card): void {
    if (card.cardStatusCodeName === "APPROVED") {
      this.dialog.open(KpoDetailsDialogComponent, {
        width: "950px",
        data: { model: card },
        panelClass: "dialog",
        scrollStrategy: this.scrollStrategy,
        closeOnNavigation: true,
      });
    } else {
      this.dialog.open(ViewDraftCardDialogComponent, {
        width: "950px",
        data: { model: card },
        panelClass: "dialog",
        scrollStrategy: this.scrollStrategy,
        closeOnNavigation: true,
      });
    }
  }

  ngOnDestroy(): void {
    this.refreshSubscription.unsubscribe();
    this.requestSubscription.unsubscribe();
  }

  getLastModificationDate(kpo: any) {
    if (kpo.creationDateTime === undefined) {
      kpo.creationDateTime = kpo.kpoLastModifiedAt;
    }
    return kpo.creationDateTime === undefined
      ? kpo.kpoLastModifiedAt
      : kpo.creationDateTime;
  }
}
