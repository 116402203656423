<section class="file-upload">
    <div *ngIf="files.length==0">
        <div ngfDrop (filesChange)="dragFile()" [multiple]="0" selectable="1" [(validDrag)]="validComboDrag"
            [(files)]="files" accept="*/*" [maxSize]="maxSize"
            [(lastInvalids)]="lastInvalids" [(dragFiles)]="dragFile" class="well drop-zone"
            [class.invalid-drag]="validComboDrag===false" [class.valid-drag]="validComboDrag">
            <div *ngIf="files.length==0">
                <i class="material-icons">get_app</i>
                <div class="text"><b>Wybierz</b> lub przeciągnij archiwum zawierające aplikację</div>
            </div>
        </div>
    </div>
</section>