import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ExtendedKpoService } from "../../../../../api";
import { AlertService, MessageType } from "../../../services/alert.service";

@Component({
  selector: "app-planned-card-removal-dialog",
  templateUrl: "./planned-card-removal-dialog.component.html",
  styleUrls: ["./planned-card-removal-dialog.component.scss"],
})
export class PlannedCardRemovalDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<PlannedCardRemovalDialogComponent>, private extendedKpoService: ExtendedKpoService, private alertService: AlertService, @Inject(MAT_DIALOG_DATA) public data: any) {}

  approve() {
    this.extendedKpoService.removeCard(this.data.model.kpoId).subscribe(
      () => {
        this.alertService.showMessage("Pomyślnie usunięto kartę", MessageType.Info);
        this.dialogRef.close(true);
      },
      (error) => this.alertService.showMessage("Wystąpił błąd podczas usuwania karty", MessageType.Error)
    );
  }
  ngOnInit(): void {}
}
