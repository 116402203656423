<h1 mat-dialog-title>Dodawanie podmiotu</h1>
<div mat-dialog-content>
<mat-form-field>
  <mat-label>Client ID</mat-label>
  <input matInput autocomplete="off" [(ngModel)]="model.clientId" /> </mat-form-field
><mat-form-field>
  <mat-label>Client Secret</mat-label>
  <input matInput autocomplete="off" [(ngModel)]="model.clientSecret" />
</mat-form-field>
</div>
<div mat-dialog-actions>
    
  <a href="/assets/instrukcja_bdo.pdf" target="_blank" rel="noopener noreferrer"  mat-button style="margin-top:15px;" (click)="addSubject()">Jak dodać</a>
  <button mat-button style="margin-top:15px;" (click)="addSubject()" color="primary">Zapisz</button>
</div>