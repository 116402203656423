/**
 * BDOEX API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * Contact: mateusz.surma@mae.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface WasteTransferCardDto { 
    id?: number;
    realTransportTime?: Date;
    cardNumber?: string;
    senderFirstAndLastName?: string;
    receiverFirstAndLastName?: string;
    wasteMass?: number;
    senderName?: string;
    receiverName?: string;
    wasteCode?: string;
}