<div *ngIf="data.mode=='create'">
  <h2 mat-dialog-title> Tworzenie grupy </h2>
</div>
<div *ngIf="data.mode=='edit'">
  <h2 mat-dialog-title> Edycja </h2>
</div>
<div *ngIf="data.mode=='view'">
  <h2 mat-dialog-title> Podgląd </h2>
</div>

<form #form="ngForm" (ngSubmit)="form.valid " autocomplete="off">
  <div mat-dialog-content fxLayout="wrap row" fxLayoutAlign="left" fxLayoutGap="1%">
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Nazwa</mat-label>
      <input matInput autocomplete="off" name="name" placeholder="Moderator Ekspozycji" [(ngModel)]="group.name"
        [readonly]="data.mode=='view'" [required]="data.mode!='view'">
    </mat-form-field>

    <mat-selection-list (selectionChange)="selectionChange($event)">
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <!-- This is the tree node template for leaf nodes -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
          <!-- use a disabled button to provide padding for tree leaf -->
          <button mat-icon-button disabled></button>
          <mat-list-option [disabled]="data.mode === 'view'" [value]="node" [selected]="node.source.granted">
            {{node.name}}
          </mat-list-option>
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding class="permission-node">

          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          {{node.name}}
        </mat-tree-node>
      </mat-tree>

    </mat-selection-list>


  </div>
  <div mat-dialog-actions>
    <button mat-button *ngIf="data.mode !== 'view'" [style.float]="'left'" (click)="selectAllPermissions()">Zaznacz
      wszystkie</button>
    <button mat-button *ngIf="data.mode !== 'view'" color="warn" (click)="dialog.close(false);">Anuluj</button>
    <button mat-button *ngIf="data.mode !== 'view'" color="primary" style="float:right;"
      (click)="onSubmit(form)">Zapisz</button>
    <button mat-button *ngIf="data.mode === 'view'" color="primary" style="float:right;"
      (click)="dialog.close(false);">Zamknij</button>



  </div>
</form>